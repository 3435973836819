import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as Immutable from 'immutable';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { Configuration } from 'shared/models/configuration';
import { FormatGroup, MaterialType } from '../../../entity/models/entity';
import { DictionaryTypes } from '../../../models/dictionaries';
import { DictionariesService } from '../../../services/dictionaries.service';
import { ItemDetail } from '../../models/custom-events';
import { CustomerI, Page } from '../../models/page';
import { DiscoverV2BffService } from '../../service/discover-v2-bff.service';

@Component({
  selector: 'app-grid-view-v2',
  templateUrl: './grid-view-v2.component.html',
})

export class GridViewV2Component {
  @Input() public entities: FormatGroup[];
  public page: Page = null;
  public config: Configuration;
  public materialTypes: Immutable.Map<string, MaterialType>;
  public customer: CustomerI = null;

  constructor(
    private readonly router: Router,
    private configLoader: ConfigurationLoader,
    private readonly dictionariesService: DictionariesService,
    private discoverV2BffService: DiscoverV2BffService,
  ) {
    this.config = configLoader.config;
    this.customer = discoverV2BffService.customer;
    this.materialTypes = this.dictionariesService.getDictionaryItem(DictionaryTypes.MATERIAL_TYPES) as Immutable.Map<string, MaterialType>;
    this.page = discoverV2BffService.$page.value;
  }

  @HostListener('window:itemClicked', ['$event'])
  public onItemClicked(event: CustomEvent) {
    const {id, entityType}: ItemDetail = event.detail;
    this.router.navigate(['/search', 'card'], {queryParams: {id, entityType}});
  }
}
