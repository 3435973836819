<div class="container">
  <div class="quiz-header text-right">
    <img class="close-icon" src="../../../../assets/close.png" (click)="closeQuiz()">
  </div>
  <div *ngIf="!loading && questions.length > 0 && !showScore">
    <h2>Question {{ currentQuestionIndex + 1 }} of {{ EXPECTED_NUMBER_OF_QUESTIONS_IN_QUIZ }}</h2>
    <p><strong>{{ questions[currentQuestionIndex]?.question }}</strong></p>
    <p *ngIf="answered"
       [ngClass]="{'answer-correct': answeredCorrect == true, 'answer-wrong': answeredCorrect == false }">
      <strong>{{ questions[currentQuestionIndex]?.answerDescription }}</strong></p>
    <ul class="list-unstyled">
      <li *ngFor="let option of questions[currentQuestionIndex]?.options; let index = index">
        <input *ngIf="!answered" type="radio" name="question{{currentQuestionIndex}}"
               (click)="selectAnswer(index === questions[currentQuestionIndex]?.answer, currentQuestionIndex,index, option)">
        <span> {{ option }}</span>
      </li>
    </ul>
    <button class="button btn-primary merlin-button" *ngIf="!answered" (click)="nextQuestion()">Skip Question</button>
    <button class="button btn-primary merlin-button" *ngIf="answered" (click)="nextQuestion()">Next Question</button>
  </div>
  <div *ngIf="loading" style="margin-bottom: 20px">
    {{ loadingText }}
  </div>

  <div *ngIf="!loading &&  questions.length == 0">
    <input type="text" [(ngModel)]="bookTitle"
           placeholder="Enter a book title with the chapter number, eg. The Phoenix project chapter 1"
           (keydown.enter)="loadQuiz()">
    <p *ngIf="!isQuizAvailable" class="text-danger fst-italic">I am currently unable to generate a quiz based on the
      given criteria.
      Please try to be more specific by adding chapter number or full book name</p>
    <button class="button btn-primary merlin-button" (click)="loadQuiz()">Create Quiz</button>
  </div>

  <div *ngIf="showScore">
    <h2>Your score is: <span class="text-success">{{ score }}</span> out of {{ questions.length }}</h2>
    <div class="mx-3">
      <div class="table-responsive scrollable-table">
        <table class="table">
          <thead>
          <tr>
            <th class="text-center">Question</th>
            <th class="text-center">Your Answer</th>
            <th class="text-center">Correct Answer</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let answer of userAnswers">
            <td class="text-center">{{ answer.question }}</td>
            <td class="text-center"
                [ngClass]="{'answer-correct': answer.choosenAnswer === answer.correctAnswer, 'answer-wrong': answer.choosenAnswer !== answer.correctAnswer}">
              <img *ngIf="answer.choosenAnswer === answer.correctAnswer" src="../../../../assets/merlin/correct.png"
                   alt="Correct" style="width: 20px; height: 20px; margin-right: 5px;">
              <img *ngIf="answer.choosenAnswer !== answer.correctAnswer" src="../../../../assets/merlin/wrong.png"
                   alt="Wrong" style="width: 20px; height: 20px; margin-right: 5px;">
              {{ answer.choosenAnswer }}
            </td>
            <td class="text-center">
              {{ answer.correctAnswer }}
              <br>
              <span class="answer-description">Answer description: {{ answer.correctAnswerDescription }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col">
          <button class="button btn-primary merlin-button" (click)="restartQuiz()">Restart Quiz</button>
        </div>
        <div class="col">
          <button class="button btn-primary merlin-button" (click)="createNewQuiz()">Create new quiz</button>
        </div>
      </div>
    </div>
  </div>
</div>

