<ng-container *featureToggle="'licensePersonalLists'">
  <!-- NLB Bookmark button for NLB non-Staff users -->
  <ng-container *ngIf="isNlbCustomer && !(hasCreateShowcasePermission$ | async); else defaultBookmarkButton">
    <app-nlb-bookmark-button
      *ngIf="isFormatGroup"
      [ariaDescribedBy]="ariaDescribedBy"
      [formatGroup]="$any(fgOrEntity)"
      [tabName]="tabName">
    </app-nlb-bookmark-button>
  </ng-container>

  <ng-template #defaultBookmarkButton>
    <app-bookmark-button
      [ariaDescribedBy]="ariaDescribedBy"
      [fgOrEntity]="fgOrEntity"
      [recordId]="recordId">
    </app-bookmark-button>
  </ng-template>
</ng-container>
