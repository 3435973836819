<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="header d-flex justify-content-between align-items-center">
        <img src="../../../../assets/close.png" class="close-img" (click)="closeChat()"/>
      </div>
      <div class="card position-relative">
        <div class="card-body">
          <div class="frame-box" [ngClass]="{'frame-box-loading': isMerlinTyping}">
            <div *ngFor="let item of chatConversation" class="mb-3">
              <div *ngIf="item.person == 'You'" [innerHTML]="item.person + ': ' + item.response"
                   class="card-text padding" [ngClass]="item.cssClass"></div>
              <div *ngIf="item.person == 'Merlin-no-recommendation'" class="card-text padding"
                   [innerHTML]="'The Famous Wizard Vega'+ ': ' + item.response" [ngClass]="item.cssClass">
              </div>
              <div class="row mb-2">
                <div *ngIf="item.person == 'Merlin'" class="col merlin-skill-recommendation root">
                  <p
                    [innerHTML]="item.person + ':' +item.response.toString().slice(0,item.response.toString().indexOf(':')+1)"
                    class="card-text padding" [ngClass]="item.cssClass"></p>
                  <div class="row align-items-center">
                    <div class="col-12 col-md-3">
                      <div class="left-jacket">
                        <img src="{{item.bookCover}}" class="img-fluid">
                      </div>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="right-content">
                        <a
                          [innerHTML]="item.response.toString().slice(item.response.toString().indexOf(':')+1,item.response.length)"
                          href="{{item.url}}" target="_blank"></a>
                        <p>{{ item.reason }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="item.person == 'Append'" class="col merlin-skill-recommendation root">
                  <div class="row align-items-top">
                    <div class="col-12 col-md-3">
                      <div class="left-jacket">
                        <img src="{{item.bookCover}}" class="img-fluid">
                      </div>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="right-content">
                        <a [innerHTML]="item.response" href="{{item.url}}" target="_blank"></a>
                        <p>{{ item.reason }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isMerlinTyping" class="mb-3">
              <strong>The Famous Wizard Vega is responding</strong>
              <span class="typing-dot">.</span>
              <span class="typing-dot">.</span>
              <span class="typing-dot">.</span>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <input type="text" class="form-control merlin-input" [(ngModel)]="promptText"
                     placeholder="Type your complex and thoughtful request for a recommendation here"
                     (keyup.enter)="$event.preventDefault(); sendMessage()" [disabled]="isMerlinTyping"/>
            </div>
            <div class="col-auto">
              <button class="button btn-primary btn-block merlin-button" (click)="sendMessage()"
                      [disabled]="isMerlinTyping || !promptText.trim()">
                <img src="../../../../assets/merlin/icon-send.png" alt="Send"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
