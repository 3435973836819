import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { SearchRequestBody } from 'search/models/search-results';
import { getSearchRequestBody } from 'search/reducers/root.reducer';
import {
  createSavedSearch,
  loadSavedSearches,
  resetSavedSearchCreatedState,
  resetSavedSearchUpdatedState,
  updateSavedSearch
} from '../../actions/saved-search.actions';
import { SavedSearch } from '../../models/saved-search';
import {
  CreateState,
  getCreateState,
  getSavedSearches,
  getUpdateState,
  SavedSearchState,
  UpdateState
} from '../../reducers/saved-search.reducer';

@Component({
  selector: 'app-save-search-modal-content',
  templateUrl: './save-search-modal-content.component.html',
  styleUrls: ['./save-search-modal-content.component.scss'],
})
export class SaveSearchModalContentComponent implements OnInit, OnDestroy {
  public activeTabId = 0;
  public saveNewSearchForm: UntypedFormGroup;
  public updateSavedSearchForm: UntypedFormGroup;
  public isCreatingSearch: boolean;
  public isUpdatingSearch: boolean;
  public savedSearches: SavedSearch[];
  public readonly showcaseIcon = faConveyorBeltAlt;

  private readonly subscriptions = new Subscription();
  private searchRequestBody: SearchRequestBody;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<SavedSearchState>,
  ) {
    this.store.dispatch(loadSavedSearches());
    this.store.dispatch(resetSavedSearchUpdatedState());
    this.store.dispatch(resetSavedSearchCreatedState());
    this.saveNewSearchForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });
    this.updateSavedSearchForm = this.formBuilder.group({
      id: ['', [Validators.required]],
    });
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.store.select(getSearchRequestBody),
        this.store.select(getSavedSearches),
      ]).subscribe(([searchRequestBody, savedSearches]) => {
        this.searchRequestBody = searchRequestBody;
        this.savedSearches = savedSearches;
      }),
    );

    this.subscriptions.add(
      this.store.select(getCreateState)
      .subscribe((createState) => this.onCreateStateChanged(createState)),
    );

    this.subscriptions.add(
      this.store.select(getUpdateState)
      .subscribe((updateState) => this.onUpdateStateChanged(updateState)),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.activeModal.dismiss();
  }

  public onSubmit() {
    if (this.activeTabId === 0) {
      this.saveNewSearch();
    } else {
      this.updateSavedSearch();
    }
  }

  public get name(): AbstractControl {
    return this.saveNewSearchForm.get('name');
  }

  public get id(): AbstractControl {
    return this.updateSavedSearchForm.get('id');
  }

  private onUpdateStateChanged(updateState: UpdateState) {
    if (updateState?.error) {
      if (updateState.error.status === 404) {
        this.id.setValue('');
        this.id.setErrors({ notFound: 'Saved search not found' });
      } else {
        this.updateSavedSearchForm.setErrors({
          unknown: { message: 'General server error', originalMessage: updateState.error.message },
        });
      }
    }

    if (updateState?.updated) {
      this.activeModal.close();
    }

    this.isUpdatingSearch = updateState?.loading || false;
  }

  private onCreateStateChanged(createState: CreateState) {
    if (createState?.error) {
      if (createState.error.status === 409) {
        this.name.setErrors({ duplicate: 'Save search name duplicate' });
      } else if (createState.error.status === 422 && createState.error.limit) {
        this.saveNewSearchForm.setErrors({
          limit: { message: 'Limit of saved searches exceeded', limit: createState.error.limit },
        });
      } else {
        this.saveNewSearchForm.setErrors({
          unknown: { message: 'General server error', originalMessage: createState.error.message },
        });
      }
    }

    if (createState?.created) {
      this.activeModal.close();
    }

    this.isCreatingSearch = createState?.loading || false;
  }

  private saveNewSearch() {
    this.name.markAsTouched();
    if (!this.saveNewSearchForm.valid || this.isCreatingSearch) {
      return;
    }

    this.store.dispatch(createSavedSearch({
      request: {
        name: this.name.value,
        searchRequest: this.searchRequestBody,
      },
    }));
  }

  private updateSavedSearch() {
    this.id.markAsTouched();
    if (!this.updateSavedSearchForm.valid || this.isUpdatingSearch) {
      return;
    }

    this.store.dispatch(updateSavedSearch({
      request: {
        id: this.id.value,
        searchRequest: this.searchRequestBody,
      },
    }));
  }
}
