<div class="quick-view" [focusOnInit]="true">
  <button class="quick-view__btn-close" aria-label="Close user panel" data-automation-id="user-panel-close-btn"
    cdkMonitorElementFocus (click)="close()">
    <span class="icon icon-close quick-view__icon-close"></span>
  </button>

  <fa-icon class="quick-view__icon-user" size="3x"
           [icon]="isStaffAuthorized ? staffUserIcon : userIcon">
  </fa-icon>

  <ng-container *ngIf="!isStaffAuthorized; else staffUserInfo">
    <ng-container *ngIf="user$ | async as user; else emptyUser">
      <div class="quick-view__name" data-automation-id="patronName">
        <button class="quick-view__link quick-view__link-user-name"
           data-automation-id="goToFullAccountLink"
           (click)="navigate(user, PatronName, customLinks?.accountUrl)">{{userNicknameOrName$ | async}}</button>
      </div>

      <nav class="quick-view__stats-section" aria-label="user related resources">
        <button class="quick-view__stats-item quick-view__link"
           data-automation-id="checkoutsLink"
           (click)="navigate(user, CheckoutsCount, customLinks?.checkoutsPhysicalUrl)">
        <span role="presentation" class="quick-view__stats-number"
              data-automation-id="checkoutsTotal">{{checkoutsTotal$ | async}}</span>

          <span role="presentation" class="quick-view__stats-entity" translate>Checkouts</span>
        </button>
        <button class="quick-view__stats-item quick-view__link"
           data-automation-id="holdsLink"
           (click)="navigate(user, HoldsCount, customLinks?.holdsPhysicalUrl)">
        <span role="presentation" class="quick-view__stats-number"
              data-automation-id="holdsTotal">{{holdsTotal$ | async}}</span>

          <span role="presentation" class="quick-view__stats-entity" translate>holds</span>
        </button>
        <button class="quick-view__stats-item quick-view__link"
           data-automation-id="feesLink"
           (click)="navigate(user, FinesFeesAmount, customLinks?.finesAndFeesUrl)">
        <span role="presentation" class="quick-view__stats-number"
              data-automation-id="finesAndFeesAmount">{{moneyOwed$ | async | configuredCurrency}}</span>

          <span role="presentation" class="quick-view__stats-entity"
                translate>Fines/fees</span>
        </button>
      </nav>
      <div class="quick-view__info-section" *ngIf="!hideInfoSection" data-automation-id="user-info-section">
        <div class="quick-view__info-item">
          <div class="quick-view__info-title" translate>homeLibraryLabel</div>
          <div class="quick-view__info-value" data-automation-id="homeLibraryCode">
            <ng-container *ngIf="user.homeLibraryCode && user.homeLibrary">
              {{ 'location.' + user.homeLibraryCode | translate }}
            </ng-container>
            <ng-container *ngIf="user.homeLibraryCode && !user.homeLibrary">
              {{ 'Unknown' | translate }} ('{{user.homeLibraryCode}}')
            </ng-container>
            <ng-container *ngIf="!user.homeLibraryCode && !user.homeLibrary">
              N/A
            </ng-container>
          </div>
        </div>
        <div class="quick-view__info-item">
          <div class="quick-view__info-title" translate>expirationDate</div>
          <div class="quick-view__info-value" data-automation-id="expirationDate">
            {{(user.expirationDate | configuredDate) || 'none' | translate}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #staffUserInfo>
    <ng-container *ngIf="staffUser$ | async as staffUser; else emptyUser">
      <div class="quick-view__staff__name mb-1" data-automation-id="user-panel-staff-name">
        {{ staffUserName$ | async }}
      </div>
      <div class="quick-view__staff__email" *ngIf="staffUser.info.email"  data-automation-id="user-panel-staff-email">
        {{ staffUser.info.email }}
      </div>
      <div class="quick-view__staff__info my-10" data-automation-id="user-panel-staff-info" translate>
        youAreUsingStaffCredentials
      </div>
    </ng-container>
  </ng-template>

  <ng-template #emptyUser>
    <div class="server-error-message" data-automation-id="empty-user-error-message">
      <div class="icon icon-error"></div>
      <span translate>
        unableToRetrieveAccountInformation
      </span>
      <span class="support-mailbox" *ngIf="guestLibraryEmail">{{ guestLibraryEmail }}</span>
    </div>
  </ng-template>

  <div class="actions-section">
    <button class="app-button button-raised quick-view__button" (click)="signOut()" translate
      data-automation-id="sign-out-button" cdkMonitorElementFocus>
      signOut
    </button>
  </div>
</div>
