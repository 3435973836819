import { Component, EventEmitter, OnDestroy, OnInit, Output, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, } from '@ngrx/store';
import { State } from 'app/reducers';
import { STOP_CUSTOM_PARAM } from 'common/models/custom-code';
import { Subscription } from 'rxjs';
import { ResetResourceFacetsAction, } from 'search/actions/facet.actions';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { GlobalHeaderConfiguration } from 'shared/models/configuration';

@Component({
  selector: 'app-global-header-container',
  templateUrl: './global-header-container.component.html',
  styleUrls: ['./global-header-container.component.scss'],
})
export class GlobalHeaderContainerComponent implements OnInit, OnDestroy {
  public headerConfig: GlobalHeaderConfiguration;
  public url: string;
  public logo: string;
  public stopCustom = false;
  @Output() public skipTo: EventEmitter<string> = new EventEmitter();

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store<State>,
    private readonly configLoader: ConfigurationLoader,
  ) { }

  public ngOnInit() {
    this.headerConfig = this.configLoader.headerConfig;
    this.logo = this.configLoader.logo;
    this.url = this.router.url;

    this.subscriptions.add(this.route.queryParamMap.subscribe((params) => {
      this.stopCustom = params.get(STOP_CUSTOM_PARAM) === 'true';
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public skipToElement(elementName: string) {
    this.skipTo.emit(elementName);
  }

  public resetFacets() {
    this.store.dispatch(new ResetResourceFacetsAction());
  }
}
