import {
  Component,
  Input,
} from '@angular/core';
import { TabEntity, VendorType } from '../../../../models/entity';
import { FormattedResource } from 'app/entity/models/econtent';

export interface PublicNote {
  label: string;
  url: string;
}
export interface AboutTabDescription {
  format?: string;
  edition?: string;
  title?: string;
  dissertation?: string;
  primaryAgent?: TabEntity;
  publisherInformation?: string;
  physicalDescription?: string;
  vendorInformation?: string;
  note?: PublicNote;
}

@Component({
  selector: 'app-about-tab-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input() public description: AboutTabDescription;
  @Input() public visible: boolean;
  @Input() public tabLabel: string;

  public readonly formattedResource = FormattedResource;
  public vendorType = VendorType;
  public boxHeight = '115';
  public moreLessColor = '#4a4b4c';

  public onOpenPublicNoteLink(url: string): void {
    window.open(url, '_blank');
  }
}
