import { Injectable } from '@angular/core';
import { ScriptLoaderService } from 'common/services/script-loader.service';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationType } from '../../../customer-integration';
import { SG_PROD_ENV } from '../../configs/nlb-common.config';

const qrCodeScriptProduction = 'https://eservice.nlb.gov.sg/components/v2/js/bundle.js?key=9WtApleodyni';
const qrCodeScriptStaging = 'https://eservice-stg.nlb.gov.sg/components/v2/js/bundle.js?key=9WtApleodyni';

@Injectable({
  providedIn: 'root',
})
export class NlbScriptService {
  private readonly qrCodeScript = ENVIRONMENT === SG_PROD_ENV ? qrCodeScriptProduction : qrCodeScriptStaging;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly scriptLoaderService: ScriptLoaderService,
  ) {
  }

  public async loadNlbScripts() {
    if (this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB) {
      await this.loadNlbWogaaScript();
      await this.loadNlbQrCodeScript();
    }
  }

  private async loadNlbQrCodeScript(): Promise<void> {
    await this.scriptLoaderService.loadScript({src: this.qrCodeScript});
  }

  private async loadNlbWogaaScript(): Promise<void> {
    await this.scriptLoaderService.loadScript({src: 'https://assets.wogaa.sg/scripts/wogaa.js'});
  }
}
