<div ngbDropdown
     #dropdown="ngbDropdown"
     [autoClose]="'outside'"
     (openChange)="setDropdownOpened($event)"
     class="d-inline-block">
  <button ngbDropdownAnchor
          (click)="$event.stopPropagation(); toggleDropdown(dropdown)"
          [attr.aria-expanded]="selectListOpened"
          [attr.aria-disabled]="disabled"
          [class.button-disabled]="disabled"
          [class]="buttonClass"
          aria-haspopup="listbox"
          [attr.aria-label]="label"
          data-automation-id="select-list-button-toggle">
    <div class="list-button-text">
      <ng-content></ng-content>
    </div>
  </button>
  <div ngbDropdownMenu data-automation-id="select-list-button-dropdown">
    <app-select-list [listItemEntities]="listItemEntities"
                     [lists]="lists"
                     [listIdsSelected]="listIdsSelected"
                     [canCreateNew]="canCreateNew"
                     [trigger]="trigger"
                     [multiselect]="multiselect"
                     [confirmDelete]="confirmDelete"
                     (onDone)="dropdown.close(); onDone.emit()"
                     (onCancel)="dropdown.close(); onCancel.emit()">
    </app-select-list>
  </div>
</div>
