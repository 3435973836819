import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CitationModule } from '../citation/citation.module';
import { NlbBookmarkModule } from '../customer-integration/nlb/nlb-bookmark/nlb-bookmark.module';
import { CopyToClipboardService } from '../services/copy-to-clipboard.service';
import { ShareItModule } from '../share-it/share-it.module';
import { BookmarkButtonComponent } from './components/bookmark-button/bookmark-button.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { DeleteListModalComponent } from './components/delete-list-modal/delete-list-modal.component';
import { EditListNameModalComponent } from './components/edit-list-name-modal/edit-list-name-modal.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { ListsListComponent } from './components/lists-list/lists-list.component';
import { SaveMultipleBookmarksModalComponent } from './components/save-multiple-bookmarks-modal/save-multiple-bookmarks-modal.component';
import { ListEffects } from './effects/list.effects';
import * as forLaterList from './reducers/for-later-list.reducer';
import * as list from './reducers/list.reducer';
import * as copyListUrl from './reducers/copy-list-url.reducer';
import { ListStoreAdapterService } from './services/list-store-adapter.service';
import { ListTransformerService } from './services/list-transformer.service';
import { ListService } from './services/list.service';
import { ModalQueueService } from './services/modal-queue.service';
import { ReadingHistoryModule } from 'app/reading-history/reading-history.module';
import { BookmarkButtonContainerComponent } from './components/bookmark-button-container/bookmark-button-container.component';
import { PermissionsModule } from 'app/permissions/permissions.module';

@NgModule({
  declarations: [
    BookmarksComponent,
    ListCardComponent,
    ListsListComponent,
    BookmarkButtonComponent,
    EditListNameModalComponent,
    DeleteListModalComponent,
    SaveMultipleBookmarksModalComponent,
    BookmarkButtonContainerComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(list.listFeatureKey, list.reducer),
    StoreModule.forFeature(forLaterList.forLaterListFeatureKey, forLaterList.reducer),
    StoreModule.forFeature(copyListUrl.featureKey, copyListUrl.reducer),
    EffectsModule.forFeature([
      ListEffects,
    ]),
    FontAwesomeModule,
    RouterModule,
    SharedModule,
    PerfectScrollbarModule,
    NgbDropdownModule,
    NgbModalModule,
    TippyModule,
    ShareItModule,
    CitationModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    FeatureToggleModule,
    ClipboardModule,
    ReadingHistoryModule,
    NlbBookmarkModule,
    PermissionsModule
  ],
  exports: [
    BookmarkButtonContainerComponent,
    BookmarkButtonComponent,
    BookmarksComponent,
  ],
  providers: [
    ListService,
    ListTransformerService,
    ListStoreAdapterService,
    ModalQueueService,
    CopyToClipboardService,
  ],
})
export class ListModule {
}
