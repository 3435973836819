import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FormatGroup } from 'app/entity/models/entity';
import { Subscription } from 'rxjs/internal/Subscription';
import { removeHoldItem, resetBulkHoldSubmitState, stopBulkHoldsProcessing, submitBulkHolds } from 'user/actions/bulk-hold.actions';
import { GetItPlaceTabHold } from 'user/models/get-it';
import {
  BulkHoldState,
  BulkHoldSubmitState,
  getAvailableFormatGroups,
  getBulkHoldSubmitState,
  getUnavailableFormatGroups
} from 'user/reducers/bulk-hold.reducer';
import { LocationWithName } from '../../../../models/locations';

interface BulkHoldForm {
  pickupLocation: FormControl<string>;
}

@Component({
  selector: 'app-bulk-hold-modal',
  templateUrl: './bulk-hold-modal.component.html',
  styleUrls: ['./bulk-hold-modal.component.scss']
})
export class BulkHoldModalComponent implements OnInit {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public selectedLocationCode: string;
  @Input() public preferredPickupLocationCode: string;
  @Input() public userId: string;
  public unavailableFormatGroups$= this.store.select(getUnavailableFormatGroups);
  public availableFormatGroups: FormatGroup[];
  public bulkHoldForm: FormGroup<BulkHoldForm>;
  public bulkHoldSubmitState: BulkHoldSubmitState;
  private readonly subscription: Subscription = new Subscription();
  @ViewChild('cancelButton') public cancelButton: ElementRef;
  public selectedFormatGroups: FormatGroup[];

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<BulkHoldState>,
    private readonly fb: FormBuilder,
  ) {
    this.store.dispatch(resetBulkHoldSubmitState());
  }

  public ngOnInit() {
    this.bulkHoldForm = this.fb.group({
      pickupLocation: new FormControl(this.selectedLocationCode, Validators.required),
    });

    this.subscription.add(
      this.store.select(getAvailableFormatGroups).subscribe(formatGroups => {
        this.availableFormatGroups = formatGroups;

        this.selectedFormatGroups = this.availableFormatGroups.map(formatGroup => ({
          ...formatGroup,
          materialTabs: formatGroup.materialTabs ? [formatGroup.materialTabs[0]] : []
        }));

        if (formatGroups.length === 0) {
          this.setFocus();
        }
      })
    );

    this.subscription.add(
      this.store.select(getBulkHoldSubmitState).subscribe(bulkHoldSubmitState => {
        this.bulkHoldSubmitState = bulkHoldSubmitState;
        if (bulkHoldSubmitState.submitted) {
          this.bulkHoldForm.disable();
        }
      })
    );
  }

  public onFormatChange(updatedFormatGroups: FormatGroup[]) {
    this.selectedFormatGroups = updatedFormatGroups;
  }

  public isSafeToClose(): boolean {
    return !this.bulkHoldSubmitState.submitted || this.bulkHoldSubmitState.allProcessed || this.bulkHoldSubmitState.allStopped;
  }

  public isSubmitDisabled(): boolean {
    return !this.bulkHoldForm.valid || this.bulkHoldSubmitState.submitted || !this.availableFormatGroups.length;
  }

  public onClose(): void {
    if (this.isSafeToClose()) {
      this.activeModal.dismiss();
    }
  }

  public onStop(): void {
    this.store.dispatch(stopBulkHoldsProcessing());
  }

  public onSubmit() {
    if (this.isSubmitDisabled()) {
      return;
    }
    const holdRequests: GetItPlaceTabHold[] = this.selectedFormatGroups.map((formatGroup) => {
      return {
        patronId: this.userId,
        pickupLocation: this.bulkHoldForm.value.pickupLocation,
        formatGroupId: formatGroup.id,
        materialTypeName: formatGroup.materialTabs[0].name,
        borrowByMail: false,
      };
    });
    this.store.dispatch(submitBulkHolds({holdRequests}));
  }

  public onRemoveItem(item: FormatGroup) {
    this.store.dispatch(removeHoldItem({item}));
  }

  private setFocus() {
    this.cancelButton?.nativeElement.focus();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
