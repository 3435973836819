import { Injectable } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { ISearchData, PassThroughTypeUrlParams } from '../../../../models/customization';
import { CustomDomainLoader } from '../../../../services/custom-domain-loader';

@Injectable({
  providedIn: 'root'
})
export class CustomPassThroughService {
  private readonly customerId: string | null = '';

  constructor(
    private readonly customDomainLoader: CustomDomainLoader,
    private configLoader: ConfigurationLoader,
  ) {
    this.customerId = this.configLoader.configuration?.customerId;
  }

  private removeSpecialCharacter(string: string): string {
    return string.replace(/[^\w\s]/gi, '');
  }

  public getRedirectSearch(keyword: string, url: string) {
    // convert url to lowercase and ##KEYWORD##
    const buildUrl = url.toLowerCase().replace(PassThroughTypeUrlParams.KEYWORD.toLowerCase(), keyword);
    return encodeURI(buildUrl);
  }

  public getRedirectWorks(data: ISearchData, url: string) {
    if (data.author && data.title) {
      const title = this.removeSpecialCharacter(data.title);
      const author = this.removeSpecialCharacter(data.author);
      const regex = /\+/gi;
      const prepareUrl = url.toLowerCase().replace(regex, ' ');

      // convert url to lowercase and ##TITLE## && ##AUTHOR##
      const buildUrl = prepareUrl.toLowerCase()
      .replace(PassThroughTypeUrlParams.TITLE.toLowerCase(), title)
      .replace(PassThroughTypeUrlParams.AUTHOR.toLowerCase(), author);

      return encodeURI(buildUrl);
    }

    if (data?.author) {
      const author = this.removeSpecialCharacter(data.author);

      //Remove Title ##TITLE## not in use, convert url to lowercase
      const prepareURL = url.toLowerCase()
      .replace(PassThroughTypeUrlParams.TITLE.toLowerCase(), '');

      //AUTHOR to lowercase since url is in lowercase
      const buildUrl = prepareURL.replace(PassThroughTypeUrlParams.AUTHOR.toLowerCase(), author);

      return encodeURI(buildUrl);
    }

    if (data?.title) {
      const title = this.removeSpecialCharacter(data.title);

      //Remove AUTHOR ##AUTHOR## not in use, convert url to lowercase && get extra parameters after author tag
      const prepareURL = url.toLowerCase();
      const baseURL = prepareURL.split(PassThroughTypeUrlParams.TITLE.toLowerCase())[0] + title;
      const authorURL = prepareURL.split(PassThroughTypeUrlParams.AUTHOR.toLowerCase());

      if (authorURL?.length > 1) {
        return encodeURI(baseURL + authorURL[1]);
      }

      return encodeURI(baseURL);
    }
  }
}
