import { PageSettings, Settings } from './settings';

export enum PAGE_TYPES {
  KIDS_CATALOG = 3,
  VEGA_CLASSIC = 1,
}

export enum PAGE_STATUS {
  LIVE = 2
}

export interface NetworkPage {
  id: string;
  customer_id: string;
  kiosk_id: string;
  type_id: PAGE_TYPES;
  status_id: number;
  last_modified_time: string;
  last_modified_by: {
    date: string;
    name: string;
    email: string;
  },
  blocks: any[];
  settings?: PageSettings;
}

export interface Page extends NetworkPage {
  settings?: Settings
}

export interface CustomerI {
  customerDomain: string
  env: string[]
  flags?: string[]
}

export type NLBList = {
  name: string;
  abbreviation: string;
  display_name: string;
  url_address: string;
}
