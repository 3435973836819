import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { CustomerIntegrationService } from '../../../../customer-integration/services/customer-integration.service';
import { VendorInfo, VendorType } from '../../../models/entity';

@Component({
  selector: 'app-checked-and-held-items-banner-container',
  templateUrl: './checked-and-held-items-banner-container.component.html',
})
export class CheckedAndHeldItemsBannerContainerComponent implements OnInit {
  @Input() public recordIds: string[];
  @Input() public instanceIds: string[];
  @Input() public isElectronic: boolean;
  @Input() public hasEcontentIssues: boolean;
  @Input() public vendors: Partial<Record<VendorType, VendorInfo>>;

  public customHoldsLink: string;
  public customCheckoutsLink: string;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly customerIntegrationService: CustomerIntegrationService,
  ) { }

  public ngOnInit() {
    const customLinks = this.customerIntegrationService.CustomerIntegrationLinksMap[this.configLoader.customerIntegrationType];
    if (customLinks) {
      this.customHoldsLink = this.isElectronic ? customLinks.holdsElectronicUrl : customLinks.holdsPhysicalUrl;
      this.customCheckoutsLink = this.isElectronic ? customLinks.checkoutsElectronicUrl : customLinks.checkoutsPhysicalUrl;
    }
  }
}
