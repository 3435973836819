import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { SearchRequestBody } from 'search/models/search-results';
import { SearchService } from 'search/services/search.service';
import { Recommendation } from '../models/chatgpt-response-message.model';

@Injectable()
export class VegaStreamInterpreterService {

  private recommendationResultsSubject: Subject<any>;
  public readonly TITLE_AVAILABLE = 'Title found';
  public readonly API_CALL_FAILED = 'API call failed';

  constructor(private searchService: SearchService) {
    this.resetRecommendationsStream();
  }

  getFormatGroupByTitle(title: string) {
    const queryParams: SearchRequestBody = {
      resourceType: 'FormatGroup',
      searchText: `${title}`,
      sorting: 'relevance',
      sortOrder: 'asc',
      searchType: 'everything',
      pageNum: 0,
      pageSize: 10,
    };
    return this.searchService.getSearchResults(queryParams);
  }

  private constructRecommendationUrl(title: string): string {
    const baseUrl = window.location.origin;
    const queryParams = new URLSearchParams({
      query: title,
      searchType: 'everything',
      pageSize: '10'
    });
    return `${baseUrl}/search?${queryParams}`;
  }

  getFormatGroupForRecommendations(recommendation: Recommendation): void {
    this.getFormatGroupByTitle(recommendation.title).pipe(
      map(response => {
        console.info('API response : ', response);
        if (response.data[0].missingWords != undefined) {
          return of({title: recommendation.title, result: this.API_CALL_FAILED});
        }
        return {
          title: response.data[0].title,
          result: this.TITLE_AVAILABLE,
          url: this.constructRecommendationUrl(response.data[0].title),
          reason: recommendation.reason,
          bookCover: response.data[0].coverUrl.medium
        };
      }),
      catchError(error => {
        console.error('API call failed for title:', recommendation.title, error);
        return of({title: recommendation.title, result: this.API_CALL_FAILED});
      }),
      finalize(() => {
        this.recommendationResultsSubject.complete();
      })
    ).subscribe(result => {
      this.recommendationResultsSubject.next(result);
    });
  }

  get recommendationResults$() {
    return this.recommendationResultsSubject.asObservable();
  }

  resetRecommendationsStream() {
    this.recommendationResultsSubject = new Subject<any>();
  }

}
