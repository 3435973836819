import { Configuration, DisplayConfiguration, GlobalHeaderConfiguration, LoginType } from 'shared/models/configuration';
import { CustomerIntegrationType } from '../customer-integration/customer-integration';
import { LibraryInfoConfiguration } from 'shared/models/library-info';
import { InnReachType } from '../models/customization';
import { customDomainLoader } from '../services/custom-domain-loader';
import { nativeLanguageNamesMap } from './native-languages-map';
import { getJSON } from './utils/get-json';

export class ConfigurationLoader {
  public configurationPromise: Promise<Configuration>;
  public config: Configuration;
  public location: Location;
  public queryParams: {[key: string]: string};
  public availableLanguages: { code: string, name: string }[] = [
    { code: 'en-US', name: 'English (US)' },
  ];

  constructor() {
    this.location = (window as any).location;
    this.queryParams = this.location.search.slice(1).split('&')
      .map((str) => str.split('='))
      .reduce((accum, [key, value]) => Object.assign(accum, {[key]: value}), {});
  }

  public get headerConfig() {
    return this.config && this.config.globalHeaderConfiguration || {
      title: 'Library name',
    } as GlobalHeaderConfiguration;
  }

  public get loginType(): LoginType | undefined {
    return this.config?.globalHeaderConfiguration?.loginType;
  }

  public get linkResolver() {
    return this.config && this.config.linkResolverConfiguration;
  }

  public get logo() {
    return this.config && this.config.logo && this.config.logo.url;
  }

  public get configuration() {
    return this.config && this.config.configuration;
  }

  public get ilsType() {
    return this.config && this.config.ilsType;
  }

  public get timeoutSeconds() {
    return this.config && this.config.timeoutSeconds;
  }

  public get libraryInfoConfiguration(): LibraryInfoConfiguration {
    return this.config && this.config.libraryInfoWidget;
  }

  public get libraryInformationEnabled() {
    return this.config && this.config.libraryInfoWidget
    && this.config.libraryInfoWidget.libraryInfo.enabled;
  }

  public get localizationConfiguration() {
    return this.config && this.config.localizationConfiguration;
  }

  public get siteId() {
    return this.config && this.config.siteId;
  }

  public get customerLanguages() {
    return this.config && this.config.customerLanguages;
  }

  public get vendorsLogo(): { [key: string]: string } {
      const vendorLogoMap: { [key: string]: string } = {};
      this.config && this.config.vendorsLogo?.forEach((vendorLogo) => {
          vendorLogoMap[vendorLogo.name] = vendorLogo.url;
        });
      return vendorLogoMap;
  }

  public get connectionsConfiguration() {
    return this.config && this.config.connections;
  }

  public get displayConfiguration(): DisplayConfiguration {
    return this.config?.displayConfiguration;
  }

  public get passThroughConfig() {
    return this.config && this.config.passthroughConnections;
  }

  public get innReachConfig() {
    return this.config && this.config.innReachSystemConfigurations && this.config.innReachSystemConfigurations[0] || {
      systemName: '',
      type: InnReachType.WebPac,
      url: '',
      imageUrl: '',
      hideSystemName: false,
    };
  }

  public get syndeticsConnection() {
    return this.config && this.config.syndeticsConnection;
  }

  public get syndeticsUnboundConnection() {
    return this.config?.syndeticsUnboundConnection;
  }

  public get customerIntegrationType(): CustomerIntegrationType | undefined {
    return this.config?.customerIntegrationType;
  }

  public async loadConfiguration(locale?: string) {
    if (this.configurationPromise) {
      return this.configurationPromise;
    }
    const { configurationId, logoKey, previewLanguage } = this.queryParams;
    const useSpecificConfiguration = configurationId && (!locale || locale === previewLanguage);
    const url = useSpecificConfiguration ?
      `api/customization/configurations-extended/${configurationId}?logoKey=${logoKey}` :
      'api/search-result/customization/configurations-extended/latest';
    const headers: { [key: string]: any } = {
      'api-version': useSpecificConfiguration ? '2' : '1',
    };

    if (locale) {
      headers['accept-language'] = [locale].concat(window.navigator.languages);
    }

    const domain = await customDomainLoader.loadDomain();
    const configP = getJSON<Configuration>(
      `${API_URL}/${url}`, headers, domain
    )
      .catch( () => ({ uiLocale: 'en-US' } as Configuration) );

    return this.configurationPromise = configP.then((config) => {
      this.config = config;

      if (config.customerLanguages) {
        this.availableLanguages = config.customerLanguages
        .filter((customerLanguage) => customerLanguage.enabled)
        .map((customerLanguage) => {
          const splitedLocale = customerLanguage.language.name.split('-');

          return {
            code: customerLanguage.language.name,
            name: nativeLanguageNamesMap[splitedLocale[0]].concat(splitedLocale[1] ? ` (${splitedLocale[1]})` /* istanbul ignore next */ : ''),
          };
        });
      }

      return config;
    });
  }
}

export const configurationLoader = new ConfigurationLoader();
