import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BulkSelectModule } from '../bulk-select/bulk-select.module';
import { NlbHoldModule } from '../customer-integration/nlb/nlb-hold/nlb-hold.module';
import { NlbQrCodeModule } from '../customer-integration/nlb/nlb-qr-code/nlb-qr-code.module';
import { ElementsV2Module } from '../elements-v2/elements-v2.module';
import { ListModule } from '../list/list.module';
import { AboutPopupComponent } from './components/about-popup/about-popup.component';
import { DescriptionComponent } from './components/about-popup/components/description/description.component';
import { DetailsComponent } from './components/about-popup/components/details/details.component';
import { NoFoundDataComponent } from './components/about-popup/components/no-found-data/no-found-data.component';
import { AvailabilityIconComponent } from './components/availability-icon/availability-icon.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import {
  CheckedAndHeldItemsBannerContainerComponent
} from './components/checked-and-held-items/checked-and-held-items-banner-container/checked-and-held-items-banner-container.component';
import {
  CheckedAndHeldItemsBannerComponent
} from './components/checked-and-held-items/checked-and-held-items-banner/checked-and-held-items-banner.component';
import { ElectronicItemAvailabilityComponent } from './components/electronic-item-availability/electronic-item-availability.component';
import { AgentOrConceptCardComponent } from './components/entities/agent-or-concept-card/agent-or-concept-card.component';
import { EntitiesComponent } from './components/entities/entities.component';
import { RollupCardComponent } from './components/entities/rollup-card/rollup-card.component';
import {
  RollupTabsContentComponent
} from './components/entities/rollup-card/rollup-tabs/rollup-tabs-content/rollup-tabs-content.component';
import { RollupTabsComponent } from './components/entities/rollup-card/rollup-tabs/rollup-tabs.component';
import { GetItButtonComponent } from './components/get-it-button/get-it-button.component';
import { GetItModalContentComponent } from './components/get-it-modal-content/get-it-modal-content.component';
import { HoldsAndCopiesMessageComponent } from './components/holds-and-copies-message/holds-and-copies-message.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { LibraryHasComponent } from './components/library-has/library-has.component';
import {
  LocationAndEditionTableContainerComponent
} from './components/location-and-edition-table/location-and-edition-table-container.component';
import { LocationAndEditionTableComponent } from './components/location-and-edition-table/location-and-edition-table.component';
import { LocationTableComponent } from './components/location-table/location-table.component';
import { MaterialTabTypeLabelComponent } from './components/material-tab-type-label/material-tab-type-label.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PhysicalItemAvailabilityComponent } from './components/physical-item-availability/physical-item-availability.component';
import { PreviewLinksComponent } from './components/preview-links/preview-links.component';
import {
  LocationListDrawerComponent
} from './components/rollup-side-drawer-container/locations-list-drawer/location-list-drawer.component';
import { RollupSideDrawerContainer } from './components/rollup-side-drawer-container/rollup-side-drawer-container.component';
import {
  DrawerHoldingsComponent
} from './components/rollup-side-drawer-container/rollup-side-drawer/drawer-holdings/drawer-holdings.component';
import { DrawerItemComponent } from './components/rollup-side-drawer-container/rollup-side-drawer/drawer-items/drawer-item.component';
import {
  DrawerLibraryHasComponent
} from './components/rollup-side-drawer-container/rollup-side-drawer/drawer-library-has/drawer-library-has.component';
import { RollupSideDrawerComponent } from './components/rollup-side-drawer-container/rollup-side-drawer/rollup-side-drawer.component';
import { RollupTabsContentLocationsComponent } from './components/rollup-tabs-content-locations/rollup-tabs-content-locations.component';
import { RollupTabsContentStatusComponent } from './components/rollup-tabs-content-status/rollup-tabs-content-status.component';
import { SyndeticsUnboundAuthorBioComponent } from './components/syndetics-unbound-author-bio/syndetics-unbound-author-bio.component';
import {
  SyndeticsUnboundSeeFullExcerptComponent
} from './components/syndetics-unbound-see-full-excerpt/syndetics-unbound-see-full-excerpt.component';
import { ToolbarButtonsComponent } from './components/toolbar-buttons/toolbar-buttons.component';
import { ToolbarLineComponent } from './components/toolbar-line/toolbar-line.component';
import { ViewToggleComponent } from './components/view-toggle/view-toggle.component';
import { CheckedAndHeldItemsUpdateContainer } from './containers/checked-and-held-items-update-container.component';
import { OverdriveAndSierraAvailabilityUpdateContainer } from './containers/overdrive-and-sierra-availability-update-container.component';
import { DrawerEffects } from './effects/drawer.effects';
import { EntityEffects } from './effects/entity.effects';
import { FullEntityEffects } from './effects/full-entity.effects';
import { GetItAfterLoginEffects } from './effects/get-it-after-login.effects';
import { GetItEffects } from './effects/get-it.effects';
import { VendorsEffects } from './effects/vendors.effects';
import * as entity from './reducers/entity.reducer';
import * as fullEntity from './reducers/full-entity.reducer';
import { NlbItemLocatorModule } from '../customer-integration/nlb/nlb-item-locator/nlb-item-locator.module';
import { NlbSiteLinkModule } from 'app/customer-integration/nlb/nlb-site-link/nlb-site-link.module';
import { NlbBorrowNDeliverModule } from '../customer-integration/nlb/nlb-borrow-n-deliver/nlb-borrow-n-deliver.module';

const COMPONENTS = [
  EntitiesComponent,
  PaginatorComponent,
  RollupCardComponent,
  RollupTabsContentComponent,
  RollupTabsComponent,
  LocationListDrawerComponent,
  RollupSideDrawerContainer,
  DrawerHoldingsComponent,
  DrawerItemComponent,
  DrawerLibraryHasComponent,
  RollupSideDrawerComponent,
  RollupTabsContentLocationsComponent,
  RollupTabsContentStatusComponent,
  PhysicalItemAvailabilityComponent,
  HoldsAndCopiesMessageComponent,
  LibraryHasComponent,
  ToolbarButtonsComponent,
  ToolbarLineComponent,
  MaterialTabTypeLabelComponent,
  AvailabilityIconComponent,
  GetItButtonComponent,
  AboutPopupComponent,
  DescriptionComponent,
  DetailsComponent,
  NoFoundDataComponent,
  ItemDetailsComponent,
  PreviewLinksComponent,
  LocationAndEditionTableComponent,
  LocationTableComponent,
  LocationAndEditionTableContainerComponent,
  SyndeticsUnboundAuthorBioComponent,
  SyndeticsUnboundSeeFullExcerptComponent,
  GetItModalContentComponent,
  AgentOrConceptCardComponent,
  BackButtonComponent,
  ElectronicItemAvailabilityComponent,
  ViewToggleComponent,
  CheckedAndHeldItemsBannerComponent,
  CheckedAndHeldItemsBannerContainerComponent,
];

const CONTAINERS = [
  OverdriveAndSierraAvailabilityUpdateContainer,
  CheckedAndHeldItemsUpdateContainer,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...CONTAINERS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FeatureToggleModule,
    NgbModalModule,
    NgbNavModule,
    ListModule,
    DragScrollModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    TippyModule,
    ReactiveFormsModule,
    StoreModule.forFeature(entity.entityFeatureKey, entity.reducer),
    StoreModule.forFeature(fullEntity.fullEntityFeatureKey, fullEntity.reducer),
    EffectsModule.forFeature([
      DrawerEffects,
      EntityEffects,
      GetItEffects,
      GetItAfterLoginEffects,
      VendorsEffects,
      FullEntityEffects,
    ]),
    ElementsV2Module,
    NlbQrCodeModule,
    NlbHoldModule,
    NlbItemLocatorModule,
    NlbSiteLinkModule,
    NlbBorrowNDeliverModule,
    BulkSelectModule,
  ],
  exports: [
    ...COMPONENTS,
    ...CONTAINERS,
  ],
})
export class EntityModule {
}
