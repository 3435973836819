import { CustomerIntegrationHiddenFeatures } from '../../customer-integration';

export const NLB_CUSTOMER_INTEGRATION_HIDDEN_FEATURES: CustomerIntegrationHiddenFeatures = {
  accountTab: true,
  bookmarksTab: true,
  readingHistoryTab: true,
  userInfoSection: true,
};

export const SG_PROD_ENV = 'sg-prod';
