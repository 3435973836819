import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChatComponent } from './components/chat/chat.component';
import { DialogComponent } from './components/merlin/dialog/dialog.component';
import { MerlinComponent } from './components/merlin/merlin.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { WizardAiComponent } from './components/wizard-ai/wizard-ai.component';
import { ChatGptService } from './services/chat-gpt.service';
import { VegaStreamInterpreterService } from './services/vega-stream-interpreter.service';

@NgModule({
  declarations: [
    MerlinComponent,
    DialogComponent,
    ChatComponent,
    QuizComponent,
    WizardAiComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    WizardAiComponent,
  ],
  providers: [
    ChatGptService,
    VegaStreamInterpreterService,
  ]
})
export class WizardAiModule { }
