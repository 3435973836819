import { Component, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { ChatGptService, StreamType } from '../../services/chat-gpt.service';
import { VegaStreamInterpreterService } from '../../services/vega-stream-interpreter.service';
import { Recommendation } from '../../models/chatgpt-response-message.model';
import { Subscription } from 'rxjs';


enum Role {
  YOU = 'You',
  MERLIN = 'Vega',
  MERLIN_APPEND = 'Append',
  MERLIN_NO_RECOMMENDATION = 'Merlin-no-recommendation'
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  public chatConversation: Array<any> = [];
  public promptText = '';
  public isMerlinTyping = false;


  @Output() closeChatEvent = new EventEmitter();
  private subscriptions: Subscription = new Subscription();
  private RECOMMENDATION_TITLE = 'I found some recommendations let me explain why: ';
  private RECOMMENDATIONS_NOT_FOUND = 'Sorry, but I don\'t have anything to recommend at this time.';
  private isRecommendationAvailable = false;


  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly chatGptService: ChatGptService,
    private readonly vegaStreamInterpreterService: VegaStreamInterpreterService) {
  }

  public async sendMessage() {
    if (!this.promptText.trim()) return;
    this.isMerlinTyping = true;
    this.isRecommendationAvailable = false;
    this.pushChatContent(this.promptText, Role.YOU, 'person', '', '', '');
    try {
      const {stream, streamId} = await this.chatGptService.sendMessage(this.promptText);
      await this.chatGptService.processStream(StreamType.PROCESSING_RECOMMENDATION_STREAM, streamId, stream,
        this.displayRecommendations.bind(this),
        this.displayNoRecommendationsFoundResponse.bind(this));
    } catch (error) {
      console.error('Error asking for recommendation:', error);
    } finally {
      this.isMerlinTyping = false;
      this.promptText = '';
      this.cdr.detectChanges();
    }
  }


  public displayChatGptResponse(title: string, response?: string, url?: string, reason?: string, bookCover?: string) {
    if (!response) return;

    if (this.isLastMessageFromMerlin()) {
      this.pushChatContent(response, Role.MERLIN_APPEND, 'bot', url, reason, bookCover);
    } else {
      this.isRecommendationAvailable = true;
      const message = title + response;
      this.pushChatContent(message, Role.MERLIN, 'bot', url, reason, bookCover);
    }
  }

  private isLastMessageFromMerlin() {
    return this.chatConversation.length > 0 &&
      (this.chatConversation[this.chatConversation.length - 1].person === Role.MERLIN
        || this.chatConversation[this.chatConversation.length - 1].person === Role.MERLIN_APPEND);
  }

  public displayNoRecommendationsFoundResponse() {
    if (!this.isRecommendationAvailable) {
      this.pushChatContent(this.RECOMMENDATIONS_NOT_FOUND, Role.MERLIN_NO_RECOMMENDATION, 'bot', '', '', '');
      this.cdr.detectChanges();
    }
  }

  public pushChatContent(content: string, person: Role, cssClass: string, url: string, reason: string, bookCover: string) {
    this.chatConversation.push({person, response: content, cssClass, url, reason, bookCover});
    this.cdr.detectChanges();
  }

  public displayRecommendations(recommendation: Recommendation) {
    this.vegaStreamInterpreterService.resetRecommendationsStream();
    this.unsubscribeRecommendationResults();
    this.vegaStreamInterpreterService.getFormatGroupForRecommendations(recommendation);
    this.subscribeToRecommendationResults();
  }

  private subscribeToRecommendationResults(): void {
    const sub = this.vegaStreamInterpreterService.recommendationResults$.subscribe({
      next: (book: { title: string; result: string, url: string, reason: string, bookCover: string }) => {
        if (book.result == this.vegaStreamInterpreterService.TITLE_AVAILABLE) {
          this.displayChatGptResponse(this.RECOMMENDATION_TITLE, book.title, book.url, book.reason, book.bookCover);
        }
      },
      complete: () => {
      },
      error: (error) => {
        console.error('Error receiving recommendation results:', error);
      }
    });
    this.subscriptions.add(sub);
  }

  public closeChat() {
    this.resetAllFields();
    this.unsubscribeRecommendationResults();
    this.chatGptService.cancelRecommendationsStream();
    this.closeChatEvent.emit();
  }

  private resetAllFields() {
    this.promptText = '';
    this.chatConversation = [];
    this.isRecommendationAvailable = false;
  }

  private unsubscribeRecommendationResults(): void {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
  }

  public ngOnDestroy() {
    this.chatGptService.cancelRecommendationsStream();
    this.unsubscribeRecommendationResults();
  }
}
