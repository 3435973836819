<app-bookshelf-card [name]="savedSearch.name"
                    [items]="savedSearch.coverConfigs"
                    [ariaTitleId]="ariaTitleId"
                    [focus$]="focus$"
                    [focusActionsMenuToggleButton$]="focusActionsMenuToggleButton$"
                    [focusShareMenuToggleButton$]="focusShareMenuToggleButton$"
                    (cardClick)="runSavedSearch()"
                    (triggerAction)="triggerCardAction($event)"
                    (triggerShareAction)="triggerCardShareAction($event)">
  <span *cardAction="cardActionTypeEnum.edit">
    <app-bookshelf-card-action-item [icon]="editIcon" messageKey="savedSearchEditNameAction">
    </app-bookshelf-card-action-item>
  </span>

  <ng-container *featureToggle="'licenseCustomShowcases'">

                  <!-- VIEW SHOWCASE BTN -->
    <ng-container>
      <ng-container *ngIf="!isAuthPatronFlagEnabled">
        <ng-container *ngIf="savedSearch.showcaseRef && (hasViewShowcasePermission$ | async); else createActionTemplate">
          <ng-container *cardAction="cardActionTypeEnum.viewShowcase">
            <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="savedSearchViewShowcaseAction">
            </app-bookshelf-card-action-item>
          </ng-container>

          <app-bookshelf-card-status *cardStatus
                                     [cardType]="bookshelfCardType.showcase"
                                     [published]="relatedShowcase?.published"
                                     [loading]="false"
                                     (onClick)="viewShowcase()">
          </app-bookshelf-card-status>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isAuthPatronFlagEnabled">
        <ng-container *ngIf="savedSearch.showcaseRef; else createActionTemplate">
          <ng-container *authorizationDirective="{
              permission: sitePermissions.SHOWCASE_VIEW
            }"
          >
            <ng-container *cardAction="cardActionTypeEnum.viewShowcase">
              <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="savedSearchViewShowcaseAction">
              </app-bookshelf-card-action-item>
            </ng-container>

            <app-bookshelf-card-status *cardStatus
                                       [cardType]="bookshelfCardType.showcase"
                                       [published]="relatedShowcase?.published"
                                       [loading]="false"
                                       (onClick)="viewShowcase()">
            </app-bookshelf-card-status>
          </ng-container>
        </ng-container>
      </ng-container>

    </ng-container>

              <!-- CREATE BTN -->

    <ng-template #createActionTemplate>
      <ng-container *ngIf="isAuthPatronFlagEnabled">
        <ng-container
          *authorizationDirective="{
            permission: sitePermissions.SHOWCASE_CREATE
          }"
        >
          <ng-container *cardAction="cardActionTypeEnum.createShowcase">
            <app-bookshelf-card-action-item [icon]="showcaseIcon"
                                            messageKey="savedSearchCreateShowcaseAction"></app-bookshelf-card-action-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isAuthPatronFlagEnabled">
        <ng-container *ngIf="hasCreateShowcasePermission$ | async">
          <ng-container *cardAction="cardActionTypeEnum.createShowcase">
            <app-bookshelf-card-action-item [icon]="showcaseIcon"
                                            messageKey="savedSearchCreateShowcaseAction"></app-bookshelf-card-action-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>

  <span *cardAction="cardActionTypeEnum.delete">
    <app-bookshelf-card-action-item [icon]="trashIcon" messageKey="savedSearchDeleteAction">
    </app-bookshelf-card-action-item>
  </span>

  <span *cardShareAction="cardShareActionTypeEnum.email">
    <app-bookshelf-card-action-item [icon]="emailIcon" messageKey="savedSearchEmailAction">
    </app-bookshelf-card-action-item>
  </span>
  <span *cardShareAction="cardShareActionTypeEnum.copyLink">
    <app-bookshelf-card-action-item [icon]="copyIcon" messageKey="savedSearchCopySearchLinkAction">
    </app-bookshelf-card-action-item>
  </span>

  <app-bookshelf-card-action-state
    [state]="
      displayState === displayStateEnum.LOADING ? {type: cardActionStateTypeEnum.loading}
      : displayState === displayStateEnum.SHARE_COPIED ? {type: cardActionStateTypeEnum.success, messageKey: 'savedSearchCopied'}
      : displayState === displayStateEnum.SHARE_FAILED ? {type: cardActionStateTypeEnum.error, messageKey: 'savedSearchShareError'}
      : displayState === displayStateEnum.RUN_FAILED ? {type: cardActionStateTypeEnum.error, messageKey: 'savedSearchRunError'}
      : {type: cardActionStateTypeEnum.none}
    "
    [clickOutsideMessageEnabled]="
      displayState === displayStateEnum.SHARE_COPIED
      || displayState === displayStateEnum.SHARE_FAILED
      || displayState === displayStateEnum.RUN_FAILED
    "
    (clickOutsideMessage)="clearStatus()"
  >
  </app-bookshelf-card-action-state>
</app-bookshelf-card>
