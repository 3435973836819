import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { tryToOpenSaveSearchModal } from '../../actions/saved-search.actions';

@Component({
  selector: 'app-save-search-button',
  templateUrl: './save-search-button.component.html',
  styleUrls: ['./save-search-button.component.scss'],
})
export class SaveSearchButtonComponent {
  constructor(private readonly store: Store) {
  }

  public onSaveSearch(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(tryToOpenSaveSearchModal());
  }
}
