<div class="get-it-modal-dialog p-4" data-automation-id="get-it-modal-dialog">
  <app-modal-header
    title="{{'getThisItemDialogTitle' | translate}}"
    titleId="get-it-modal-title"
    closeId="close-get-it-dialog-btn"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body">
    <div data-automation-id="get-it-requested-volume" *ngIf="volume">
      <div class="requested-label mb-2" translate>requestedVolume</div>
      <div data-automation-id="get-it-requested-volume-value">{{volume}}</div>
    </div>
    <div data-automation-id="get-it-requested-copy" *ngIf="itemVolume">
      <div class="requested-label mb-2" translate>requestedCopy</div>
      <div data-automation-id="get-it-requested-copy-value">{{itemVolume}}</div>
    </div>
    <form [formGroup]="getItForm" class="get-it-form" (ngSubmit)="onSubmit()">
      <app-pickup-location-select
        formControlName="pickupLocation"
        [pickupLocations]="pickupLocations"
        [selectedLocationCode]="selectedLocationCode"
        [dataAutomationId]="'pickupLocationDropdown'">
      </app-pickup-location-select>

      <section *ngIf="borrowByMailConfiguration?.enabled">
        <ins-bs-checkbox
          [checked]="borrowByMail.value"
          [containerClass]="['my-4', 'mr-3']"
          (change)="onBorrowByMailChange($event.checked)"
          [aria-label]="'borrowByMailTitle' | translate"
          data-automation-id="borrow-by-mail-checkbox">
          <span class="borrow-by-mail__checkbox" translate>borrowByMailTitle</span>
        </ins-bs-checkbox>

        <app-borrow-by-mail-user-address
          *ngIf="borrowByMail.value"
          [userAddress]="userAddress">
        </app-borrow-by-mail-user-address>

        <div
          *ngIf="borrowByMail.value"
          data-automation-id="borrow-by-mail-message"
          class="d-flex align-items-center p-3 borrow-by-mail-message mt-3">
          <span class="fas fa-check-circle fa-4x check-icon" aria-hidden="true"></span>
          <span class="ml-2">{{borrowByMailConfiguration.deliveryChargeMessage}}</span>
        </div>
      </section>

      <section class="hold-needed-date" *ngIf="ilsType === IlsType.sierra || (ilsType === IlsType.polaris && holdActivationDateSetting)">
        <ins-bs-checkbox class="hold-needed-date__checkbox"
                         [checked]="getItForm.value.hasExpirationDate"
                         [containerClass]="['my-4', 'mr-3']"
                         (change)="onChange($event.checked)"
                         [aria-label]="'dontNeedText' | translate: {timeReference: ilsType === IlsType.polaris ? 'before' : 'after'}"
                         data-automation-id="dont-need-checkbox">
          <span class="dont-need-text" translate>{{ 'dontNeedText' | translate: {timeReference: ilsType === IlsType.polaris  ? 'before' : 'after'} }}</span>
        </ins-bs-checkbox>
        <div class="hold-needed-date__input" *ngIf="getItForm.value.hasExpirationDate">
          <div *ngIf="ilsType === IlsType.polaris">
            <label for="needed-from-date"
                   data-automation-id="needed-from-date-label"
                   class="hold-needed-date__label">
              {{ 'neededFromLabel' | translate }}
            </label>
            <div class="form-group form-inline">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-outline-flint" (click)="pickerNeededFrom.open()" attr.aria-label="{{ 'calendarNeededFrom' | translate }}"
                          data-automation-id="toggle-datepicker-from">
                    <fa-icon [icon]="calendarIcon"></fa-icon>
                  </button>
                </div>
                <input class="form-control" id="needed-from-date" [matDatepicker]="pickerNeededFrom" [min]="today" formControlName="neededFrom"
                       [class.is-invalid]="getItForm.get('neededFrom')?.invalid" data-automation-id="needed-from-date" attr.aria-label="{{ 'neededFrom' | translate }}">
                <mat-datepicker #pickerNeededFrom color="accent"></mat-datepicker>
              </div>
            </div>
            <div class="hold-needed-date__input__date-format">{{ dateFormat }}</div>
          </div>

          <div *ngIf="ilsType === IlsType.sierra">
            <label for="needed-by-date"
                   data-automation-id="needed-by-date-label"
                   class="hold-needed-date__label">
              {{ 'neededByLabel' | translate }}
            </label>
            <div class="form-group form-inline">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button type="button" class="btn btn-outline-flint" (click)="pickerNeededBy.open()" attr.aria-label="{{ 'calendarNeededBy' | translate }}"
                          data-automation-id="toggle-datepicker-by">
                    <fa-icon [icon]="calendarIcon"></fa-icon>
                  </button>
                </div>
                <input class="form-control" id="needed-by-date" [matDatepicker]="pickerNeededBy" [min]="today" formControlName="neededBy"
                       [class.is-invalid]="getItForm.get('neededBy')?.invalid" data-automation-id="needed-by-date" attr.aria-label="{{ 'neededBy' | translate }}">
                <mat-datepicker #pickerNeededBy color="accent"></mat-datepicker>
              </div>
            </div>
            <div class="hold-needed-date__input__date-format">{{ dateFormat }}</div>
          </div>
        </div>
      </section>

      <section class="actions">
        <button
          class="ins-buttons button-primary flint"
          type="submit"
          [disabled]="!getItForm.value.pickupLocation || (isLoading$ | async)"
          data-automation-id="confirm-get-it-btn"
          translate>
          confirmLocation
        </button>
        <button
          type="button"
          class="ins-buttons button-secondary flint"
          (click)="onClose()"
          [disabled]="(isLoading$ | async)"
          data-automation-id="cancel-get-it-btn"
          translate>
          cancelCapitalized
        </button>
      </section>
    </form>
  </div>
  <div aria-atomic="true" aria-live="assertive" role="alert">
    <p *ngIf="errorMessage"
         class="error-message py-2 px-3 text-center"
         data-automation-id="location-error">
         {{ errorMessage }}
    </p>
  </div>
  <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
    <div *ngIf="(isLoading$ | async)"
          class="hold-spinner-border spinner-border text-primary"
          data-automation-id="holds-loading">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
</div>
