<label class="checkbox-label d-flex align-items-center mb-0">
  <div class="checkbox-container position-relative"
       [ngClass]=containerClass>
    <input #checkbox
           type="checkbox"
           class="sr-only position-absolute m-0"
           [checked]="checked"
           [disabled]="disabled"
           (change)="onChange($event)"
           [attr.aria-label]="ariaLabel || null"
           [attr.aria-labelledby]="ariaLabelledby || null"
    >
    <span class="checkbox-outline rounded-circle position-absolute"></span>
    <span class="checkbox-custom position-absolute"></span>
  </div>
  <span class="checkbox-label gu-unselectable">
    <ng-content></ng-content>
  </span>
</label>
