import { Injectable } from '@angular/core';
import { DictionaryItem, DictionaryTypes, } from 'app/models/dictionaries';
import { DictionariesService } from 'app/services/dictionaries.service';
import { ElectronicLocator } from 'search/models/modal-window-data';
import {
  ClassificationItem,
  ExtractCallNumberData,
  LocationAvailability,
  MaterialTypeGroupResult,
  MaterialTypeLocationAvailability,
  RollupResult,
  SearchResult,
} from 'search/models/search-results';
import { PublicNote } from '../../entity/components/about-popup/components/description/description.component';
import { AboutTab, Entity, EntityTypes, FormatGroup, TabEntity } from '../../entity/models/entity';

@Injectable()
export class SearchResultHelper {
  constructor(
    private readonly dictionariesService: DictionariesService,
  ) {
  }

  // TODO a significant amount of this code is wrong or no longer implemented
  public getAboutTabData(data: SearchResult): AboutTab {
    return !data ? null : {
      description: {
        format: this.extractMaterialTypeDescription(data),
        title: this.extractTitle(data),
        edition: this.extractEdition(data),
        dissertation: this.extractDissertation(data),
        primaryAgent: this.extractPrimaryAgent(data),
        publisherInformation: this.extractPublisherInformation(data),
        vendorInformation: data.nonBibframe?.eContent?.[0]?.vendor || '',
        physicalDescription: this.extractPhysicalDescription(data),
        note: data.electronicLocator ? this.getPublicNoteFromElectronicLocator(data.electronicLocator) : null,
      },
      details: {
        frequency: this.extractFrequency(data),
        formerTitle: this.extractFormerTitle(data),
        abbreviatedTitle: this.extractAbbreviatedTitle(data),
        genre: this.extractGenre(data),
        language: this.extractLanguage(data),
        intendedAudience: this.extractIntendedAudience(data),
        supplementaryContent: this.extractSupplementaryContent(data),
        credits: this.extractCredits(data),

        series: this.extractSeries(data),
        identifiers: this.extractAllIdentifiers(data),
        awards: this.extractAwards(data),
        tableOfContents: this.extractTableOfContents(data),
        bibId: this.extractBibId(data),
      },
    } as AboutTab;
  }

  public getPublicNoteFromElectronicLocator(electronicLocator: ElectronicLocator[]): PublicNote {
    const hasNote = electronicLocator[0] && electronicLocator[0].note && electronicLocator[0].note.length;
    const publicNote = hasNote ? electronicLocator[0].note.filter((note) => note.noteType.toLowerCase() === 'public note') : null;
    return publicNote && publicNote.length ? {label: publicNote[0].label, url: electronicLocator[0].url} : null;
  }

  public getMinDueDate(locationAvailability: MaterialTypeLocationAvailability[]): string {
    const filteredLocation = this.getFilteredLocations(locationAvailability);
    const locationAvailabilityWithMinDueDate = this.sortByMinDueDate(filteredLocation)[0];
    return locationAvailabilityWithMinDueDate && locationAvailabilityWithMinDueDate.minDueDate || '';
  }

  public statusBasedAvailabilityPredicate(item: MaterialTypeLocationAvailability): boolean {
    return item && item.status === '-';
  }

  public minDueDateBasedAvailabilityPredicate(item: MaterialTypeLocationAvailability): boolean {
    const itemStatusAvailability = item && item.status ?
      this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.ITEM_STATUSES, item.status) as DictionaryItem : null;
    return itemStatusAvailability && itemStatusAvailability.availabilityStatus === 'Available' && (!item.minDueDate || item.hasEmptyDueDate);
  }

  public getAvailableLocations(
    data: MaterialTypeLocationAvailability[],
    availabilityPredicate: (item: MaterialTypeLocationAvailability) => boolean,
  ) {
    return data
    .filter(availabilityPredicate.bind(this))
    .map((item: MaterialTypeLocationAvailability) => {
      const dictionaryItem = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.PHYSICAL_LOCATIONS, item.location);
      return dictionaryItem || item.location || '';
    });
  }

  public extractPhysicalLocationByCode(locationCode: string): string {
    const location = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.PHYSICAL_LOCATIONS, locationCode);
    return location && location.name ? location.name : null;
  }

  public extractMaterialTypeDescription(data: RollupResult | Entity): string {
    const code = this.extractMaterialTypeCode(data);
    const dictionaryItem: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.MATERIAL_TYPES, code);
    return dictionaryItem ? dictionaryItem.description : '';
  }

  public extractTitle(data: RollupResult | FormatGroup | Entity): string {
    if (this.isFormatGroup(data) && this.isFormatGroupV2(data)) {
      return data.title || '';
    }

    return data.$title && data.$title[0] || '';
  }

  public extractPrimaryAgent(data: RollupResult | SearchResult): TabEntity {
    if (this.isFieldNotEmptyArray(data, 'agent')) {
      const firstAgentData = this.getAllAuthorsAndEditors(data.agent)[0];
      const role = firstAgentData && firstAgentData.linkType.attributes.role.toLowerCase();

      return role
      && this.isFieldExist(firstAgentData.item, 'label') ?
        { id: firstAgentData.item.id, label: firstAgentData.item.label, role } : null;
    }

    return null;
  }

  public extractPublisherInformation(data: RollupResult | MaterialTypeGroupResult | SearchResult): string {
    return this.isFieldNotEmptyArray(data, 'provisionActivityStatement') ? data.provisionActivityStatement[0] : null;
  }

  public extractPublicationDate(data: RollupResult | SearchResult): string {
    return data && data.$publicationDate && data.$publicationDate[0] || null;
  }

  public extractPhysicalDescription(data: SearchResult): string {
    const extent = this.isFieldNotEmptyArray(data, 'extent') ? data.extent[0] : '';
    const dimensions = this.isFieldNotEmptyArray(data, 'dimensions') ? data.dimensions[0] : '';
    return extent || dimensions ? `${extent} ${dimensions}`.trim() : null;
  }

  public extractEdition(data: RollupResult | SearchResult) {
    return this.getFirstArrayElementFromDataField(data, 'editionStatement');
  }

  public extractSeries(data: SearchResult): string {
    return this.isFieldNotEmptyArray(data, 'hasSeries')
    && this.isFieldNotEmptyArray(data.hasSeries[0], 'seriesStatement') ?
      data.hasSeries[0].seriesStatement[0] : null;
  }

  public extractAllIdentifiers(data: SearchResult): Map<string, string[]> {
    if (!this.isFieldNotEmptyArray(data, 'identifiedBy')) {
      return null;
    }

    const { identifiedBy } = data;
    const identifiers = new Map([
      ['isbn', []],
      ['issn', []],
      ['upc', []],
      ['other', []],
    ]);
    const identifiersTypes = ['isbn', 'issn', 'upc'];

    identifiedBy.forEach((elem) => {
      // temporary hide Other identifiers
      /* if (identifiersOtherTypes.includes(elem.type)) {
         const currentValues = identifiers.get('other');
         identifiers.set('other', [...currentValues, elem.value]);
       } */
      if (identifiersTypes.includes(elem.type)) {
        const currentValues = identifiers.get(elem.type);
        identifiers.set(elem.type, [...currentValues, elem.value]);
      }
    });

    for (const [key, value] of identifiers) {
      if (value.length < 1) {
        identifiers.delete(key);
      }
    }
    return identifiers.size > 0 ? identifiers : null;
  }

  public extractIntendedAudience(data: SearchResult): string {
    return this.isFieldNotEmptyArray(data, 'intendedAudience')
    && this.isFieldExist(data.intendedAudience[0], 'label') ?
      data.intendedAudience[0].label : null;
  }

  public extractPersons(data: RollupResult): string[] {
    return this.extractAgentWithType(data, 'person');
  }

  public extractOrganizations(data: RollupResult): string[] {
    return this.extractAgentWithType(data, 'organization');
  }

  public shufflePersonsAndOrganizations(persons: string[], organizations: string[]): string[] {
    const arr = persons.concat(organizations);
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  public extractLanguage(data: SearchResult) {
    return data.$language && data.$language[0] || null;
  }

  public extractBibId(data: SearchResult): string {
    return data?.recordId?.[0];
  }

  public extractNotFoundWords(data: RollupResult): string[] {
    return this.isFieldNotEmptyArray(data, 'notFoundWords') && data.notFoundWords || [];
  }

  public extractCallNumber(data: ExtractCallNumberData): string {
    let result = this.extractCallNumberForShelfMark(data);
    if (!result) {
      result = this.extractCallNumberFromWork(data);
    }
    return result;
  }

  public extractCallNumberForShelfMark(data: ExtractCallNumberData): string {
    let result = '';
    if (this.isFieldNotEmptyArray(data, 'identifiedBy')) {
      result = this.getFieldFromFirstArrayElement(
        data.identifiedBy.filter((identifier: any) => identifier.type === 'shelfMark'), 'value');
    }
    return result;
  }

  public extractCallNumberFromWork(data: ExtractCallNumberData): string {
    let classification: ClassificationItem[] = [];
    let callNumber = '';
    if (data) {
      if (this.isFieldNotEmptyArray(data, 'work') && this.isFieldNotEmptyArray((data as RollupResult).work[0].item, 'classification')) {
        classification = (data as RollupResult).work[0].item.classification;
      } else if ((data.work && this.isFieldNotEmptyArray(data.work, 'classification'))) {
        classification = (data as MaterialTypeGroupResult).work.classification;
      }
      if (classification.length) {
        const classificationPortion = (this.getFieldFromFirstArrayElement(classification, 'classificationPortion') || []).join(' ');
        const itemPortion = this.getFieldFromFirstArrayElement(classification, 'itemPortion') || '';
        callNumber = `${classificationPortion} ${itemPortion}`.trim();
      }
    }
    return callNumber;
  }

  public extractFrequency(data: SearchResult): string {
    let frequency = null;
    if (this.isFieldNotEmptyArray(data, 'frequency')) {
      const label = data.frequency[0].label || '';
      const date = data.frequency[0].date || '';
      if (label && date) {
        frequency = `${label}, ${date}`.trim();
      } else {
        frequency = `${label} ${date}`.trim() || null;
      }
    }
    return frequency;
  }

  public extractFormerTitle(data: SearchResult) {
    return this.extractCertainTitleFieldFromInstanceOrWork(data, 'former', 'variantType');
  }

  public extractAbbreviatedTitle(data: SearchResult) {
    return this.extractCertainTitleFieldFromInstanceOrWork(data, 'abbreviatedTitle', 'type');
  }

  public extractGenre(data: SearchResult) {
    return this.getFirstArrayElementFromDataField(data, 'genreForm');
  }

  public extractSupplementaryContent(data: SearchResult) {
    return this.getFirstArrayElementFromDataField(data, 'supplementaryContent');
  }

  public extractCredits(data: SearchResult) {
    return this.extractFirstFromWorkFieldArray(data, 'creditsNote');
  }

  public extractTableOfContents(data: SearchResult) {
    return this.getFirstArrayElementFromDataField(data, 'tableOfContents');
  }

  public extractAwards(data: SearchResult): string[] {
    return this.isFieldNotEmptyArray(data, 'awards') ? data.awards : null;
  }

  public extractDissertation(data: SearchResult) {
    const dissertation = this.extractFirstFromWorkFieldArray(data, 'dissertation');
    return dissertation != null ? dissertation.label : null;
  }

  public calculateTotalResults(actual: number): string {
    if (actual <= 3000) {
      return actual + '';
    } else if (actual < 16666) {
      const roundedHundreds = Math.ceil((actual * 0.03) / 100);
      const temp = Math.floor((actual * 0.97) / 100);
      return Math.floor(temp / roundedHundreds) * roundedHundreds * 100 + '+';
    } else {
      return Math.floor((actual * 0.97) / 1000) * 1000 + '+';
    }
  }

  public extractDescriptionDataByType(type: string, data: any) {
    return data.description &&
    data.description[0] &&
    data.description[0][type] ?
      data.description[0][type] : null;
  }

  private extractMaterialTypeCode(data: RollupResult | Entity) {
    return this.getFirstArrayElementFromDataField(data, '$materialType') || this.getFirstArrayElementFromDataField(data, 'materialType');
  }

  private getFilteredLocations(
    locationAvailability: MaterialTypeLocationAvailability[]): MaterialTypeLocationAvailability[] {
    return locationAvailability
    .filter((item: MaterialTypeLocationAvailability) => item &&
      this.dictionariesService.getDictionaryItemByCode(DictionaryTypes.ITEM_STATUSES, item.status))
    .filter((item: MaterialTypeLocationAvailability) =>
      item.location && item.minDueDate);
  }

  private sortByMinDueDate(locationAvailability: MaterialTypeLocationAvailability[]): MaterialTypeLocationAvailability[] {
    return [].concat(locationAvailability)
    .sort((a: LocationAvailability, b: MaterialTypeLocationAvailability) =>
      a.minDueDate > b.minDueDate ? 1 : -1);
  }

  private isNotEmptyArray(array: any[]): boolean {
    return Array.isArray(array) && !!array.length;
  }

  private isFieldNotEmptyArray(data: any, field: string): boolean {
    return data != null && data[field] != null && this.isNotEmptyArray(data[field]);
  }

  private isFieldExist(data: any, field: string): boolean {
    return data != null && data[field] != null;
  }

  private getFirstArrayElementFromDataField(data: RollupResult | Entity | SearchResult, field: string) {
    return this.isFieldNotEmptyArray(data, field) ? (data as any)[field][0] : null;
  }

  private filterByAuthor(item: any) {
    return item.linkType
      && item.linkType.attributes
      && item.linkType.attributes.role
      && (['author', 'aut'].indexOf(item.linkType.attributes.role.toLowerCase()) > -1);
  }

  private filterBySequentialDesignation(item: any) {
    return item.linkType
      && item.linkType.attributes
      && item.linkType.attributes.sequentialDesignation;
  }

  private filterByAuthorAndEditor(item: any) {
    return item.linkType
      && item.linkType.attributes
      && item.linkType.attributes.role
      && (['author', 'aut', 'editor', 'edt'].indexOf(item.linkType.attributes.role.toLowerCase()) > -1);
  }

  private sortBySequentialDesignation(a: any, b: any) {
    const aField = a.linkType.attributes.sequentialDesignation;
    const bField = b.linkType.attributes.sequentialDesignation;
    const isNumbers = Number(aField) && Number(bField);
    if (isNumbers) {
      return Number(aField) - Number(bField);
    }
    return bField > aField ? 1 : bField === aField ? 0 : -1;
  }

  private getAuthorsSortedArray(agentArray: any) {
    return agentArray
    .filter(this.filterByAuthor)
    .sort(this.sortBySequentialDesignation);
  }

  private getAllAuthorsAndEditors(agentArray: any) {
    return agentArray
    .filter(this.filterByAuthorAndEditor)
    .filter(this.filterBySequentialDesignation)
    .sort(this.sortBySequentialDesignation);
  }

  private extractAgentWithType(data: any, type: string): string[] {
    const res: string[] = [];

    if (this.isFieldNotEmptyArray(data, 'agent')) {
      const resArray = data.agent.filter((a: any) => a.item.type === type);
      for (const el of resArray) {
        if (this.isFieldExist(el.item, 'label')) {
          res.push(el.item.label);
        }
      }
    }
    return res;
  }

  private getFieldFromFirstArrayElement(array: any, field: string) {
    return this.isNotEmptyArray(array) ? array[0][field] : null;
  }

  private extractCertainTitleFieldFromInstanceOrWork(data: SearchResult, field: string, filterField: string) {
    const instanceTitle = this.isFieldNotEmptyArray(data, 'title') ? data.title : null;
    const instanceField = instanceTitle ?
      this.getFieldFromFirstArrayElement(
        instanceTitle.filter((data) => (data as any)[filterField] === field), 'label',
      ) : null;

    const workTitle = this.isFieldNotEmptyArray(data, 'work')
    && this.isFieldNotEmptyArray(data.work[0].item, 'title') ? data.work[0].item.title : null;
    const workField = workTitle ?
      this.getFieldFromFirstArrayElement(
        workTitle.filter((data) => (data as any)[filterField] === field), 'label',
      ) : null;

    return instanceField || workField;
  }

  private extractFirstFromWorkFieldArray(data: SearchResult, field: string) {
    return this.isFieldNotEmptyArray(data, 'work')
    && this.isFieldNotEmptyArray(data.work[0].item, field) ?
      (data.work[0].item as any)[field][0] : null;
  }

  private isFormatGroup(entity: RollupResult | FormatGroup | Entity): entity is FormatGroup {
    return (entity.entityType === EntityTypes.FORMAT_GROUP);
  }

  private isFormatGroupV2(entity: (FormatGroup & { $title?: undefined })): entity is FormatGroup {
    return typeof entity.$title === 'undefined';
  }
}
