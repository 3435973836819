import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'common/shared.module';
import { QueryHelper } from 'core/utils/query-helper';
import { DragScrollModule } from 'ngx-drag-scroll';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { FacetMapperService } from 'search/facets/mappers/facet-mapper.service';
import { FacetsService } from 'search/facets/services/facets.service';
import { GatesService } from 'search/services/gates.service';
import { SearchMapperService } from 'search/services/mappers/search-mapper.service';
import { SearchParamsService } from 'search/services/search-params.service';
import { BoolQueryHelper } from 'search/utils/bool-query-helper';
import { EntityModule } from '../entity/entity.module';
import { CopyToClipboardService } from '../services/copy-to-clipboard.service';
import { ShareItModule } from '../share-it/share-it.module';
import { DeleteSavedSearchModalComponent } from './components/delete-saved-search-modal/delete-saved-search-modal.component';
import { EditSearchNameModalComponent } from './components/edit-search-name-modal/edit-search-name-modal.component';
import { SaveSearchButtonComponent } from './components/save-search-button/save-search-button.component';
import { SaveSearchModalContentComponent } from './components/save-search-modal-content/save-search-modal-content.component';
import { SavedSearchCardComponent } from './components/saved-search-card/saved-search-card.component';
import { SavedSearchesListComponent } from './components/saved-searches-list/saved-searches-list.component';
import { SavedSearchEffects } from './effects/saved-search.effects';
import * as savedSearch from './reducers/saved-search.reducer';
import { SavedSearchService } from './services/saved-search.service';
import { PermissionsModule } from 'app/permissions/permissions.module';

const COMPONENTS = [
  DeleteSavedSearchModalComponent,
  SaveSearchButtonComponent,
  SaveSearchModalContentComponent,
  SavedSearchesListComponent,
  SavedSearchCardComponent,
  EditSearchNameModalComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    ClipboardModule,
    SharedModule,
    StoreModule.forFeature(savedSearch.savedSearchFeatureKey, savedSearch.reducer),
    EffectsModule.forFeature([
      SavedSearchEffects,
    ]),
    DragScrollModule,
    ShareItModule,
    FeatureToggleModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatSelectModule,
    EntityModule,
    PermissionsModule
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    SavedSearchService,
    SearchMapperService,
    BoolQueryHelper,
    QueryHelper,
    FacetsService,
    FacetMapperService,
    SearchParamsService,
    GatesService,
    CopyToClipboardService,
  ],
})
export class SavedSearchModule {
}
