import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DropdownValue } from 'common/models/custom-dropdown';

export type SortOrderType = 'asc' | 'desc';
export type SortingType = 'id' | 'callNumber' | 'location';

export interface ISortingValue {
  key?: string;
  sorting: SortingType;
  sortOrder: SortOrderType;
}

@Component({
  selector: 'app-item-volume-list-sort',
  templateUrl: './item-volume-list-sort.component.html',
  styleUrls: ['./item-volume-list-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemVolumeListSortComponent {

  public expanded = false;
  @Input() public value: any;
  @Output() public onChange: EventEmitter<DropdownValue<ISortingValue>> = new EventEmitter<{selected: ISortingValue; label: string;}>();
  public SORTING_OPTIONS = [
    { key: 'id:desc', sorting: 'id', sortOrder: 'desc' }, // "newest first"
    { key: 'callNumber:desc', sorting: 'callNumber', sortOrder: 'desc' },
    { key: 'callNumber:asc', sorting: 'callNumber', sortOrder: 'asc' },
    { key: 'location:desc', sorting: 'location', sortOrder: 'desc' },
    { key: 'location:asc', sorting: 'location', sortOrder: 'asc' },
  ];

  public onDropdownChange(value: { selected: string; label: string;}) {
    this.value = this.SORTING_OPTIONS.find((item) => item.key === value.selected);
    this.onChange.emit({selected: this.value, label: value.label});
  }
}
