import { Component, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ChatGptService, StreamType } from '../../services/chat-gpt.service';
import { QuizQuestion } from '../../models/chatgpt-response-message.model';
import { UserAnswer } from '../../models/quiz';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent {
  public readonly EXPECTED_NUMBER_OF_QUESTIONS_IN_QUIZ: number = 10;
  private readonly NO_QUESTIONS_AVAILABLE = 'Sorry no questions for the title you have provided. Please try with another title.';

  questions: QuizQuestion[] = [];
  userAnswers: UserAnswer[] = [];
  currentQuestionIndex: number = 0;
  score: number = 0;
  showScore: boolean = false;
  awaitingNextQuestion: boolean = false;
  bookTitle: string = '';
  loading: boolean = false;
  answered: boolean = false;
  answeredCorrect: boolean = false;
  isQuizAvailable: boolean = true;
  loadingText = 'Loading quiz...';
  @Output('closeQuizDialogEvent') closeQuizDialogEvent = new EventEmitter();

  constructor(
    private readonly chatGptService: ChatGptService,
    private readonly cdr: ChangeDetectorRef) {
  }

  async loadQuiz(): Promise<void> {
    if (!this.bookTitle) {
      return;
    }
    this.loading = true;
    this.isQuizAvailable = true;
    this.loadingText = 'Loading quiz...';
    this.questions = [];
    this.currentQuestionIndex = 0;
    this.score = 0;
    try {
      this.chatGptService.cancelTheQuizStream();
      const {stream, streamId} = await this.chatGptService.generateQuizForTitle(this.bookTitle);

      await this.chatGptService.processStream(StreamType.PROCESSING_QUIZ_STREAM, streamId, stream,
        this.displayQuizQuestion.bind(this),
        this.displayMessageIfNoQuestionIsAvailable.bind(this));
    } catch (error) {
      console.error('Failed to load quiz', error);
      alert('Failed to load quiz. Please try again.');
    } finally {
      this.loading = false;
    }
  }

  public createNewQuiz() {
    this.userAnswers = [];
    this.score = 0;
    this.bookTitle = '';
    this.showScore = false;
    this.currentQuestionIndex = 0;
    this.awaitingNextQuestion = false;
    this.questions = [];
    this.cdr.detectChanges();
  }

  public displayQuizQuestion(quizQuestion: QuizQuestion) {
    this.questions.push(quizQuestion);
    this.awaitingNextQuestion = false;
    this.loading = false;
    this.cdr.detectChanges();
  };

  public displayMessageIfNoQuestionIsAvailable() {
    if (this.questions.length == 0) {
      this.chatGptService.cancelTheQuizStream();
      this.isQuizAvailable = false;
      this.cdr.detectChanges();
    }
  }


  public selectAnswer(isCorrect: boolean, currentQuestionIndex: number, questionIndex: number, selectedAnswer: string): void {
    const answer = {
      question: this.questions[currentQuestionIndex].question,
      choosenAnswer: selectedAnswer,
      correctAnswer: this.questions[currentQuestionIndex].options[this.questions[currentQuestionIndex].answer],
      correctAnswerDescription: this.questions[currentQuestionIndex].answerDescription,
    };
    console.info('THE selected Answer ' + selectedAnswer);
    console.info('The current question index ' + currentQuestionIndex);
    console.info('the answer object' + JSON.stringify(answer));
    console.info(JSON.stringify(this.questions));
    const isCorrectTwo = selectedAnswer === this.questions[currentQuestionIndex].options[this.questions[currentQuestionIndex].answer];

    this.userAnswers.push(answer);

    if (isCorrectTwo) {
      console.info('THE QUIZ answer is correct');
      this.score++;
      this.answeredCorrect = true;
    }

    this.answered = true;
  }


  public nextQuestion(): void {
    this.answeredCorrect = false;
    if (this.currentQuestionIndex < this.EXPECTED_NUMBER_OF_QUESTIONS_IN_QUIZ - 1) {
      this.currentQuestionIndex++;
      this.checkIfQuestionIsAvailableAtIndex(this.currentQuestionIndex);
      this.answered = false;
    } else {
      this.showScore = true;
      this.answered = false;
    }
  }

  private checkIfQuestionIsAvailableAtIndex(index: number) {
    if (index != this.EXPECTED_NUMBER_OF_QUESTIONS_IN_QUIZ && (this.questions[index] == null || this.questions[index] == undefined)) {
      this.loading = true;
      this.loadingText = 'Please wait until next question is loaded..';
    }
  }

  closeQuiz() {
    this.questions = [];
    this.chatGptService.cancelTheQuizStream();
    this.closeQuizDialogEvent.emit();
  }


  restartQuiz(): void {
    this.score = 0;
    this.currentQuestionIndex = 0;
    this.showScore = false;
  }
}
