import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'core/utils/error-decorator';
import { Observable } from 'rxjs';
import { FacetMapperService } from 'search/facets/mappers/facet-mapper.service';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { FacetSearchObject, MappedSearchObject } from '../../models/search-object';
import {
  AllResponseFacets,
  defaultAllResourceFacets,
  defaultFacetResult,
  FacetResult,
  ResponseFacet
} from '../models/resource-facet';

@Injectable()
export class FacetsService {

  private static readonly facetsUrl = 'api/search-result/facet/FormatGroup';
  private static readonly DEFAULT_OPTIONS: Partial<MappedSearchObject> = {
    pageNum: 0,
    pageSize: 24,
    sorting: 'count',
    sortOrder: 'desc'
  }

  constructor(private http: HttpClient,
              private facetMapperService: FacetMapperService,
              private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public getFacets(query: MappedSearchObject): Observable<AllResponseFacets> {
    const response = this.getFacetsOrDefault(query);
    return this.facetMapperService.mapGetAllFacets(response, query);
  }

  @error(defaultFacetResult)
  public getSingleFacet(query: FacetSearchObject,
                        options: Partial<MappedSearchObject> = FacetsService.DEFAULT_OPTIONS): Observable<FacetResult<ResponseFacet>> {
    const facetType = query.facetMapField.replace(/\.?([A-Z])/g, (x, y) => '_' + y).replace(/^_/, '');
    const url = `${ FacetsService.facetsUrl }/${ facetType }`;

    const body = FacetsService.mapQueryToBody(query);
    body.facetQuery = query.facetQuery;
    body.pageNum = options.pageNum;
    body.pageSize = options.pageSize;
    body.sorting = options.sorting;
    body.sortOrder = options.sortOrder;

    const response = this.http.post<FacetResult<ResponseFacet>>(url, body);
    return this.facetMapperService.mapGetSingleFacet(response, query.facetKey);
  }

  public getModelForFacet(facetKey: string, response:any) {
    return this.facetMapperService.getModelForFacet(facetKey, response);
  }

  private static mapQueryToBody(query: MappedSearchObject): any {
    const facets = query.filters ? { ...query.filters.facets } : {};
    const { searchText, searchType, metadataBoolQuery, resourceIds, facetTypes } = query;
    return {
      ...facets,
      facetTypes,
      searchText,
      searchType,
      metadataBoolQuery,
      resourceIds,
    };
  }

  @error(defaultAllResourceFacets)
  private getFacetsOrDefault(query: MappedSearchObject) {
    const body = FacetsService.mapQueryToBody(query);
    if (this.featureToggleService.getToggles()['DIS-31746_2024-07-31_location_facet_sorting']) {
      body.facetTypes = body.facetTypes?.filter((facetType: string) => facetType != 'location') || [];
    }
    if (this.featureToggleService.getToggles()['VE-4775_2024-07-31_format_facet_search']) {
      body.facetTypes = body.facetTypes?.filter((facetType: string) => facetType != 'materialType') || [];
    }
    return this.http.post<AllResponseFacets>(FacetsService.facetsUrl, body);
  }
}
