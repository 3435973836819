<app-custom-dropdown
  class="item-volume-dropdown pb-2"
  data-automation-id="item-volume-list-sort"
  [aria-label]="'sortedBy' | translate"
  [selectedOption]="value?.sorting+':'+value?.sortOrder" (change)="onDropdownChange($event)">
  <span custom-dropdown-label translate>sortedBy</span>
  <app-custom-dropdown-option
    [value]="SORTING_OPTIONS[0].key"
    translate
    [id]="SORTING_OPTIONS[0].key"
    data-automation-id="item-volume-list-sort-newest-first">
    newestFirst
  </app-custom-dropdown-option>
  <app-custom-dropdown-option
    [value]="SORTING_OPTIONS[1].key"
    [id]="SORTING_OPTIONS[1].key"
    translate>sortedByCallNumberDesc</app-custom-dropdown-option>
  <app-custom-dropdown-option
    [value]="SORTING_OPTIONS[2].key"
    [id]="SORTING_OPTIONS[2].key"
    translate>sortedByCallNumberAsc</app-custom-dropdown-option>
  <app-custom-dropdown-option
    [value]="SORTING_OPTIONS[3].key"
    [id]="SORTING_OPTIONS[3].key"
    translate>sortedByLocationDesc</app-custom-dropdown-option>
  <app-custom-dropdown-option
    [value]="SORTING_OPTIONS[4].key"
    [id]="SORTING_OPTIONS[4].key"
    translate>sortedByLocationAsc</app-custom-dropdown-option>
</app-custom-dropdown>
