<div class="table-container overflow-auto">
  <table data-automation-id="loc-edtn-table" class="location-edition-table table">

    <thead class="location-edition-table__header">
      <tr data-automation-id="editions-table-header">
        <th data-automation-id="editions-table-header-cell" *ngIf="isExpandable && !isElectronic"></th>
        <th translate data-automation-id="editions-table-header-cell">publicationDateTitle</th>
        <th translate data-automation-id="editions-table-header-cell">editionTitle</th>
        <th translate data-automation-id="editions-table-header-cell">detailsTitle</th>
        <th translate data-automation-id="editions-table-header-cell" *ngIf="!isElectronic">publisherTitle</th>
        <th translate data-automation-id="editions-table-header-cell" *ngIf="isElectronic">vendorTitle</th>
        <th translate data-automation-id="editions-table-header-cell" *ngIf="!isElectronic">physicalDescriptionTitle</th>
        <th translate data-automation-id="editions-table-header-cell" *ngIf="isElectronic">noteTitle</th>
        <th translate data-automation-id="editions-table-header-cell" *ngIf="!isElectronic">availabilityTitle</th>
        <th data-automation-id="editions-table-header-cell" class="column-actions"></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let edition of tableData; let idx = index">
        <tr
          class="edition-information-row expand-cell"
          data-automation-id="table-row"
          [ngClass]="{'edition-information-row-expanded': edition.availability?.expanded}">
          <td *ngIf="isExpandable && !isElectronic">
            <button
              *ngIf="edition.hasItems"
              class="panel-expansion-button"
              data-automation-id="expand-loc-info"
              [attr.aria-label]="'epanCollapseEditionPanel' | translate"
              [attr.aria-expanded]="edition.availability?.expanded"
              (click)="toggleEdition(edition)">
              <fa-icon class="arrow-icon" [icon]="edition.availability?.expanded ? arrowIconUp : arrowIconDown"></fa-icon>
            </button>
          </td>
          <td data-automation-id="publication-date-cell">{{edition.publicationDate || '—'}}</td>
          <td data-automation-id="edition-cell">{{edition.edition || '—'}}</td>
          <td data-automation-id="details-cell">
            <button
              class="ins-buttons button-primary details"
              data-automation-id="details-popup-button"
              [tippy]="'showDetailsPopup' | translate"
              [attr.aria-label]="'showDetailsPopup' | translate"
              (click)="showDetails(edition)">
              <fa-icon class="info-icon" [icon]="infoCircle"></fa-icon>
            </button>
          </td>
          <td data-automation-id="publisher-cell" *ngIf="!isElectronic">{{edition.publisher || '—'}}</td>
          <td data-automation-id="vendor-cell" *ngIf="isElectronic">
            <ng-container *ngIf="(!!availabilityUpdates ? availabilityUpdates[idx]?.reserve?.vendorName : isAvailableVendor(edition)) as vendor">
              {{ formattedResource[vendor] || '—' }}
            </ng-container>
          </td>
          <td data-automation-id="description-cell" *ngIf="!isElectronic">
            <ng-container *ngIf="!edition.physicalDescription; else description">
              <span> — </span>
            </ng-container>
            <ng-template #description>
              {{edition?.physicalDescription}}
            </ng-template>
          </td>
          <td data-automation-id="note-cell" *ngIf="isElectronic">
            <span
              *ngIf="edition.publicNote; else emptyNote"
              data-automation-id="public-note-link"
              class="resource-url"
              (click)="onOpenPublicNoteLink(edition)">
              {{edition.publicNote}}
            </span>
            <ng-template #emptyNote>—</ng-template>
          </td>
          <td data-automation-id="availability-cell" *ngIf="!isElectronic">
            <span>{{statusKeys[edition?.availabilityStatus] | translate}}</span>
          </td>
          <td>
            <app-toolbar-buttons
              *ngIf="!edition.isGetItSuppressed"
              [label]="label"
              [tabName]="tabName"
              [isElectronicResource]="edition.type == editionType.ELECTRONIC"
              [resourceId]="fgId"
              [availability]="availabilityUpdates && availabilityUpdates[idx]?.status"
              [reserve]="availabilityUpdates && availabilityUpdates[idx]?.reserve"
              [eVendorAvailabilityPending]="availabilityUpdates && availabilityUpdates[idx]?.eVendorAvailabilityPending"
              [instanceId]="edition.id"
              [hasEcontentIssues]="hasEditionEcontentIssues"
              [recordId]="[edition.recordId]"
              [eResourceUrls]="edition.eResourceUrls"
              (getIt$)="getIt$.emit($event)"
              (placeHold$)="placeHold$.emit($event)"
              [type]="'secondary'"
              [getOneEdition]="true">
            </app-toolbar-buttons>
          </td>
        </tr>
        <tr class="location-information-row location-information">
          <td
            class="panel-content"
            [ngClass]="{'panel-content-expanded d-table-cell overflow-auto' : edition.availability?.expanded}"
            [attr.colspan]="displayedColumns.length">
            <div class="d-flex justify-content-around">
              <div *ngIf="edition.availability?.loading"
                   class="spinner-border text-primary location-info-spinner"
                   role="status"
                   aria-live="polite">
                  <span class="sr-only" translate>
                      Loading...
                  </span>
              </div>
            </div>
            <app-location-table [itemTitle]="itemTitle" [tableItems]="edition.availability?.items ?? []" *ngIf="!edition.availability?.loading">
            </app-location-table>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
