<app-bookshelf-card [name]="list.name"
                    [items]="list.items | slice:0:5 | pluck:'entity'"
                    [coverConfigs]="list.items | slice:0:5 | pluck:'entity.coverConfig'"
                    [ariaTitleId]="ariaTitleId"
                    [itemsCount]="list.itemsCount"
                    [focus$]="focus$"
                    [focusActionsMenuToggleButton$]="focusActionsMenuToggleButton$"
                    (cardClick)="onListOpened.emit()"
                    (triggerAction)="triggerCardAction($event)"
                    [focusShareMenuToggleButton$]="focusShareMenuToggleButton$"
                    (triggerShareAction)="triggerCardShareAction($event)">
  <ng-container *ngIf="list.type === listType.regular">
    <ng-container *cardAction="cardActionTypeEnum.edit">
      <app-bookshelf-card-action-item [icon]="editIcon" messageKey="listEditNameAction">
      </app-bookshelf-card-action-item>
    </ng-container>

    <ng-container *featureToggle="'licenseCustomShowcases'">
                            <!-- VIEW SHOWCASE BTN -->
      <ng-container>
        <ng-container *ngIf="!isAuthPatronFlagEnabled">
          <ng-container *ngIf="list.showcaseRef && (hasViewShowcasePermission$ | async); else createActionTemplate">
            <ng-container *cardAction="cardActionTypeEnum.viewShowcase">
              <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="listViewShowcaseAction">
              </app-bookshelf-card-action-item>
            </ng-container>

            <app-bookshelf-card-status *cardStatus
                                       [cardType]="bookshelfCardType.showcase"
                                       [published]="relatedShowcase?.published"
                                       [loading]="false"
                                       (onClick)="viewShowcase()">
            </app-bookshelf-card-status>
          </ng-container>
        </ng-container>

         <ng-container *ngIf="isAuthPatronFlagEnabled">
           <ng-container *ngIf="list.showcaseRef; else createActionTemplate">
            <ng-container *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_VIEW
              }"
            >
              <ng-container *cardAction="cardActionTypeEnum.viewShowcase">
                <app-bookshelf-card-action-item [icon]="showcaseIcon" messageKey="listViewShowcaseAction">
                </app-bookshelf-card-action-item>
              </ng-container>

              <app-bookshelf-card-status *cardStatus
                                        [cardType]="bookshelfCardType.showcase"
                                        [published]="relatedShowcase?.published"
                                        [loading]="false"
                                        (onClick)="viewShowcase()">
              </app-bookshelf-card-status>
            </ng-container>
           </ng-container>
         </ng-container>
      </ng-container>

                                <!-- CREATE BTN -->

      <ng-template #createActionTemplate>
        <ng-container *ngIf="isAuthPatronFlagEnabled">
          <ng-container
            *authorizationDirective="{
              permission: sitePermissions.SHOWCASE_CREATE
            }"
          >
            <ng-container *cardAction="cardActionTypeEnum.createShowcase">
              <app-bookshelf-card-action-item [icon]="showcaseIcon"
                                              messageKey="listCreateShowcaseAction"></app-bookshelf-card-action-item>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isAuthPatronFlagEnabled">
          <ng-container *ngIf="hasCreateShowcasePermission$ | async">
            <ng-container *cardAction="cardActionTypeEnum.createShowcase">
              <app-bookshelf-card-action-item [icon]="showcaseIcon"
                                              messageKey="listCreateShowcaseAction"></app-bookshelf-card-action-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *cardAction="cardActionTypeEnum.delete">
      <app-bookshelf-card-action-item [icon]="trashIcon" messageKey="listDeleteAction">
      </app-bookshelf-card-action-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="list.type === listType.regular">
    <span *cardShareAction="cardShareActionTypeEnum.email">
      <app-bookshelf-card-action-item [icon]="emailIcon" messageKey="shareListEmailAction">
      </app-bookshelf-card-action-item>
    </span>
    <span *cardShareAction="cardShareActionTypeEnum.copyLink">
      <app-bookshelf-card-action-item [icon]="copyIcon" messageKey="listCopyLinkAction">
      </app-bookshelf-card-action-item>
    </span>
  </ng-container>
  <app-bookshelf-card-action-state
    [state]="
      displayState === displayStateEnum.SHARE_COPIED ?
      {type: cardActionStateTypeEnum.success, messageKey: 'savedSearchCopied'} :
      {type: cardActionStateTypeEnum.none}"
    [clickOutsideMessageEnabled]="displayState === displayStateEnum.SHARE_COPIED"
    (clickOutsideMessage)="clearStatus()"
  ></app-bookshelf-card-action-state>
</app-bookshelf-card>
