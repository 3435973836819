import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CustomPassThroughService } from '../services/custom-passthrough.service';

import {
  redirectCustomPassThroughSearch,
  redirectCustomPassThroughSearchSuccess,
  redirectCustomPassThroughWork,
  redirectCustomPassThroughWorkSuccess
} from '../actions/custom-passthrough.actions';

@Injectable()
export class CustomPassThroughEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly contentScopeService: CustomPassThroughService
  ) {
  }

  public redirectCustomPassThroughSearch$ = createEffect(() => this.actions$.pipe(
      ofType(redirectCustomPassThroughSearch),
      switchMap(({keyword, url}) => {
        const redirectURL = this.contentScopeService.getRedirectSearch(keyword as string, url);
        if (redirectURL) {
          window.open(redirectURL, '_blank');
        }
        return of(redirectCustomPassThroughSearchSuccess());
      }),
      catchError(() => {
        return EMPTY;
      })
    )
  );

  public redirectCustomPassThroughWork$ = createEffect(() => this.actions$.pipe(
      ofType(redirectCustomPassThroughWork),
      switchMap(({data, url}) => {
        const redirectURL = this.contentScopeService.getRedirectWorks(data, url);
        if (redirectURL) {
          window.open(redirectURL, '_blank');
        }
        return of(redirectCustomPassThroughWorkSuccess());
      }),
      catchError(() => {
        return EMPTY;
      })
    )
  );
}
