import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import {
  neededActionProcessing,
  neededActionProcessingFailure,
  neededActionProcessingSuccess,
  openBulkHoldModal,
  removeHoldItem,
  resetBulkHoldSubmitState,
  stopBulkHoldsProcessing,
  submitBulkHolds,
  submitIndividualHoldRequest,
  submitIndividualHoldRequestFailure,
  submitIndividualHoldRequestProcessing,
  submitIndividualHoldRequestStopped,
  submitIndividualHoldRequestSuccess
} from 'user/actions/bulk-hold.actions';
import { GetItPlaceTabHold, ItemVolume, Volume } from 'user/models/get-it';
import { BulkFeatureKey } from '../../bulk-select/enums/bulk-feature-key';
import { FormatGroup } from '../../entity/models/entity';

export interface PlaceHoldError {
  status: number;
  message: string;
  needsAction: boolean;
  volumes?: Volume[];
  itemsAsVolumes?: ItemVolume[];
}

export interface BulkHoldSubmitState {
  submitted: boolean;
  processedCount: number;
  succeededCount: number;
  totalCount: number;
  stoppedCount: number;
  processingFgId?: string;
  needsActionFgIds: string[];
  holdRequests: GetItPlaceTabHold[];
  allProcessed: boolean;
  stopping: boolean;
  allStopped: boolean;
}

export const BulkHoldSubmitStateRecord = Immutable.Record<BulkHoldSubmitState>({
  submitted: false,
  processedCount: 0,
  succeededCount: 0,
  totalCount: 0,
  stoppedCount: 0,
  processingFgId: null,
  needsActionFgIds: [],
  holdRequests: null,
  allProcessed: false,
  stopping: false,
  allStopped: false,
});

export interface IndividualHoldSubmitState {
  holdRequest: GetItPlaceTabHold;
  waiting: boolean;
  loading: boolean;
  error: PlaceHoldError;
  success: boolean;
  recordId: string;
  stopped: boolean;
}

export const IndividualHoldSubmitStateRecord = Immutable.Record<IndividualHoldSubmitState>({
  holdRequest: null,
  waiting: false,
  loading: false,
  error: null,
  success: false,
  recordId: null,
  stopped: false,
});

export interface BulkHoldState {
  bulkFeatureKey: BulkFeatureKey;
  availableFormatGroups: Immutable.List<FormatGroup>;
  unavailableFormatGroups: Immutable.List<FormatGroup>;
  bulkHoldSubmitState: Immutable.Record<BulkHoldSubmitState>;
  individualHoldSubmitState: { [key: string]: Immutable.Record<IndividualHoldSubmitState> };
}

export const initialState: BulkHoldState = {
  bulkFeatureKey: null,
  availableFormatGroups: Immutable.List(),
  unavailableFormatGroups: Immutable.List(),
  bulkHoldSubmitState: BulkHoldSubmitStateRecord(),
  individualHoldSubmitState: {},
};

const _reducer = createReducer(
  initialState,
  on(openBulkHoldModal, (state, {availableFormatGroups, unavailableFormatGroups}) => {
    return {
      ...state,
      availableFormatGroups: Immutable.List(availableFormatGroups),
      unavailableFormatGroups: Immutable.List(unavailableFormatGroups),
    };
  }),
  on(removeHoldItem, (state, {item}) => {
      const updatedFormatGroups = state.availableFormatGroups.filter((el) => el.id !== item.id);
      return {
        ...state,
        availableFormatGroups: updatedFormatGroups,
      };
    }
  ),
  on(submitBulkHolds, (state, {holdRequests}) => {
    return {
      ...state,
      bulkHoldSubmitState: BulkHoldSubmitStateRecord({submitted: true, totalCount: holdRequests.length, holdRequests}),
    };
  }),
  on(submitIndividualHoldRequest, (state, {holdRequest}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [holdRequest.formatGroupId]: IndividualHoldSubmitStateRecord({waiting: true, holdRequest}),
      }
    };
  }),
  on(submitIndividualHoldRequestProcessing, (state, {holdRequest}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [holdRequest.formatGroupId]: IndividualHoldSubmitStateRecord({loading: true, holdRequest}),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.set('processingFgId', holdRequest.formatGroupId),
    };
  }),
  on(submitIndividualHoldRequestSuccess, (state, {holdRequest, holdIndex, recordId}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [holdRequest.formatGroupId]: IndividualHoldSubmitStateRecord({holdRequest, recordId, success: true}),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        const newSucceededCount = bulkSubmitState.get('succeededCount') + 1;
        const newProcessedCount = bulkSubmitState.get('processedCount') + 1;
        const allProcessed = newProcessedCount === bulkSubmitState.get('totalCount');
        bulkSubmitState
        .set('succeededCount', newSucceededCount)
        .set('processedCount', newProcessedCount)
        .set('allProcessed', allProcessed);
      }),
    };
  }),
  on(submitIndividualHoldRequestFailure, (state, {holdRequest, holdIndex, error}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [holdRequest.formatGroupId]: IndividualHoldSubmitStateRecord({holdRequest, error}),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        const newProcessedCount = bulkSubmitState.get('processedCount') + 1;
        const allProcessed = newProcessedCount === bulkSubmitState.get('totalCount');
        const needsActionFgIds = error.needsAction
          ? [...bulkSubmitState.get('needsActionFgIds'), holdRequest.formatGroupId]
          : bulkSubmitState.get('needsActionFgIds');
        bulkSubmitState
        .set('processedCount', newProcessedCount)
        .set('needsActionFgIds', needsActionFgIds)
        .set('allProcessed', allProcessed);
      }),
    };
  }),
  on(neededActionProcessing, (state, {fgId}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [fgId]: state.individualHoldSubmitState[fgId].set('loading', true).set('error', null),
      },
    };
  }),
  on(neededActionProcessingSuccess, (state, {fgId}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [fgId]: state.individualHoldSubmitState[fgId].set('loading', false).set('success', true),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        bulkSubmitState.set('needsActionFgIds', bulkSubmitState.get('needsActionFgIds').filter((id) => id !== fgId));
      }),
    };
  }),
  on(neededActionProcessingFailure, (state, {fgId, message}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [fgId]: state.individualHoldSubmitState[fgId].set('loading', false).set('error', {status: null, message, needsAction: false}),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        bulkSubmitState.set('needsActionFgIds', bulkSubmitState.get('needsActionFgIds').filter((id) => id !== fgId));
      }),
    };
  }),
  on(stopBulkHoldsProcessing, (state) => {
    return {
      ...state,
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        bulkSubmitState
        .set('stopping', true);
      }),
    };
  }),
  on(submitIndividualHoldRequestStopped, (state, {holdRequest}) => {
    return {
      ...state,
      individualHoldSubmitState: {
        ...state.individualHoldSubmitState,
        [holdRequest.formatGroupId]: IndividualHoldSubmitStateRecord({holdRequest, stopped: true}),
      },
      bulkHoldSubmitState: state.bulkHoldSubmitState.withMutations((bulkSubmitState) => {
        const newStoppedCount = bulkSubmitState.get('stoppedCount') + 1;
        const processedCount = bulkSubmitState.get('processedCount');
        const allStopped = (newStoppedCount + processedCount) === bulkSubmitState.get('totalCount');
        bulkSubmitState
        .set('stoppedCount', newStoppedCount)
        .set('allStopped', allStopped)
        .set('stopping', !allStopped);
      }),
    };
  }),
  on(resetBulkHoldSubmitState, (state) => {
    return {
      ...state,
      bulkHoldSubmitState: BulkHoldSubmitStateRecord(),
      individualHoldSubmitState: {},
    };
  }),
);

export function reducer(state: BulkHoldState | undefined, action: Action) {
  return _reducer(state, action);
}

export const featureKey = 'bulkHold';

export const getBulkHoldState = createFeatureSelector<BulkHoldState>(featureKey);

export const getAvailableFormatGroups = createSelector(getBulkHoldState, (state: BulkHoldState): FormatGroup[] => {
  return state.availableFormatGroups.toArray();
});

export const getUnavailableFormatGroups = createSelector(getBulkHoldState, (state: BulkHoldState): FormatGroup[] => {
  return state.unavailableFormatGroups.toArray();
});

export const getBulkHoldSubmitState = createSelector(
  getBulkHoldState, (state: BulkHoldState): BulkHoldSubmitState => {
    return state.bulkHoldSubmitState.toJS() as BulkHoldSubmitState;
  },
);

export const getIndividualHoldSubmitState = createSelector(
  getBulkHoldState,
  (state: BulkHoldState, props: { id: string }): IndividualHoldSubmitState => {
    return (state?.individualHoldSubmitState[props.id]) ? (state.individualHoldSubmitState[props.id].toJS() as IndividualHoldSubmitState) : null;
  },
);
