import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ConfirmationModalContentComponent } from 'common/components/confirmation-modal-content/confirmation-modal-content.component';
import { from, of, Subscription } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { checkoutCardActionRequest, collapseBookshelf } from 'user/actions/user-profile.actions';
import {
  Axis360Checkout,
  BorrowboxCheckout,
  CheckoutCard,
  CheckoutCardActionPayload,
  CheckoutCardActionType,
  CloudLibraryCheckout,
  EContentAttributes,
  HooplaCheckout
} from 'user/models/checkouts';
import { ResourceInfo } from 'user/models/resource-item-data';
import { UserReceiptActionStatus } from 'user/models/user-receipt-card';
import { ECONTENT_VENDORS, Vendor } from 'user/models/vendor';
import { getUserId } from 'user/reducers/user.reducer';
import { FormattedVendor } from '../../../../../entity/models/econtent';

export enum DaysLeftCode {
  CHECKED_OUT = 0,
  BRING_BACK_SOON = 1,
  OVERDUE = 2,
}

@Component({
  selector: 'app-checkout-card',
  templateUrl: './checkout-card.component.html',
  styleUrls: ['./checkout-card.component.scss'],
})
export class CheckoutCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public card: CheckoutCard;

  public daysLeftRounded: number;
  public daysLeftCode: DaysLeftCode;
  public ariaTitleId: string;
  public url: string | null = null;
  public frcLink: boolean;
  public hasResourceTitle: boolean;
  public readonly daysLeftCodeEnum = DaysLeftCode;
  public readonly vendorEnum = Vendor;
  public readonly exclamationCircleIcon = faExclamationCircle;
  public readonly actionStatus = UserReceiptActionStatus;
  public readonly formattedVendor = FormattedVendor;

  private userId: string;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly modal: NgbModal,
  ) {
  }

  public ngOnInit(): void {
    this.ariaTitleId = `ariaLabel-${this.card.receipt.id}`;
    this.subscriptions.add(
      this.store.select(getUserId).subscribe((userId) => {
        this.userId = userId;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onNavigation(): void {
    this.store.dispatch(collapseBookshelf());
  }

  public ngOnChanges(): void {
    const now = new Date();
    const endOfTheDay = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    const dueDateTime = new Date(this.card.receipt.dueDate);
    const dueDate = new Date(dueDateTime.getUTCFullYear(), dueDateTime.getUTCMonth(), dueDateTime.getUTCDate());

    const daysLeft = (dueDate.getTime() - endOfTheDay.getTime()) / (24 * 3600 * 1000);
    this.daysLeftRounded = Math.round(Math.abs(daysLeft));

    if (daysLeft < 0) {
      this.daysLeftCode = DaysLeftCode.OVERDUE;
    } else if (daysLeft < 6) {
      this.daysLeftCode = DaysLeftCode.BRING_BACK_SOON;
    } else {
      this.daysLeftCode = DaysLeftCode.CHECKED_OUT;
    }

    this.url = this.getUrl();
    this.frcLink = this.card.receipt.resource?.id?.length > 0 && this.card.receipt.resource?.type?.length > 0;
    this.hasResourceTitle = this.card.receipt?.resource?.title?.length > 0;
  }

  public handleActionTrigger(actionPayload: CheckoutCardActionPayload): void {
    const obs = (actionPayload.actionType === CheckoutCardActionType.CheckIn)
      ? from(this.openConfirmationDialog({action: actionPayload.actionType}).result)
      .pipe(catchError((_) => of()))
      : of(true);

    this.subscriptions.add(
      obs.pipe(filter(Boolean)).subscribe(() => {
        this.store.dispatch(checkoutCardActionRequest({
          actionType: actionPayload.actionType,
          payload: actionPayload.payload,
          checkoutId: this.card.receipt.id,
          resourceId: this.card.receipt.resource?.id,
        }));
      }),
    );
  }

  private openConfirmationDialog(context: { action: CheckoutCardActionType }): NgbModalRef {
    const ngbModalRef = this.modal.open(ConfirmationModalContentComponent, {
      centered: true,
      windowClass: 'inspire-custom-modal',
    });
    ngbModalRef.componentInstance.context = context;
    return ngbModalRef;
  }

  private getUrl(): string | null {
    const checkout = this.card.receipt;
    const vendor: Vendor = checkout.vendor;
    switch (vendor) {
      case Vendor.HOOPLA:
        return (checkout as HooplaCheckout).hoopla.url;
      case Vendor.CLOUD_LIBRARY:
        return this.getEContentUrl((checkout as CloudLibraryCheckout).cloudlibrary, checkout.resource);
      case Vendor.BORROW_BOX:
        return this.getEContentUrl((checkout as BorrowboxCheckout).borrowbox, checkout.resource);
      case Vendor.AXIS360:
        return this.getEContentUrl((checkout as Axis360Checkout).axis360, checkout.resource);
      default:
        return null;
    }
  }

  private getEContentUrl(attributes: EContentAttributes, resource: ResourceInfo): string | null {
    return attributes.titleUrl || resource?.electronicLocatorUrl || null;
  }

  isEcontentVendor(): boolean {
    return ECONTENT_VENDORS.includes(this.card.receipt.vendor);
  }
}
