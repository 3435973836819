<perfect-scrollbar removeTabindexOnYScroll [ngStyle]="{'max-height': maxHeightPerfectScrollbar}">
  <div cdkTrapFocus cdkTrapFocusAutoCapture class="custom-showcase mb-5" *ngIf="showcase">
    <div class="d-flex justify-content-between align-items-baseline">
      <div class="custom-showcase-title" data-automation-id="custom-showcase-title" role="heading" aria-level="1">
        <fa-icon [icon]="showcaseIcon" [classes]="['mr-1']"></fa-icon>
        {{ (isEditMode ? 'editShowcase' : 'createShowcase') | translate }}
      </div>
      <div class="custom-showcase-title-buttons">
        <div *ngIf="isEditMode" class="custom-showcase-buttons d-none d-sm-inline-flex">
          <ng-container [ngTemplateOutlet]="formButtons"></ng-container>
        </div>
        <button class="custom-showcase-close-button px-3 ml-2 ml-md-8"
                data-automation-id="custom-showcase-cancel-button"
                [attr.aria-label]="(isEditMode ? 'cancelShowcaseEdition' : 'cancelShowcaseCreation') | translate"
                (click)="cancel()">
          <span class="fas fa-times" aria-hidden="true"></span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="isEditMode">
      <div class="custom-showcase-buttons d-inline-flex d-sm-none">
        <ng-container [ngTemplateOutlet]="formButtons"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="loadingOrError"></ng-container>
    </ng-container>

    <div class="custom-showcase-form-container px-8 pt-5 my-5">
      <form class="custom-showcase-form mb-0"
            id="custom-showcase-form"
            data-automation-id="custom-showcase-form"
            [formGroup]="customShowcaseForm"
            (ngSubmit)="save(isEditMode ? undefined : false)">

        <div class="form-group mb-10">
          <label for="custom-showcase-create-from"
                 class="custom-showcase-form-label mb-0"
                 data-automation-id="custom-showcase-create-from-label">
            {{ (isEditMode ? 'createdFrom' : 'createFrom') | translate }}
          </label>
          <div class="custom-showcase-create-from w-100 d-inline-flex align-items-center"
               data-automation-id="custom-showcase-create-from-value" id="custom-showcase-create-from">
            <a [ngSwitch]="showcase.createdFrom.type" href=""
               [class.no-pointer-events]="!isEditMode"
               (click)="$event.preventDefault(); onShowcaseTypeClick()"
               data-automation-id="custom-showcase-created-from-link">
              <ng-container *ngSwitchCase="createdFromType.list">
                <span class="created-from-icon fas fa-bookmark mr-1" aria-hidden="true"></span>
                <span class="type mr-2">{{ 'list' | translate }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="createdFromType.savedSearch">
                <fa-icon [icon]="searchIcon" [classes]="['created-from-icon mr-1']"></fa-icon>
                <span class="type mr-2">{{ 'savedSearch' | translate }}</span>
              </ng-container>
            </a>
            <span class="name text-nowrap overflow-hidden" role="heading"
                  aria-level="2">{{ showcase.createdFrom.name }}</span>
          </div>
        </div>

        <div class="form-group mb-15">
          <label for="custom-showcase-form-name"
                 class="custom-showcase-form-label"
                 data-automation-id="custom-showcase-form-name-label"
                 translate>
            customShowcaseName
          </label>
          <input type="text"
                 #nameField
                 formControlName="name"
                 class="form-control custom-showcase-form-name"
                 id="custom-showcase-form-name"
                 aria-required="true"
                 [class.is-invalid]="name.invalid && name.touched"
                 [attr.aria-invalid]="name.invalid && name.touched"
                 data-automation-id="custom-showcase-form-name-input"/>
          <div aria-atomic="true" aria-live="assertive" role="alert">
            <div *ngIf="customShowcaseForm.invalid && customShowcaseForm.touched"
                 class="invalid-feedback d-block"
                 data-automation-id="custom-showcase-form-name-error">
              <ng-container *ngIf="name.errors">
                <ng-container *ngIf="name.errors.required">
                  {{ 'customShowcaseNameRequiredError' | translate }}
                </ng-container>
                <ng-container *ngIf="name.errors.maxlength">
                  {{ 'customShowcaseNameMaxLengthError' | translate: {maxCharactersNumber: name.errors.maxlength.requiredLength} }}
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="isShowCaseScheduleFlagEnabled" class="mb-3">
          <h4
            class="custom-showcase-publish-title mt-0 mb-3"
            data-automation-id="custom-showcase-title-publish-manual" translate>
            customShowcaseMetadataPublishManually
          </h4>
          <div class="row mb-4 no-gutters align-items-center">
            <!-- Start date and time -->
            <div class="col-6 col-md-2">

              <div class="input-group">
                <label for="custom-showcase-form-start-date-and-time"
                       class="custom-showcase-label"
                       data-automation-id="custom-showcase-form-start-date-and-time-label"
                       translate>customShowcaseMetadataStartDateAndTime</label>

                <span class="input-group-text input-group-text-datepicker">
                  <div class="datepicker-container align-items-center d-flex">
                    <input id="custom-showcase-form-start-date-and-time"
                           class="custom-start-datepicker-input mb-0"
                           matInput
                           [matDatepicker]="startDate"
                           data-automation-id="custom-showcase-form-start-date-and-time"
                           placeholder="{{ 'customShowcaseMetadataEnterTime' | translate }}"
                           formControlName="startDate">
                    <mat-datepicker-toggle [for]="startDate" class="datepicker-toggle"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </div>

                </span>
              </div>
            </div>

            <div class="mr-3 d-flex justify-content-center mt-7">
              <div class="align-items-center">
                <span translate data-automation-id="custom-showcase-date-till" class="custom-showcase-label">
                  customShowcaseMetadataTill
                </span>
              </div>
            </div>

            <!-- End date -->
            <div class="col-6 col-md-2">
              <div class="input-group">
                <label for="custom-showcase-form-end-date-and-time"
                       class="custom-showcase-label"
                       data-automation-id="custom-showcase-form-end-date-and-time-label"
                       translate>customShowcaseMetadataEndDateAndTime</label>

                <span class="input-group-text input-group-text-datepicker">
                  <div class="datepicker-container align-items-center d-flex">
                    <input id="custom-showcase-form-end-date-and-time"
                           class="custom-end-datepicker-input mb-0"
                           matInput
                           [matDatepicker]="endDate"
                           data-automation-id="custom-showcase-form-end-date-and-time"
                           placeholder="{{ 'customShowcaseMetadataEnterDate' | translate }}"
                           formControlName="endDate">
                    <mat-datepicker-toggle [for]="endDate" class="datepicker-toggle"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </div>

                </span>
              </div>

            </div>
          </div>

          <!-- Delete showcase on end date -->
          <div class="row mb-4" data-automation-id="delete-showcase-container">
            <div class="col-12">
              <h6 class="custom-delete-showcase-label" data-automation-id="custom-showcase-delete-date-is-reached" translate>
                customShowcaseMetadataDeleteShowcaseWhenDateIsReached
              </h6>
          
              <div class="form-check form-check-inline">
                <input class="form-check-input mb-0" type="radio" formControlName="deleteExpired" name="deleteExpired"
                  data-automation-id="custom-showcase-delete-expired-true" id="delete-showcase-true" value="true">
                <label class="form-check-label custom-showcase-date-label custom-showcase-label"
                  data-automation-id="custom-showcase-delete-expired-true-label" for="delete-showcase-true" translate>customShowcaseMetadataYes</label>
              </div>
          
              <div class="form-check form-check-inline ml-5">
                <input class="form-check-input mb-0" type="radio" formControlName="deleteExpired" name="deleteExpired"
                  data-automation-id="custom-showcase-delete-expired-false" id="delete-showcase-false" value="false">
                <label class="form-check-label custom-showcase-date-label custom-showcase-label"
                  data-automation-id="custom-showcase-delete-expired-false-label" for="delete-showcase-false" translate>customShowcaseMetadataNo</label>
              </div>
            </div>
          </div>

          <!-- Warning text -->
          <div class="row mb-4">
            <div class="col-12">
              <p class="bordered-warning" translate>customShowcaseMetadataWarningText</p>
            </div>
          </div>
        </div>

        <app-custom-showcase-form-metadata
          formGroupName="metadata"
          [createdFrom]="showcase.createdFrom.type"
          [nickname]="isEditMode ? showcase.creator.nickname : (nickname$ | async)"
          [staffName]="staffName$ | async"
        >
        </app-custom-showcase-form-metadata>
      </form>
    </div>

    <ng-container *ngIf="!isEditMode">
      <div class="custom-showcase-buttons d-flex justify-content-center">
        <ng-container [ngTemplateOutlet]="formButtons"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="loadingOrError"></ng-container>
    </ng-container>

    <ng-template #formButtons>
      <!-- Save -->
      <button type="button"
              (click)="save()"
              form="custom-showcase-form"
              data-automation-id="custom-showcase-save-button"
              class="ins-buttons button-secondary flint save-button px-4 px-md-10 mr-2"
              translate>
        customShowcaseSaveButton
      </button>

      <div *ngIf="isEditMode" class="ml-0 ml-sm-3 ml-md-5 ml-lg-10 ml-xl-24 mr-0 mr-sm-2 mr-md-5">
        <app-custom-showcase-form-publish-status
          [published]="showcase.published"
          [publishDate]="showcase.publishDate">
        </app-custom-showcase-form-publish-status>
      </div>

      <!-- Save & Publish, Publish, Unpublish -->
      <ng-container *ngIf="!isAuthPatronFlagEnabled">
        <button *ngIf="hasPublishShowcasePermission$ | async"
                type="button"
                (click)="save(true)"
                data-automation-id="custom-showcase-save-and-publish-button"
                class="ins-buttons button-primary save-publish-button flint px-3 px-md-5 ml-2">
          <ng-container *ngIf="!isEditMode; else publishOrUnpublish">
            <span class="fas fa-upload mr-1" aria-hidden="true"></span>
            {{ 'customShowcaseSaveAndPublishButton' | translate }}
          </ng-container>
          <ng-template #publishOrUnpublish>
            <span class="fas mr-1 d-none d-md-inline" [ngClass]="showcase.published ? 'fa-eye-slash' : 'fa-upload'"
                  aria-hidden="true"></span>
            {{ (showcase.published ? 'customShowcaseUnpublishButton'
            : 'customShowcasePublishButton') | translate }}
          </ng-template>
        </button>
      </ng-container>

      <ng-container *ngIf="isAuthPatronFlagEnabled">
        <ng-container *hasShowcasePermission="{
            permission: showcasePermissions.PUBLISH,
            showcaseId: showcase.id
          }">
          <button type="button"
                  (click)="save(true)"
                  data-automation-id="custom-showcase-save-and-publish-button"
                  class="ins-buttons button-primary save-publish-button flint px-3 px-md-5 ml-2">
            <ng-container *ngIf="!isEditMode; else publishOrUnpublish">
              <span class="fas fa-upload mr-1" aria-hidden="true"></span>
              {{ 'customShowcaseSaveAndPublishButton' | translate }}
            </ng-container>
            <ng-template #publishOrUnpublish>
              <span class="fas mr-1 d-none d-md-inline" [ngClass]="showcase.published ? 'fa-eye-slash' : 'fa-upload'"
                    aria-hidden="true"></span>
              {{ (showcase.published ? 'customShowcaseUnpublishButton'
              : 'customShowcasePublishButton') | translate }}
            </ng-template>
          </button>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #loadingOrError>
      <div class="spinner-container d-flex justify-content-center m-2" role="status" aria-live="polite">
        <div *ngIf="isSaving" class="spinner-border text-white" data-automation-id="save-custom-showcase-spinner">
          <span class="sr-only" translate>Loading...</span>
        </div>
        <div aria-atomic="true" aria-live="assertive" role="alert">
          <div *ngIf="hasError"
               class="text-white"
               data-automation-id="save-custom-showcase-error">
            {{'generalServerError' | translate}}
          </div>
        </div>
      </div>
    </ng-template>

  </div>
</perfect-scrollbar>
