import { createAction, props } from '@ngrx/store';
import { BulkFeatureKey } from '../enums/bulk-feature-key';
import { BulkSelectItem } from '../models/bulk-select-item';

export const toggleSelectItem = createAction(
  '[BulkSelect] Toggle select item',
  props<{ bulkFeatureKey: BulkFeatureKey, item: BulkSelectItem }>(),
);

export const bulkSelectAllItems = createAction(
  '[BulkSelect] Select all items',
  props<{ bulkFeatureKey: BulkFeatureKey, items: BulkSelectItem[] }>(),
);

export const resetBulkSelection = createAction(
  '[BulkSelect] Reset bulk items selection',
  props<{ bulkFeatureKey: BulkFeatureKey }>(),
);

export const bulkHoldSelectedItems = createAction(
  '[BulkSelect] Bulk hold selected items',
  props<{ bulkFeatureKey: BulkFeatureKey, items: BulkSelectItem[] }>(),
);
