
import { ArticleData, EntityTypes, FormatGroup, IdentifiedBy, NonBibframe, TabEntity, } from '../../entity/models/entity';
import { RollUpTab } from '../../entity/models/rollup-card';
import { EContent } from './e-content';
import { ElectronicLocator } from './modal-window-data';

export interface SearchRequestBody {
  resourceType: string;
  searchText?: string;
  searchType?: string;
  pageNum: number;
  pageSize: number;
  sorting?: string;
  sortOrder?: string;
  expand?: boolean;
  metadataBoolQuery?: string;
  dateFrom?: number;
  dateTo?: number;
  catalogDate?: CatalogDate;
  conceptIds?: string[];
  agentIds?: string[];
  universalLimiterIds?: string[];
  materialTypeIds?: string[];
  resourceIds?: string[];
  locationIds?: string[];
  languageIds?: string[];
  fullText?: boolean;
  collectionIds?: string[];
  publisherIds?: string[];
  donorIds?: string[];
  literaryFormIds? : string[];
}

export interface Agent {
  linkType?: {
    id?: string;
    attributes?: {
      sequentialDesignation: string;
      role: string;
    };
  };
  item: {
    id?: string;
    description?: string[];
    label?: string;
    type?: string;
    identifiedBy?: IdentifiedBy[];
    alternateAgent?: {
      label?: string;
    };
  };
}

export interface TitleDescription {
  type?: string;
  variantType?: string;
  mainTitle?: string;
  label?: string;
  subtitle?: string;
  language?: string;
  alternateTitle?: AlternateTitle | AlternateTitle[];
}

export interface AlternateTitle {
  label: string;
}

export interface ArticleUnit {
  label: string;
  count: string;
}

export interface SearchResult {
  id: string;
  entityType: EntityTypes;
  editionStatement?: string[];
  frequency?: {
    label: string;
    date: string;
  }[];
  issuance?: string[];
  genreForm?: string[];
  supplementaryContent?: string[];
  awards?: string[];
  tableOfContents?: string[];
  extent?: string[];
  dimensions?: string[];
  fullText?: boolean;
  $title?: string[];
  title?: TitleDescription[];
  notFoundWords?: string[];
  highlight?: {
    fieldPath: string;
    fieldName?: string;
    value: string[];
  }[];
  hasSeries?: {
    label: string;
    seriesStatement: string[];
  }[];
  identifiedBy?: IdentifiedBy[];
  intendedAudience?: {
    source?: string;
    label?: string;
  }[];
  provisionActivityStatement?: string[];
  unit?: ArticleUnit[];
  electronicLocator?: ElectronicLocator[];
  entitlementUrls?: string[];
  $language?: string[];
  $publicationDate?: string[];
  concept?: SearchResultConcept[];
  agent?: Agent[];
  primaryAgent?: TabEntity;
  work?: WorkInInstance[];
  locationMap?: Record<string, LocationInfo[]>;
  nonBibframe?: {
    eContent: EContent[]
  };
  recordId?: string[];
  saved?: boolean;
  type?: string | string[];
  heldCount?: number;
  checkedOutCount?: number;
  materialType?: string[];
  $materialType?: string[];
  locationAvailability?: MaterialTypeLocationAvailability[];
  hasMetadataLinks?: boolean;
  tabInfo?: RollUpTab;
  formatGroupId?: string;
  instanceId?: string;
  instances?: MaterialTypeGroupResult[];
  usePublicApi?: boolean;
  coverUrl?: CoverUrl;
}

export interface SearchResultConcept {
  linkType?: {
    id: string;
    attributes?: any;
  },
  item: {
    id?: string;
    recordId?: string [];
    description?: string[];
    label: string;
    type?: string;
    identifiedBy?: IdentifiedBy[];
    alternateConcept?: {
      label?: string;
    };
  }
}

export interface ClassificationItem {
  classificationPortion: string[];
  itemPortion: string;
  edition?: string[];
  type?: string;
  source?: string;
  identifiedBy?: IdentifiedBy[];
}

export interface WorkInInstance {
  linkType?: {
    id: string;
    attributes?: any;
  };
  item: {
    id?: string;
    classification?: ClassificationItem[];
    summary?: {
      label?: string;
      source?: string[];
      alternateSummary?: {
        label: string;
      };
    }[];
    note?: {
      'noteType': string;
    }[];
    title?: TitleDescription[];
    type?: string;
    language?: {
      label: string;
    }[];
    capture?: string[];
    creditsNote?: string[];
    dissertation?: {
      label?: string
    }[]
  };
}

export interface RollupResult {
  $title?: string[];
  id: string;
  entityType: EntityTypes;
  type: string[] | string;
  customerId: string;
  notFoundWords?: string[];
  $publicationDate?: string[];
  materialType?: string[];
  genreForm?: string[];
  issuance?: string[];
  extent?: string[];
  hasMetadataLinks: boolean;
  saved?: boolean;
  agent?: Agent[];
  primaryAgent?: TabEntity;
  concept?: any;
  title?: TitleDescription[];
  identifiedBy?: IdentifiedBy[];
  work?: WorkInInstance[];
  materialTypeGroups: Record<string, MaterialTypeGroupResult>;
  unit?: {
    label: string;
    count: string;
  }[];
  nonBibframe?: NonBibframe;
  electronicLocator?: ElectronicLocator[];
  provisionActivityStatement?: string[];
  dimensions?: string[];
  hasSeries?: {
    label: string;
    seriesStatement: string[];
  }[];
  intendedAudience?: {
    source?: string;
    label?: string;
  }[];
  $language?: string[];
  frequency?: {
    label: string;
    date: string;
  }[];
  awards?: string[];
  supplementaryContent?: string[];
  tableOfContents?: string[];
  editionStatement?: string[];
  fullText?: boolean;
}

/**
 * Location data of single item stored inside FormatGroup in server response and lately,
 * after series of transformations, stored in MatGroupData per material type.
 *
 * Glossary:
 *  - name:   actual name of location, how it is stored in location hierarchy
 *  - label:  name of parent location situated at the level chosen in location hierarchy wizard
 *  - code:   location code as is in location hierarchy
 */
export interface LocationInfo {
  label: string;
  code: string;
  availabilityStatus: LocationAvailabilityStatus;
}

/**
 * Data carrier for LocationInfo grouped by name of parent location
 * located at level chosen in location hierarchy wizard.
 */
export interface GroupedLocationInfo {
  label: string;
  codes: string[];
  isPatronHome?: boolean;
  itemLocationLabel?: string;
  itemLocationCode?: string;
  callNumber?: string;
  availabilityStatus: LocationAvailabilityStatus;
}

export interface SelectedRollUpTab {
  selectedIndex: number;
  expandLocationAndEditionInformation: boolean;
  skipFirstAutomaticSelection?: true;
}

export interface RollupResultsV2 {
  totalResults: number;
  data: FormatGroup[];
  totalPages: number;
  alternativeSearch?: string;
  truncatedSearch?: TruncatedSearch;
  page?: number;
}

export interface CitationEditionData {
  editionStatement?: string[];
  publicationDate?: string;
}

export interface CitationDataObject {
  [instanceId: string]: CitationEditionData;
}

export interface MaterialTypeGroupResult {
  id: string;
  publicationDate?: string;
  dimensions?: string[];
  extent?: string[];
  type: string[];
  materialType?: string[];
  provisionActivityStatement?: string[];
  editionStatement?: string[];
  locationAvailability?: MaterialTypeLocationAvailability[];
  recordId?: string[];
  titleId?: string[];
  identifiedBy?: IdentifiedBy[];
  work?: {
    classification: ClassificationItem[];
  };
  nonBibframe?: NonBibframe;
  electronicLocator?: ElectronicLocator[];
  entitlementUrls?: string[];
  eContent?: EContent[];
  instanceIds?: string[];
  citation?: CitationDataObject;
  locations?: LocationInfo[];
  callNumber?: string;
  itemLocationName?: string;
  unit?: ArticleUnit[];
}

export interface MatGroupData {
  id?: string;
  label: string;
  labelAllCaps: string;
  instanceId?: string;
  /**
   * @deprecated use availabilityStatuses instead
   */
  available: boolean;
  /**
   * @deprecated use availabilityStatuses instead
   */
  showAvailabilityInfo?: boolean;
  isGetItSuppressed?: boolean;
  isElectronicResource?: boolean;
  eContent?: EContent[];
  electronicLocator?: {
    url?: string;
  }[];
  eResourceUrls?: string[];
  isArticle?: boolean;
  isJournal?: boolean;
  isComplexRollup?: boolean;
  isEnoughDataToBuildOpenUrl?: boolean;
  generatedOpenUrl?: string;
  heldCount?: number;
  checkedOutCount?: number;
  recordId?: string[];
  reserveId?: string[];
  materialType?: string[];
  entityType: string;
  holds?: number;
  copies?: number;
  availabilityStatus?: Availability;
  instancesInfo?: MatGroupData[];
  callNumber?: string;
  hasItems?: boolean;
  articleInfo?: ArticleData | null;
  instanceIds?: string[];
  citation?: CitationDataObject;
  groupedLocations?: GroupedLocationInfo[];
  itemLocationName?: string;
  hasHoldings?: boolean;
  holdingsDisplayStrings?: string[];
}

export interface Availability {
  general: AvailabilityStatus;
  [vendorName: string]: AvailabilityStatus;
}

export enum AvailabilityStatus {
  Available,
  CheckedOut,
  CheckAvailability,
  Unavailable,
}

export enum LocationAvailabilityStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export interface AvailabilityRollupInfo {
  available: boolean;
  showAvailabilityInfo: boolean;
  availabilityStatus: Availability;
  isGetItSuppressed: boolean;
}

export interface BookCover {
  type?: EntityTypes;
  coverUrl?: CoverUrl;
}

export interface CoverUrl {
  small: string;
  medium: string;
  large: string;
}

export interface Format extends MatGroupData {
  overDriveLink?: string;
  overDriveImgUrl?: string;
  cloudLibraryLink?: string;
  cloudLibraryImgUrl?: string;
  info?: string;
}

export interface SearchResults {
  totalResults: number;
  alternativeSearch?: string;
  totalPages?: number;
  data: SearchResult[];
  personsAndConcepts?: {
    id?: string;
    entityType?: string;
  }[];
}

export interface TruncatedSearch {
  firstTruncatedWord: string;
  maxSearchWords: number;
}
export interface LocationAvailability {
  isPhysical?: boolean;
  isAvailable: boolean;
  minDueDate: string;
  isGetItSuppressed?: boolean;
}

export interface MaterialTypeLocationAvailability {
  status: string;
  itemNumber: number;
  location: string;
  minDueDate?: string;
  hasEmptyDueDate?: boolean;
  shelfMark?: string;
  messageCode?: string;
  shelfLocation?: string;
}

export enum CatalogDate {
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type WebBridgeData = RollupResult | SearchResult;
export type ExtractCallNumberData = RollupResult | MaterialTypeGroupResult | SearchResult;

export const MAX_PAGE_NUMBER = 249;
export const MAX_PAGE_SIZE = 40;
export const MAX_TOTAL_PAGES = MAX_PAGE_NUMBER + 1;

export const OAI_MATERIAL_TYPE_REGEX = /(OAI:(?:.*:)*(?:.*\/)?)(.*)/;
