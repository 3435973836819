<div class="rollup-tabs-content-locations">
    <ul>
      <li *ngFor="let location of locations; last as isLast; let i = index; trackBy: trackByCode"
          class="mr-1">
        <a
          class="location-link d-inline-block px-1 py-1"
          (click)="$event.preventDefault(); locationClicked$.emit(location)"
          [attr.data-automation-id]="'location-' + location.label + '-' + i"
          [attr.aria-label]="'Find an available copy in ' + ('location.' + location.codes | translate)"
          tabindex="0"
          href="">
          <ng-container [ngSwitch]="location.availabilityStatus">
            <fa-icon *ngSwitchCase="locationAvailabilityStatusEnum.Available"
                     [icon]="availableIcon"
                     class="icon icon--available">
            </fa-icon>
            <fa-icon *ngSwitchCase="locationAvailabilityStatusEnum.Unavailable"
                     [icon]="unavailableIcon"
                     class="icon icon--unavailable">
            </fa-icon>
          </ng-container>
          <span class="ml-1">{{ 'location.' + location.codes | translate }} </span>
        </a>
        <app-nlb-site-link *ngIf="isNlbCustomer" [label]="'location.' + location.codes | translate"></app-nlb-site-link>
        <span *ngIf="!isLast"> | </span>

      </li>
    </ul>
    <a *ngIf="displayAllLocationsLink"
       class="location-link location-link--control d-inline-block py-1 px-1"
       data-automation-id="all-locations"
       tabindex="0" href=""
       (click)="$event.preventDefault(); allLocationsClicked$.emit()">
      <fa-icon [icon]="locationIcon" class="icon icon--location"></fa-icon>
      <span class="ml-1">{{'viewAllLocations' | translate}}</span>
    </a>
</div>
