import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemVolume } from 'user/models/get-it';
import { DropdownValue } from 'common/models/custom-dropdown';
import { ISortingValue } from 'user/components/item-volume-list-sort/item-volume-list-sort.component';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { DictionariesService } from '../../../services/dictionaries.service';
import { DictionaryTypes } from '../../../models/dictionaries';

@Component({
  selector: 'app-item-volume-list',
  templateUrl: './item-volume-list.component.html',
  styleUrls: ['./item-volume-list.component.scss'],
})
export class ItemVolumeListComponent implements OnInit, AfterViewInit {

  @Input() public itemsAsVolumes: ItemVolume[];
  public sortValue: ISortingValue;
  public physicalLocations = DictionaryTypes.PHYSICAL_LOCATIONS;

  @ViewChild('itemVolumesSearchInput') public itemVolumesSearchInput: ElementRef;
  public readonly searchIcon = faSearch;
  public queriedItemsAsVolumes: ItemVolume[];
  private FILTER_FIELDS = ['location', 'callNumber'];

  constructor(
    public activeModal: NgbActiveModal,
    public dictionariesService: DictionariesService,
  ) { }

  public ngOnInit(): void {
    this.itemsAsVolumes = this.sortUsingCriteria(this.itemsAsVolumes.slice(), {sorting: 'id', sortOrder: 'desc'});
    this.queriedItemsAsVolumes = this.itemsAsVolumes;
  }

  ngAfterViewInit(): void {
    if (this.itemVolumesSearchInput?.nativeElement) {
      fromEvent(this.itemVolumesSearchInput.nativeElement,'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => {
            this.onItemVolumesSearch(this.itemVolumesSearchInput.nativeElement.value);
          })
        )
        .subscribe();
    }
  }

  public onClose() {
    this.activeModal.dismiss();
  }

  public trackById(index: number, itemVolume: ItemVolume) {
    return itemVolume.id;
  }

  public placeHold(itemVolume: ItemVolume) {
    this.activeModal.close(itemVolume);
  }

  public sort(value: DropdownValue<ISortingValue>): void {
    const { selected } = value;
    this.queriedItemsAsVolumes = this.sortUsingCriteria(this.queriedItemsAsVolumes.slice(), selected);
  }

  private sortUsingCriteria(rows: ItemVolume[], sortingCriteria: ISortingValue) {
    switch (sortingCriteria.sorting) {
      case 'id':
        return rows.sort((a, b) => Number(b.id) - Number(a.id));
      case 'callNumber':
        return sortingCriteria.sortOrder === 'asc' ?
          rows.sort((a, b) => a.callNumber?.localeCompare(b.callNumber) || Number(b.id) - Number(a.id)) :
          rows.sort((a, b) => b.callNumber?.localeCompare(a.callNumber) || Number(b.id) - Number(a.id));
      case 'location':
        return sortingCriteria.sortOrder === 'asc' ?
          rows.sort((a, b) => a.location?.localeCompare(b.location) || Number(b.id) - Number(a.id)) :
          rows.sort((a, b) => b.location?.localeCompare(a.location) || Number(b.id) - Number(a.id));
      default:
        return rows.sort((a, b) => Number(b.id) - Number(a.id));
    }
  }

  private filterUsingQuery(rows: ItemVolume[], query: string) {
    if (!query) {
      return rows;
    }
    return rows.reduce((target: ItemVolume[], item) => {
      for (const filter of this.FILTER_FIELDS) {
        if ((item as any)[filter]?.toLowerCase()?.trim()?.includes(query?.toLowerCase()?.trim())) {
          target.push(item);
          break;
        }
      }
      return target;
    }, []);
  }

  public onItemVolumesSearch(query: string) {
    this.queriedItemsAsVolumes = this.filterUsingQuery(this.itemsAsVolumes, query);
  }

  protected readonly faSearch = faSearch;
}
