import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { toggleSelectItem } from '../../actions/bulk-select.actions';
import { BulkFeatureKey } from '../../enums/bulk-feature-key';
import { BulkSelectItem } from '../../models/bulk-select-item';
import { BulkSelectState, isItemSelected } from '../../reducers/bulk-select.reducer';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'app-bulk-select-checkbox',
  templateUrl: './bulk-select-checkbox.component.html',
})
export class BulkSelectCheckboxComponent {
  @Input() bulkFeatureKey: BulkFeatureKey;
  @Input() item: BulkSelectItem;
  @Input() ariaTitleId: string;
  public isItemSelected$: Observable<boolean>;
  public isAuthorizedInKeycloak$ = this.store.select(isAuthorizedInKeycloak);
  public isFeatureBulkHoldsEnabled: boolean;
  constructor(
    private store: Store<BulkSelectState>,
    private featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit() {
    this.isFeatureBulkHoldsEnabled = this.featureToggleService.getToggles()['DIS-27182_2024-07-30_bulk_holds'];
    this.isItemSelected$ = this.store.select(isItemSelected(this.bulkFeatureKey, this.item.id));
  }

  public onItemSelectChange() {
    this.store.dispatch(toggleSelectItem({bulkFeatureKey: this.bulkFeatureKey, item: this.item}));
  }
}
