import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'merlin',
  templateUrl: './merlin.component.html',
  styleUrls: ['./merlin.component.scss']
})
export class MerlinComponent {
  @Output('openMerlinModalDialogEvent') openMerlinModalDialogEvent = new EventEmitter<boolean>();

  openMerlinDialog() {
    this.openMerlinModalDialogEvent.emit();
  }

}
