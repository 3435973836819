import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { bulkSelectAllItems, bulkHoldSelectedItems, resetBulkSelection } from '../../actions/bulk-select.actions';
import { BulkFeatureKey } from '../../enums/bulk-feature-key';
import { BulkSelectItem } from '../../models/bulk-select-item';
import { BulkSelectState, getSelectedItems } from '../../reducers/bulk-select.reducer';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'app-bulk-select-panel',
  templateUrl: './bulk-select-panel.component.html',
  styleUrls: ['./bulk-select-panel.component.scss']
})
export class BulkSelectPanelComponent implements OnInit {
  @Input() bulkFeatureKey: BulkFeatureKey;
  @Input() items: BulkSelectItem[] = [];
  public selectedItems$: Observable<BulkSelectItem[]>;
  public isAuthorizedInKeycloak$ = this.store.select(isAuthorizedInKeycloak);
  public isFeatureBulkHoldsEnabled: boolean;
  constructor(
    private store: Store<BulkSelectState>,
    private featureToggleService: FeatureToggleService
  ) {
  }

  public ngOnInit() {
    this.isFeatureBulkHoldsEnabled = this.featureToggleService.getToggles()['DIS-27182_2024-07-30_bulk_holds'];
    this.selectedItems$ = this.store.select(getSelectedItems(this.bulkFeatureKey));
  }

  public isAllItemsSelected(selectedItems: BulkSelectItem[]): boolean {
    return this.items.length === selectedItems.length;
  }

  public onBulkSelectionChange(selectAll: boolean) {
    selectAll
      ? this.store.dispatch(bulkSelectAllItems({bulkFeatureKey: this.bulkFeatureKey, items: this.items}))
      : this.store.dispatch(resetBulkSelection({bulkFeatureKey: this.bulkFeatureKey}));
  }

  public onBulkPlaceHold(selectedItems: BulkSelectItem[]) {
    if (selectedItems.length) {
      this.store.dispatch(bulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items: selectedItems}));
    }
  }
}
