<div class="d-flex align-items-center">
  <ng-container [ngSwitch]="cardType">
    <a *ngSwitchCase="cardTypeEnum.list"
       href=""
       (click)="$event.preventDefault(); onClick.emit()"
       class="d-flex card-type-link align-items-center mr-2"
       data-automation-id="bookshelf-card-type-link">
      <ng-container *ngTemplateOutlet="cardStatusLabel; context: {
        icon: bookmarksIcon,
        label: 'bookshelfCardStatusList' | translate
      }"></ng-container>
    </a>

    <a *ngSwitchCase="cardTypeEnum.savedSearch"
       href=""
       (click)="$event.preventDefault(); onClick.emit()"
       class="d-flex card-type-link align-items-center mr-2"
       data-automation-id="bookshelf-card-type-link">
      <ng-container *ngTemplateOutlet="cardStatusLabel; context: {
        icon: searchIcon,
        label: 'bookshelfCardStatusSavedSearch' | translate
      }"></ng-container>
    </a>

    <a *ngSwitchCase="cardTypeEnum.showcase"
       href=""
       (click)="$event.preventDefault(); onClick.emit()"
       class="d-flex card-type-link align-items-center mr-2"
       data-automation-id="bookshelf-card-type-link">
      <ng-container *ngTemplateOutlet="cardStatusLabel; context: {
        icon: showcaseIcon,
        label: 'bookshelfCardStatusShowcase' | translate
      }"></ng-container>
    </a>
  </ng-container>

  <div class="d-flex align-items-center" *ngIf="!loading; else spinner" data-automation-id="bookshelf-card-status">
    <ng-container *ngIf="published; else draftStatus">
      <fa-icon [icon]="publishedIcon" class="status-published-icon mr-1"></fa-icon>
      <span translate class="status-label">bookshelfCardStatusPublished</span>
    </ng-container>
    <ng-template #draftStatus>
      <span class="text-uppercase status-label" translate>bookshelfCardStatusDraft</span>
    </ng-template>
  </div>
</div>

<ng-template #cardStatusLabel let-icon="icon" let-label="label">
  <fa-icon [icon]="icon" class="card-type-icon mr-1"></fa-icon>
  <span>{{label}}</span>
</ng-template>

<ng-template #spinner>
  <div aria-live="polite" class="d-flex" role="status">
    <div *ngIf="loading"
         class="spinner-border text-primary p-2"
         data-automation-id="bookshelf-card-status-spinner">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
</ng-template>
