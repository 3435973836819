import { KeycloakRole } from '../../keycloak/models/keycloak';

export interface User {
  id: string;
  name: string;
  nickname: string;
  cardNumber: string;
  emails: string[];
  homeLibrary: string;
  homeLibraryCode: string;
  preferredPickupLocation: string;
  preferredPickupLocationCode: string;
  registeredBranch?: string;
  registeredBranchId?: string;
  addresses: UserAddress[];
  homePhones: string[];
  mobilePhones: MobilePhone[];
  expirationDate: string;
  moneyOwed: number;
  profileConfiguration: ProfileConfiguration;
  permissions: UserPermission[];
  roles: KeycloakRole[];
  notificationPreference: NotificationPreferenceData;
}

export interface StaffUser {
  info: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
  }
  showcasePermissions: UserPermission[];
  roles: KeycloakRole[];
}

export enum UserPermission {
  SHOWCASES_VIEW = 'showcases::view',
  SHOWCASES_CREATE = 'showcases::create',
  SHOWCASES_PERSONAL_UPDATE = 'showcases/personal::update',
  SHOWCASES_NON_PERSONAL_UPDATE = 'showcases/non-personal::update',
  SHOWCASES_PERSONAL_DELETE = 'showcases/personal::delete',
  SHOWCASES_NON_PERSONAL_DELETE = 'showcases/non-personal::delete',
  SHOWCASES_PUBLISH = 'showcases::publish',
  SHOWCASES_GENERATE = 'showcases::generate-embedded',
}

export interface ProfileConfiguration {
  fieldsConfiguration: FieldsConfiguration;
  passcodePolicyMessage?: string;
  libraryContactLink?: string;
  expirationMessage?: ExpirationMessageInfo;
  holdSetting: HoldSettingType;
  holdExpirationDefault: number;
  holdActivationDateSetting: boolean;
  borrowByMailConfiguration?: BorrowByMailConfiguration;
  notificationConfiguration: NotificationPreferenceConfig;
  carriers: Carrier[];
}

export type NotificationSupportedTypes = 'email' | 'mail' | 'text' | 'phone' | 'none';

export interface NotificationPreferenceConfig {
  enabled: boolean;
  supportedTypes: NotificationSupportedTypes[];
  usePreselectedPhone: boolean;
}

export interface NotificationPreferenceData {
  channel: NotificationSupportedTypes;
  preselectedPhone?: string | null;
}

export interface MobilePhone {
  phone: string;
  carrierId?: number;
}

export interface Carrier {
  carrierId: number;
  carrierName: string;
}

export enum HoldSettingType {
  ASK = 'ASK',
  HOME = 'HOME',
}

export interface BorrowByMailConfiguration {
  enabled: boolean;
  deliveryChargeMessage?: string;
}

export interface ExpirationMessageInfo {
  daysLeft: number;
}

export interface FieldsConfiguration {
  name: boolean;
  emails: boolean;
  addresses: boolean;
  mobilePhones: boolean;
  homePhones: boolean;
  homeLibraryCode: boolean;
  preferredPickupLocationCode: boolean;
  enableReadingHistory: boolean;
  passcode: boolean;
}

export interface ProfileUpdate {
  name?: string;
  nickname?: string;
  emails?: string[];
  homeLibraryCode?: string;
  preferredPickupLocationCode?: string;
  addresses?: UserAddress[];
  homePhones?: string[];
  mobilePhones?: MobilePhone[];
  passcode?: string;
  notificationPreference?: NotificationPreferenceData;
}

export type ProfileUpdated =
  (ProfileUpdate & { homeLibrary: string, preferredPickupLocation: string })
  | Omit<ProfileUpdate, 'homeLibraryCode' | 'preferredPickupLocationCode'>;

export interface ProfileUpdateError {
  status: number;
  fields?: ProfileUpdateErrorField[];
}

export type ProfileUpdateErrorField =
  'name' |
  'nickname' |
  'emails' |
  'homeLibraryCode' |
  'preferredPickupLocationCode' |
  'addresses' |
  'homePhones' |
  'mobilePhones' |
  'notificationPreference';

export interface UserAddress {
  line1: string;
  line2: string;
  city: string;
  stateCode: string;
  postalCode: string;
}

export enum ProfileUpdateServerError {
  update = 1,
}

export interface PatronUser {
  id: string;
  recordId: string;
  branchId: string;
  firstName: string;
  lastName: string;
  nickname: string;
  birthDate: Date;
  phoneNumber: string;
  mobilePhoneNumber: string;
  email: string;
  homeLibrary: string;
  preferredPickupLocation?: string;
  registeredBranch?: string;
  addressLine1: string;
  addressLine2: string;
  state: string;
  city: string;
  zipCode: string;
  country: string;
  cardNumber: string;
  cardExpirationDate: Date;
  preferredNotificationChannel: string;
  smsOptInStatus: boolean;
  registrationDate: Date;
}

export interface NewPatronAddress{
  addressLine1: string;
  addressLine2: string;
  state: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface UserLocationsToUpdate{
  [key: string]: string;
}
