<div [formGroup]="metadata" class="container-fluid p-0">
  <div class="row">
    <!-- SORT ORDER -->
    <div class="col-12 col-md-4 mb-4 form-group" *ngIf="createdFrom === createdFromType.list" data-automation-id="metadata-sort-order-container">
      <label for="metadata-sort-order" class="metadata-label mb-2" data-automation-id="metadata-sort-order-label">
        <fa-icon [icon]="sortIcon" [classes]="['icon']" [fixedWidth]="true"></fa-icon>
        {{ 'customShowcaseMetadataSortOrder' | translate }}
      </label>
      <select class="form-control metadata-select ml-2 mr-4 my-4"
              id="metadata-sort-order"
              formControlName="sortOrder"
              data-automation-id="metadata-sort-order-select">
        <option *ngFor="let sortOrderTranslation of sortOrderTranslations | keyvalue:originalOrder"
                [value]="sortOrderTranslation.key" data-automation-id="metadata-sort-order-select-option">
          {{ sortOrderTranslation.value | translate }}
        </option>
      </select>
    </div>

    <!-- DISPLAY ON -->
    <div class="col-12 col-md-4 mb-4" *ngIf="createdFrom === createdFromType.list" data-automation-id="metadata-display-on-container">
      <fieldset class="form-group p-0 m-0" [formGroup]="displayOn" data-automation-id="metadata-display-on">
        <legend class="metadata-label" data-automation-id="metadata-display-on-label">
          <span class="icon far fa-eye fa-fw" aria-hidden="true"></span>
          {{ 'customShowcaseMetadataDisplayShowcaseOn' | translate }}
        </legend>
        <ng-container *ngFor="let displaysOn of displayOn.controls | keyvalue:originalOrder; index as i">
          <app-custom-checkbox
            [formControlName]="displaysOn.key"
            [containerClass]="['ml-2 mr-4 my-4']"
            [attr.data-automation-id]="'metadata-display-on-checkbox-' + i">
            {{ displayOnTranslations[displaysOn.key] | translate }}
          </app-custom-checkbox>
        </ng-container>
      </fieldset>
      <div class="ml-10">
        <app-custom-showcase-display-on-box></app-custom-showcase-display-on-box>
      </div>
    </div>

    <!-- DISPLAY CREATOR -->
    <div class="col-12 col-md-4 mb-4 form-group" data-automation-id="metadata-display-creator">
      <label class="metadata-label" data-automation-id="metadata-display-creator-label">
        <span class="icon far fa-user fa-fw" aria-hidden="true"></span>
        {{ 'customShowcaseMetadataDisplayShowcaseCreator' | translate }}
      </label>
      <app-custom-checkbox
        formControlName="displayCreator"
        [containerClass]="['ml-2 mr-4 my-4']"
        data-automation-id="metadata-display-creator-checkbox">
        {{ 'customShowcaseMetadataCreatedBy' | translate }}
        <span class="bold">{{nickname || staffName}}</span>
      </app-custom-checkbox>
      <p
        *ngIf="!nickname && !staffName"
        data-automation-id="metadata-nickname-note"
        class="metadata-note ml-2"
        translate>
        showcaseNicknameNote
      </p>
    </div>
  </div>

  <!-- ABOUT SHOWCASE -->
  <app-custom-showcase-form-about-showcase
    [formGroup]="aboutShowcase">
  </app-custom-showcase-form-about-showcase>
</div>
