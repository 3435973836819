import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-wizard-ai',
  templateUrl: './wizard-ai.component.html',
})
export class WizardAiComponent {
  public isMerlinModalDialogOpened = false;
  public isChatDialogOpen = false;
  public isQuizDialogOpen = false;

  constructor(
    private readonly chRef: ChangeDetectorRef,
  ) {
  }

  public onMerlinClicked() {
    if (!this.isMerlinModalDialogOpened) {
      this.isMerlinModalDialogOpened = true;
      this.chRef.detectChanges();
    }
  }

  public closeQuizDialog() {
    this.isQuizDialogOpen = false;
    this.isMerlinModalDialogOpened = false;
    this.chRef.detectChanges();
  }

  public openQuizDialog() {
    this.isQuizDialogOpen = true;
    this.isMerlinModalDialogOpened = false;
    this.chRef.detectChanges();
  }

  public openChatDialog() {
    this.isChatDialogOpen = true;
    this.isMerlinModalDialogOpened = false;
    this.chRef.detectChanges();
  }

  public closeChatDialog() {
    this.isChatDialogOpen = false;
    this.chRef.detectChanges();
  }

  public closeMerlinModal() {
    this.isMerlinModalDialogOpened = false;
    this.chRef.detectChanges();
  }
}
