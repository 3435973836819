export enum InnReachType {
  WebPac = 'WebPac Pro',
  Encore = 'Encore',
}

export enum PassThroughType {
  CustomPassThrough = 'custompassthrough',
  InnReach = 'innreachpassthrough'
}

export enum PassThroughTypeUrlParams {
  AUTHOR = '##AUTHOR##',
  TITLE = '##TITLE##',
  KEYWORD = '##KEYWORD##'
}


export interface CustomizableLink {
  title: string;
  href: string;
}

export interface LinkResolver {
  host: string;
  sid: string;
  enabled: boolean;
}

export interface IInnReachConfiguration {
  type: InnReachType;
  systemName: string;
  url: string;
  hideSystemName: boolean;
  imageKey?: string;
  imageUrl?: string;
  file?: File;
}

export interface ISearchData {
  title: string;
  author: string;
}

export enum CustomPassThroughDisplayOption {
  SHOW_ALL_TITLES = 'SHOW_ALL_TITLES',
  SHOW_UNAVAILABLE_TITLES = 'SHOW_UNAVAILABLE_TITLES',
}

export interface PassThroughConfigParamsI {
  vendor: PassThroughType,
  name?: string,
  searchUrl?: string,
  worksUrl?: string,
  url?: string,
  hideSystemName?: boolean,
  displayOption?: CustomPassThroughDisplayOption,
  imageUrl?: string,
  systemName?: string,
  type?: InnReachType,
}

export interface PassThroughItemI {
  connectionId: string,
  vendor: PassThroughType,
  enabled: boolean,
  logoUrl?: string,
  params: PassThroughConfigParamsI,
  type: PassThroughType,
}
