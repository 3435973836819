import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { SharedModule } from 'common/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import * as customShowcase from '../custom-showcase/reducers/custom-showcase.reducer';
import { SavedSearchEffects } from '../saved-search/effects/saved-search.effects';
import * as savedSearch from '../saved-search/reducers/saved-search.reducer';
import { CopyToClipboardService } from '../services/copy-to-clipboard.service';
import { ShowcaseGenerationPopupComponent } from './components/showcase-generation-popup/showcase-generation-popup.component';
import * as copyShowcaseUrl from './reducers/copy-showcase-url.reducer';
import { CustomShowcaseCardComponent } from './components/custom-showcase-card/custom-showcase-card.component';
import { CustomShowcaseDisplayOnBoxComponent } from './components/custom-showcase-display-on-box/custom-showcase-display-on-box.component';
import {
  CustomShowcaseFormAboutShowcaseComponent,
} from './components/custom-showcase-form-about-showcase/custom-showcase-form-about-showcase.component';
import { CustomShowcaseFormMetadataComponent } from './components/custom-showcase-form-metadata/custom-showcase-form-metadata.component';
import {
  CustomShowcaseFormPublishStatusComponent,
} from './components/custom-showcase-form-publish-status/custom-showcase-form-publish-status.component';
import { CustomShowcaseFormComponent } from './components/custom-showcase-form/custom-showcase-form.component';
import { CustomShowcasesListComponent } from './components/custom-showcases-list/custom-showcases-list.component';
import { CustomShowcasesComponent } from './components/custom-showcases/custom-showcases.component';
import { RemoveCustomShowcaseModalComponent } from './components/remove-custom-showcase-modal/remove-custom-showcase-modal.component';
import { CustomShowcaseEffects } from './effects/custom-showcase.effects';
import { CustomShowcaseStoreAdapterService } from './services/custom-showcase-store-adapter.service';
import { CustomShowcaseService } from './services/custom-showcase.service';
import { CustomShowcaseSettingsHeaderComponent } from './components/custom-showcase-settings-header/custom-showcase-settings-header.component';
import { CuratedShowcaseViewComponent } from './components/curated-showcase-view/curated-showcase-view.component';
import { DynamicShowcaseViewComponent } from './components/dynamic-showcase-view/dynamic-showcase-view.component';
import {
  DynamicShowcaseSearchDataComponent,
} from './components/dynamic-showcase-view/dynamic-showcase-search-data/dynamic-showcase-search-data.component';
import { EmbeddableShowcaseService } from './services/embeddable-showcase.service';
import { ShowcaseGenerationService } from './services/showcase-generation.service';
import { EmbeddableShowcaseErrorDialogComponent } from './components/embeddable-showcase-error-dialog/embeddable-showcase-error-dialog.component';
import { PermissionsModule } from 'app/permissions/permissions.module';
import { TippyModule } from '@ngneat/helipopper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomShowcaseScheduleComponent } from './components/custom-showcase-schedule/custom-showcase-schedule.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const COMPONENTS = [
  CustomShowcasesComponent,
  CustomShowcasesListComponent,
  CustomShowcaseFormComponent,
  CustomShowcaseFormMetadataComponent,
  CustomShowcaseFormAboutShowcaseComponent,
  CustomShowcaseFormPublishStatusComponent,
  CustomShowcaseDisplayOnBoxComponent,
  CustomShowcaseCardComponent,
  RemoveCustomShowcaseModalComponent,
  CustomShowcaseSettingsHeaderComponent,
  CuratedShowcaseViewComponent,
  DynamicShowcaseViewComponent,
  DynamicShowcaseSearchDataComponent,
  ShowcaseGenerationPopupComponent,
  EmbeddableShowcaseErrorDialogComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    CustomShowcaseScheduleComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClipboardModule,
    StoreModule.forFeature(savedSearch.savedSearchFeatureKey, savedSearch.reducer),
    StoreModule.forFeature(customShowcase.featureKey, customShowcase.reducer),
    StoreModule.forFeature(copyShowcaseUrl.featureKey, copyShowcaseUrl.reducer),
    EffectsModule.forFeature([
      CustomShowcaseEffects,
      SavedSearchEffects,
    ]),
    FontAwesomeModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    FeatureToggleModule,
    PermissionsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    PermissionsModule,
    TippyModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    CustomShowcaseService,
    EmbeddableShowcaseService,
    CustomShowcaseStoreAdapterService,
    ShowcaseGenerationService,
    CopyToClipboardService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomShowcaseModule {
}
