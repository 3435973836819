<ng-container *ngIf="isFeatureBulkHoldsEnabled">
  <ng-container *ngIf="isAuthorizedInKeycloak$ | async">
    <ins-bs-checkbox
      data-automation-id="bulk-select-checkbox"
      (change)="onItemSelectChange()"
      [checked]="isItemSelected$ | async"
      [ariaLabelledby]="ariaTitleId"
      [containerClass]="['mr-3']">
    </ins-bs-checkbox>
  </ng-container>
</ng-container>
