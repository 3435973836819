import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faConveyorBeltAlt, faCopy, faEdit, faEnvelope, faEyeSlash, faTrash, faFolderOpen, faUpload } from '@fortawesome/pro-light-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BookshelfCardActionStateTypeEnum } from 'common/components/bookshelf-card-action-state/bookshelf-card-action-state.component';
import { BookshelfCardType } from 'common/components/bookshelf-card-status/bookshelf-card-status.component';
import { InformationModalComponent } from 'common/components/information-modal/information-modal.component';
import { BookshelfCardActionPayload, BookshelfCardShareActionType, CustomShowcaseActionType } from 'common/models/bookshelf-card';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { openBookmarksTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { openList } from '../../../list/actions/list.actions';
import { runSavedSearch, copySavedSearch, setLastCopied, stopFocusShareButton } from '../../../saved-search/actions/saved-search.actions';
import { CopyToClipboardService } from '../../../services/copy-to-clipboard.service';
import { copyState } from '../../reducers/copy-showcase-url.reducer';
import * as CustomShowcaseActions from '../../actions/custom-showcase.actions';
import { getFocusShareButton, getGetState, GetState } from '../../../saved-search/reducers/saved-search.reducer';
import { CustomShowcase, isCuratedShowcase, isDynamicShowcase } from '../../models/custom-showcase';
import { getIsEditFormOpened, getUpdateState } from '../../reducers/custom-showcase.reducer';
import { RemoveCustomShowcaseModalComponent } from '../remove-custom-showcase-modal/remove-custom-showcase-modal.component';
import { getPermissionsFromToken } from 'app/keycloak/actions/keycloak.actions';
import { ShowcasePermissions } from 'app/permissions/models/permissions';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

enum DisplayState {
  NONE,
  LOADING,
  SHARE_FAILED,
  SHARE_COPIED,
}

@Component({
  selector: 'app-custom-showcase-card',
  templateUrl: './custom-showcase-card.component.html',
  styleUrls: ['./custom-showcase-card.component.scss'],
})
export class CustomShowcaseCardComponent implements OnInit, OnDestroy {
  @Input() public showcase: CustomShowcase;
  @Input() public isShowcaseCreator: boolean;
  @Output() public readonly onShowcaseOpened = new EventEmitter();

  public ariaTitleId: string;
  public isUpdating = false;
  public isEditFormOpened = false;

  public readonly focus$: Observable<void>;
  public readonly focusActionsMenuToggleButton$: Observable<void>;
  public readonly focusShareMenuToggleButton$: Observable<void>;
  public readonly hasPublishShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PUBLISH));
  public readonly hasUpdatePersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_UPDATE));
  public readonly hasUpdateNonPersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_NON_PERSONAL_UPDATE));
  public readonly hasDeletePersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_DELETE));
  public readonly hasDeleteNonPersonalShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_NON_PERSONAL_DELETE));
  public readonly hasGenerateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_GENERATE));
  public readonly editIcon = faEdit;
  public readonly trashIcon = faTrash;
  public readonly folderOpenIcon = faFolderOpen;
  public readonly unpublishIcon = faEyeSlash;
  public readonly publishIcon = faUpload;
  public readonly copyIcon = faCopy;
  public readonly emailIcon = faEnvelope;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public showcasePermissions = ShowcasePermissions;
  public isAuthPatronFlagEnabled: boolean;

  public readonly cardActionTypeEnum = CustomShowcaseActionType;
  public readonly cardShareActionTypeEnum = BookshelfCardShareActionType;
  public readonly cardActionStateTypeEnum = BookshelfCardActionStateTypeEnum;
  public readonly displayStateEnum = DisplayState;
  public readonly bookshelfCardType = BookshelfCardType;
  public readonly isDynamicShowcase = isDynamicShowcase;
  public readonly isCuratedShowcase = isCuratedShowcase;

  public displayState: DisplayState = DisplayState.NONE;

  private readonly focusSubject = new Subject<void>();
  private readonly focusActionsMenuToggleButtonSubject = new Subject<void>();
  private readonly focusShareMenuToggleButtonSubject = new Subject<void>();
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly modal: NgbModal,
    private readonly copyService: CopyToClipboardService,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleService: FeatureToggleService,
  ) {
    this.focus$ = this.focusSubject.asObservable();
    this.focusActionsMenuToggleButton$ = this.focusActionsMenuToggleButtonSubject.asObservable();
    this.focusShareMenuToggleButton$ = this.focusShareMenuToggleButtonSubject.asObservable();
  }

  public requestPermissions(showcaseId: string) {
     const showcaseString = `showcase-${showcaseId}`;
     this.store.dispatch(getPermissionsFromToken({showcaseResource: showcaseString}));
  }

  public ngOnInit(): void {
    this.ariaTitleId = `custom-showcase-${this.showcase.id}`;
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];


    this.subscriptions.add(
      this.store.select(getGetState, {id: this.showcase.createdFrom.id})
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((getState) => {
        this.setCopyState(getState);
      }),
    );

    this.subscriptions.add(
      this.store.select(copyState, {id: this.showcase.id})
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((getState) => {
        this.setCopyState(getState);
      }),
    );

    this.subscriptions.add(
      this.store.select(getUpdateState)
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((updateState) => {
        if (updateState.id === this.showcase.id) {
          this.isUpdating = updateState.loading;
          if (updateState.errorCode && !this.isEditFormOpened) {
            this.onUpdateError();
          }
        } else if (updateState.id === null) {
          this.isUpdating = false;
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(getIsEditFormOpened)
      .subscribe((isEditFormOpened) => {
        this.isEditFormOpened = isEditFormOpened;
      }),
    );

    this.subscriptions.add(
      this.store.select(getFocusShareButton).subscribe((focusShareButton) => {
        if (focusShareButton && focusShareButton === this.showcase.createdFrom.id) {
          this.store.dispatch(stopFocusShareButton());
          this.focusShareMenuToggleButtonSubject.next();
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.focusSubject.complete();
    this.focusActionsMenuToggleButtonSubject.complete();
    this.focusShareMenuToggleButtonSubject.complete();
    this.subscriptions.unsubscribe();
  }

  public triggerCardAction(actionPayload: BookshelfCardActionPayload<CustomShowcaseActionType>) {
    switch (actionPayload.actionType) {
      case CustomShowcaseActionType.edit: {
        this.onEditAction();
        break;
      }
      case CustomShowcaseActionType.delete: {
        this.onDeleteAction();
        break;
      }
      case CustomShowcaseActionType.publish: {
        this.onPublishChangeAction(true);
        break;
      }
      case CustomShowcaseActionType.unpublish: {
        this.onPublishChangeAction(false);
        break;
      }
      case CustomShowcaseActionType.open: {
        this.onShowcaseOpened.emit();
        break;
      }
      case CustomShowcaseActionType.generate: {
        this.store.dispatch(CustomShowcaseActions.openGenerateShowcaseModal({publishedShowcase: this.showcase}));
        break;
      }
    }
  }

  public onEditAction(): void {
    this.store.dispatch(CustomShowcaseActions.showForm({showcase: this.showcase}));
  }

  public onDeleteAction(): void {
    const modalRef = this.modal.open(
      RemoveCustomShowcaseModalComponent,
      {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'remove-custom-showcase-modal-title',
      },
    );
    modalRef.componentInstance.customShowcase = this.showcase;
    modalRef.result.catch(() => {
      this.focusActionsMenuToggleButtonSubject.next();
    });
  }

  public onUpdateError(): void {
    const modalRef = this.modal.open(InformationModalComponent);
    modalRef.componentInstance.bodyTranslateKey = (this.showcase.published)
      ? 'customShowcaseUnpublishError'
      : 'customShowcasePublishError';
    modalRef.result.finally(() => {
      this.focusActionsMenuToggleButtonSubject.next();
    });
  }


  public focus() {
    this.focusSubject.next();
  }

  public onPublishChangeAction(published: boolean): void {
    this.store.dispatch(CustomShowcaseActions.publish({updatedFields: {published}, id: this.showcase.id}));
  }

  public onShowcaseTypeClick(): void {
    this.isDynamicShowcase(this.showcase) ? this.runSavedSearch() : this.openList();
  }

  private runSavedSearch(): void {
    this.store.dispatch(runSavedSearch({savedSearchId: this.showcase.createdFrom.id}));
  }

  private openList(): void {
    this.store.dispatch(CustomShowcaseActions.hideForm());
    this.store.dispatch(openBookmarksTab());
    this.store.dispatch(openList({listId: this.showcase.createdFrom.id}));
  }

  public clearStatus(): void {
    this.displayState = DisplayState.NONE;
  }

  public triggerCardShareAction(actionPayload: BookshelfCardActionPayload<BookshelfCardShareActionType, undefined>) {
    switch (actionPayload.actionType) {
      case BookshelfCardShareActionType.copyLink: {
        this.store.dispatch(CustomShowcaseActions.copyUrl({id: this.showcase.id}));
        break;
      }
      case BookshelfCardShareActionType.email: {
        this.store.dispatch(CustomShowcaseActions.openEmailModal({sharedShowcase: this.showcase}));
        break;
      }
    }
  }

  private setCopyState(getState: GetState): void {
    this.displayState = DisplayState.NONE;

    if (getState) {
      if (getState.error) {
        this.displayState = DisplayState.SHARE_FAILED;
      } else if (getState.loading) {
        this.displayState = DisplayState.LOADING;
      } else if (getState.url) {
        this.copyToClipboard(getState.url);
        this.displayState = DisplayState.SHARE_COPIED;
        this.focusShareMenuToggleButtonSubject.next();
        if (isDynamicShowcase(this.showcase)) {
          this.store.dispatch(setLastCopied({id: this.showcase.createdFrom.id}));
        } else if (isCuratedShowcase(this.showcase)) {
          this.store.dispatch(CustomShowcaseActions.setLastCopied({id: this.showcase.id}));
        }
      } else if (getState.lastCopied) {
        this.displayState = DisplayState.SHARE_COPIED;
      }
      this.cdr.detectChanges();
    }
  }

  private copyToClipboard(text: string): void {
    this.copyService.copyToClipboard(text);
  }
}
