import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { select, Store, } from '@ngrx/store';
import {
  redirectCustomPassThroughSearch,
  redirectCustomPassThroughWork
} from 'common/components/custom-pass-through/actions/custom-passthrough.actions';
import { CustomPassThroughService } from 'common/components/custom-pass-through/services/custom-passthrough.service';
import { DropdownValue } from 'common/models/custom-dropdown';
import { Subscription, } from 'rxjs';
import { MappedSearchObject } from 'search/models/search-object';
import { SearchType } from 'search/models/search-type';
import { getSearchObject, State } from 'search/reducers/root.reducer';
import { ConfigurationLoader } from 'shared/configuration-loader';
import {
  CustomPassThroughDisplayOption, InnReachType,
  ISearchData,
  PassThroughItemI,
  PassThroughType,
} from '../../../models/customization';

@Component({
  selector: 'app-custom-pass-through',
  templateUrl: './custom-pass-through.component.html',
  styleUrls: ['./custom-pass-through.component.scss']
})
export class CustomPassThroughComponent implements OnDestroy, OnInit {
  @Input() public isInnReachSearchAvailable: boolean;
  @Input() public searchData: ISearchData;

  public queryParams: string;
  public urlQueryParams: string;
  public singleResultItem: boolean = false;
  public isFullCardWithTitleAndImage: boolean = false;
  private subscriptions = new Subscription();
  public selectedCustomPassThrough: PassThroughItemI = null;

  public customPassThroughData: PassThroughItemI[] = []; // Group by url

  public showAllItems: PassThroughItemI[] = []; // searchResultItems filter by result is not empty or not
  public allItems: PassThroughItemI[] = []; // merge showEmptyItems and showAllItems

  public dropDownOffset: number = 30;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly customPassThroughService: CustomPassThroughService,
    private readonly store: Store<State>,
  ) {
  }

  public ngOnInit() {
    this.setQueryParams();

    this.customPassThroughData = this.configLoader.passThroughConfig;

    if (this.customPassThroughData) {

      if (this.searchData) {
        this.dropDownOffset = 90;

        const unavailableTitles = this.customPassThroughData
        .filter((item: PassThroughItemI) => item.params.displayOption === CustomPassThroughDisplayOption.SHOW_UNAVAILABLE_TITLES);

        const showAllTitles = this.customPassThroughData
        .filter((item: PassThroughItemI) => item.params.displayOption === CustomPassThroughDisplayOption.SHOW_ALL_TITLES);

        if (this.isInnReachSearchAvailable) {
          this.customPassThroughData = [...unavailableTitles, ...showAllTitles];
        } else {
          this.customPassThroughData = [...showAllTitles];
        }

        if (this.customPassThroughData.length === 1) {
          this.singleResultItem = true;
          const hasTitle = !!(this.customPassThroughData[0].params.name || this.customPassThroughData[0].params.systemName);
          const hasImage = !!(this.customPassThroughData[0].logoUrl || this.customPassThroughData[0].params.imageUrl);
          this.isFullCardWithTitleAndImage = hasTitle && hasImage;
        }
        this.selectedCustomPassThrough = this.customPassThroughData[0];
      } else {
        if (this.customPassThroughData.length === 1) {
          this.singleResultItem = true;
        }
        this.selectedCustomPassThrough = this.customPassThroughData[0];
      }

    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public openLink(): void {
    this.setQueryParams();

    if (this.selectedCustomPassThrough.vendor === PassThroughType.InnReach) {
      const redirectUrl = this.setInnReachQueryParams(this.selectedCustomPassThrough);
      window.open(redirectUrl, '_blank');
    } else {
      if (this.searchData) {
        this.store.dispatch(redirectCustomPassThroughWork({
          data: this.searchData,
          url: this.selectedCustomPassThrough.params.worksUrl
        }));
      } else {
        this.store.dispatch(redirectCustomPassThroughSearch({
          keyword: this.queryParams,
          url: this.selectedCustomPassThrough.params.searchUrl
        }));
      }
    }
  }

  public selectCustomPass(option: DropdownValue<string>) {
    const {selected} = option;
    this.selectedCustomPassThrough = this.customPassThroughData.find((i: PassThroughItemI) => i.connectionId === selected) || null;
  }

  private setQueryParams(): void {
    this.store.pipe(select(getSearchObject))
    .subscribe((res: MappedSearchObject) => {
      if (!res) return;
      this.queryParams = res.searchText;
    });
  }

  private setInnReachQueryParams(innReachItem: PassThroughItemI): string {
    if (this.searchData) {
      const author = this.trimTrailingPunctuation(this.searchData.author || '');
      this.urlQueryParams = `t:(${this.trimTrailingPunctuation(this.searchData.title)})`;
      this.urlQueryParams += author ?
        innReachItem.params.type === InnReachType.WebPac ?
          `and a:(${author})` : `a:(${author})` : '';
      return this.setLinkUrl(innReachItem);
    } else {
      switch (this.queryParams) {
        case SearchType.AGENT:
          this.urlQueryParams = `a:(${this.queryParams})`;
          break;
        case SearchType.TITLE:
          this.urlQueryParams = `t:(${this.queryParams})`;
          break;
        default:
          this.urlQueryParams = this.queryParams;
          break;
      }
      return this.setLinkUrl(innReachItem);
    }

  }

  private trimTrailingPunctuation(text: string): string {
    return text.replace(/[^a-zA-Z0-9]+$/g, '');
  }

  private setLinkUrl(innReachItem: PassThroughItemI): string {
    const url = innReachItem?.params.url.replace(/\/$/, '');
    return innReachItem.params.type === InnReachType.WebPac ?
      `${url}/search/X?SEARCH=${encodeURIComponent(this.urlQueryParams)}` :
      `${url}/iii/encore/search/${encodeURIComponent(`C__S${this.urlQueryParams}__Orightresult__U`)}`;
  }
}
