import { Injectable } from '@angular/core';
import { OpenAI } from 'openai';
import { Stream } from 'openai/streaming';
import { environment } from '../../../environments/environments.merlin';
import { ChatMessage } from '../models/chatgpt-request-messages.model';
import { ChatGptResponse, QuizQuestion, Recommendation, } from '../models/chatgpt-response-message.model';

export enum StreamType {
  PROCESSING_QUIZ_STREAM,
  PROCESSING_RECOMMENDATION_STREAM,
}

@Injectable()
export class ChatGptService {
  private readonly FINISH_REASON_STOP = 'stop';
  private readonly CHAT_RECOMMENDATION_MESSAGE_DEFAULT_SETTINGS = 'Provide a list of ten books to recommend to the user ' +
    'by responding with JSON -\'recommendations\' ' +
    'an array that contains a \'recommendation\' ' +
    'object that has the attributes - \'reason\' ' +
    'that in detail explains the reasoning for the ' +
    'recommendation and - \'title\' that is a string with the book\'s title only with no author';
  private readonly CHAT_QUIZ_MESSAGE_DEFAULT_SETTINGS = 'Provide 10 multiple choice questions for the user based upon the book ' +
    'and the specific chapter they request.' +
    'Please generate a response in the form of a ' +
    'JSON object that adheres to' +
    ' the following schema - \'chapters\': ' +
    'A Number representing the chapters in the book. ' +
    '- \'title\': The String title of the book. ' +
    '- \'quiz\': The object holding the array of questions.' +
    ' - \'question\': ' +
    'A String holding the Nth question of the quiz. - \'options\': ' +
    'A String holding one of the four options. - \'answer\': ' +
    'A Number representing which index ' +
    'in the array the answer is starting with zero - \'answerDescription\': ' +
    'A String that explains why this answer is valid';

  private readonly API_KEY = environment.apikey;
  private readonly openAI: OpenAI = new OpenAI({
    apiKey: this.API_KEY,
    dangerouslyAllowBrowser: true
  });
  private currentQuizStreamId = 0;
  private currentRecommendationStreamId = 0;

  public async sendMessage(tittleName: string) {
    this.currentRecommendationStreamId++;
    const streamId = this.currentRecommendationStreamId;
    const request: ChatMessage = {
      model: 'gpt-4-turbo-preview',
      messages: [
        {role: 'system', content: this.CHAT_RECOMMENDATION_MESSAGE_DEFAULT_SETTINGS},
        {role: 'user', content: tittleName}
      ],
      temperature: 0,
      max_tokens: 4096,
      top_p: 1,
      stream: true,
      frequency_penalty: 0,
      presence_penalty: 0
    };
    const stream = await this.openAI.chat.completions.create(request);
    return {stream: stream, streamId};
  }

  public async generateQuizForTitle(titleName: string) {
    const streamId = this.currentQuizStreamId;
    const stream = await this.openAI.chat.completions.create({
      model: 'gpt-4-turbo-preview',
      messages: [
        {role: 'system', content: this.CHAT_QUIZ_MESSAGE_DEFAULT_SETTINGS},
        {role: 'user', content: titleName}
      ],
      temperature: 0,
      max_tokens: 4096,
      top_p: 1,
      stream: true,
      frequency_penalty: 0,
      presence_penalty: 0
    });
    return {stream: stream, streamId};
  }

  async processStream(streamType: StreamType, streamId: number, stream: Stream<OpenAI.Chat.ChatCompletionChunk>,
                      displayDataCallbackFunction: Function, streamProcessingFinshCallback: Function) {
    let fullResponse = '';

    try {
      for await (const chunk of stream) {
        if (!this.isValidStreamId(streamType, streamId)) {
          console.error('Stream is closed.');
          break;
        }
        if (this.shouldStopProcessing(chunk)) {
          break;
        }
        let response = chunk.choices[0]?.delta?.content || '';
        fullResponse += response;
        const parsedResponse = this.parseTheStream(streamType, fullResponse);
        if (parsedResponse) {
          displayDataCallbackFunction(parsedResponse);
          fullResponse = '';
        }
      }
      streamProcessingFinshCallback();
    } catch (error) {
      console.error('Error processing stream:', error);
    }
  }

  private isValidStreamId(streamType: StreamType, streamId: number): boolean {
    switch (streamType) {
      case StreamType.PROCESSING_RECOMMENDATION_STREAM:
        return streamId === this.currentRecommendationStreamId;
      case StreamType.PROCESSING_QUIZ_STREAM:
        return streamId === this.currentQuizStreamId;
      default:
        return false;
    }
  }

  private parseTheStream(streamType: StreamType | StreamType.PROCESSING_QUIZ_STREAM, fullResponse: string) {
    let parsedResponse: ChatGptResponse = null;
    if (streamType == StreamType.PROCESSING_RECOMMENDATION_STREAM) {
      parsedResponse = this.createRecommendation(fullResponse);
    } else if (streamType == StreamType.PROCESSING_QUIZ_STREAM) {
      parsedResponse = this.createQuiz(fullResponse);
    }
    return parsedResponse;
  }

  public createRecommendation(chunk: string): Recommendation {
    const reasonRegex = /"reason":\s*"([\s\S]+?)"/;
    const titleRegex = /"title":\s*"([\s\S]+?)"/;


    const reasonMatch = chunk.match(reasonRegex);
    const titleMatch = chunk.match(titleRegex);


    const reason = reasonMatch ? reasonMatch[1] : null;
    const title = titleMatch ? titleMatch[1] : null;

    let recommendation: Recommendation = null;
    if (reason && title) {
      recommendation = {
        reason: reason,
        title: title
      };
    }
    return recommendation;
  }

  public createQuiz(fullResponse: string): QuizQuestion | null {
    const blockRegex = /{[^{}]+}/g;


    const questionRegex = /"question":\s*"([^"]+)"/;
    const optionsRegex = /"options":\s*\[([^\]]+)\]/;
    const answerRegex = /"answer":\s*(\d+)/;
    const answerDescriptionRegex = /"answerDescription":\s*"([^"]+)"/;
    let quizQuestion: QuizQuestion | null = null;
    let match;

    while ((match = blockRegex.exec(fullResponse)) !== null) {
      const block = match[0];

      const questionMatch = block.match(questionRegex);
      const optionsMatch = block.match(optionsRegex);
      const answerMatch = block.match(answerRegex);
      const answerDescriptionMatch = block.match(answerDescriptionRegex);

      if (questionMatch && optionsMatch && answerMatch && answerDescriptionMatch) {
        const question = questionMatch[1];
        const options = optionsMatch[1].split(',').map(option => option.trim().replace(/^"|"$/g, ''));
        const answer = parseInt(answerMatch[1], 10);
        const answerDescription = answerDescriptionMatch[1];

        quizQuestion = {
          question: question,
          options: options,
          answer: answer,
          answerDescription: answerDescription
        };

        return quizQuestion;
      }
    }
    return quizQuestion;
  }


  private shouldStopProcessing(streamChunk: OpenAI.Chat.ChatCompletionChunk): boolean {
    return streamChunk.choices[0].finish_reason === this.FINISH_REASON_STOP;
  }

  public cancelTheQuizStream() {
    this.currentQuizStreamId++;
  }

  public cancelRecommendationsStream() {
    this.currentRecommendationStreamId++;
  }
}
