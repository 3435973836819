import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faBookmark, faCheckCircle, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons';

export enum BookshelfCardType {
  list = 1,
  showcase = 2,
  savedSearch = 3,
}

@Component({
  selector: 'app-bookshelf-card-status',
  templateUrl: './bookshelf-card-status.component.html',
  styleUrls: ['./bookshelf-card-status.component.scss'],
})
export class BookshelfCardStatusComponent {
  @Input() cardType: BookshelfCardType;
  @Input() published: boolean;
  @Input() loading: boolean;
  @Output() readonly onClick = new EventEmitter<void>();

  public readonly cardTypeEnum = BookshelfCardType;
  public readonly bookmarksIcon = faBookmark;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public readonly publishedIcon = faCheckCircle;
  public readonly searchIcon = faSearchPlus;
}
