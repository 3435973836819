import { createAction, props } from '@ngrx/store';
import { GetItPlaceHoldData, GetItPlaceTabHold, ItemVolume, Volume } from 'user/models/get-it';
import { PlaceHoldError } from 'user/reducers/bulk-hold.reducer';
import { BulkFeatureKey } from '../../bulk-select/enums/bulk-feature-key';
import { FormatGroup } from '../../entity/models/entity';

export const openBulkHoldModal = createAction(
  '[Bulk Hold] Open bulk hold modal',
  props<{bulkFeatureKey: BulkFeatureKey, availableFormatGroups: FormatGroup[], unavailableFormatGroups: FormatGroup[]}>(),
);

export const removeHoldItem = createAction(
  '[Bulk Hold] Remove Hold item',
  props<{item: FormatGroup}>(),
);

export const submitBulkHolds = createAction(
  '[Bulk Hold] Submit bulk holds',
  props<{holdRequests: GetItPlaceTabHold[]}>(),
);

export const submitIndividualHoldRequest = createAction(
  '[Bulk Hold] Submit individual hold request',
  props<{holdRequest: GetItPlaceTabHold, holdIndex: number}>(),
);

export const stopBulkHoldsProcessing = createAction(
  '[Bulk Hold] Stop bulk holds processing',
);

export const submitIndividualHoldRequestProcessing = createAction(
  '[Bulk Hold] Submit individual hold request processing',
  props<{holdRequest: GetItPlaceTabHold, holdIndex: number}>(),
);

export const submitIndividualHoldRequestSuccess = createAction(
  '[Bulk Hold] Submit individual hold request success',
  props<{holdRequest: GetItPlaceTabHold, recordId: string, holdIndex: number}>(),
);

export const submitIndividualHoldRequestFailure = createAction(
  '[Bulk Hold] Submit individual hold request failure',
  props<{holdRequest: GetItPlaceTabHold, error: PlaceHoldError, holdIndex: number}>(),
);

export const submitIndividualHoldRequestStopped = createAction(
  '[Bulk Hold] Submit individual hold request stopped',
  props<{holdRequest: GetItPlaceTabHold, holdIndex: number}>(),
);

export const openErrorDetailsModal = createAction(
  '[Bulk Hold] Show error details modal',
  props<{message: string}>(),
);

export const resetBulkHoldSubmitState = createAction(
  '[Bulk Hold] Reset bulk hold state',
);

export const reloadHoldsAfterBulkRequest = createAction(
  '[Bulk Hold] Reload holds and reset state after bulk request',
);

export const handleNeededAction = createAction(
  '[Bulk Hold] Handle needed action',
  props<{holdRequest: GetItPlaceTabHold, error: PlaceHoldError}>(),
);

export const neededActionProcessing = createAction(
  '[Bulk Hold] Needed action processing',
  props<{fgId: string}>(),
);

export const neededActionProcessingSuccess = createAction(
  '[Bulk Hold] Needed action processing success',
  props<{fgId: string}>(),
);

export const neededActionProcessingFailure = createAction(
  '[Bulk Hold] Needed action processing failure',
  props<{fgId: string, message: string}>(),
);

export const tabHoldItemAsVolumeInBulkHoldFlow = createAction(
  '[Bulk Hold] Pick item volume for tab hold in bulk hold flow',
  props<{ data: GetItPlaceTabHold, itemsAsVolumes: ItemVolume[]}>(),
);

export const tabHoldVolumeInBulkHoldFlow = createAction(
  '[Bulk Hold] Pick volume for tab hold in bulk hold flow',
  props<{ data: GetItPlaceTabHold, volumes: Volume[]}>(),
);

export const finalizeVolumeFlowInBulkHoldFlow = createAction(
  '[Bulk Hold] place hold tab item as volume in bulk hold flow',
  props<{ data: GetItPlaceHoldData, fgId: string }>(),
);

export const openIneligibleForBulkHoldModal = createAction(
  '[Bulk Hold] Open ineligible for bulk hold modal',
  props<{ineligibleFormatGroups: FormatGroup[]}>(),
);
