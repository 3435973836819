<section class="header-top">
  <a
    *ngIf="isMainPage"
    data-automation-id="header-skip-to-main-content"
    class="cdk-visually-hidden header-top__skip-link"
    (click)="skipToElement($event, 'main')"
    (keydown.space)="skipToElement($event, 'main')"
    (keydown.enter)="skipToElement($event, 'main')"
    translate
    tabindex="0">skipToMainContentButton</a>

  <div class="header-top__title-logo">
    <a class="library__title-logo-link" [routerLink]="['/']" data-automation-id="header-title-link"
      (click)="resetFacets($event)" cdkMonitorElementFocus
      [attr.aria-label]="'goToTheHomePage' | translate: {pageName: title}">
      <img [src]="logoSrc" [attr.alt]="title + ' home'" class="library__logo"
        *ngIf="logoSrc && !isLogoError" data-automation-id="logo" (load)="onImgLoad()" (error)="onImgError()">
      <h1 *ngIf="isLogoLoaded" class="library__title" data-automation-id="header-title">{{title}}</h1>
    </a>
  </div>

  <app-language-switcher></app-language-switcher>
  <app-onetrust-footer-button></app-onetrust-footer-button>

  <div class="header-top__btn-container">
    <app-help-button></app-help-button>
    <app-library-info-button></app-library-info-button>
  </div>

  <div class="header-top__user">
    <div class="separator"></div>
    <app-toggle-bookmarks-button></app-toggle-bookmarks-button>
    <app-login-button></app-login-button>
  </div>
</section>
