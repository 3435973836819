<div class="header-container" [class.fixed-top]="fixedHeader" data-automation-id="header-container" id="header" #header >
  <app-cookie-notification *ngIf="!seen && !featureOneTrustBanner" class="cookie-container"></app-cookie-notification>
  <app-header-announcement-v2></app-header-announcement-v2>
  <app-global-header-container (skipTo)="skipToElement($event)"></app-global-header-container>
</div>

<div class="content-container container-fluid"
    [style.padding-top]="fixedHeader ? header.getBoundingClientRect().height + 'px' : 'unset'"
    [class.pb-0]="!isBookshelfDisplayed"
    [class.h-100]="fixedHeader"
    [ngClass]="pageName"
    data-automation-id="root-page-content"
    tabindex="-1"
    #root
    #main>
  <router-outlet (activate)="root.focus()" #outlet="outlet"></router-outlet>
</div>

<app-bookshelf [(isDisplayed)]="isBookshelfDisplayed" class="fixed-bottom"></app-bookshelf>
<app-inspire-user-panel *ngIf="isPanelShown" cdkTrapFocus></app-inspire-user-panel>
<app-library-info-widget *ngIf="isLibraryInfoShown" cdkTrapFocus></app-library-info-widget>
<app-global-sidebar *ngIf="isGlobalSidebarVisible"></app-global-sidebar>
<app-wizard-ai *featureToggle="'DIS-31589'"></app-wizard-ai>
