<div class="bulk-hold-modal-content p-0 p-sm-4" data-automation-id="bulk-hold-modal-container">
  <app-modal-header
    title="{{'bulkPlaceHolds' | translate}}"
    titleId="bulk-hold-modal-title"
    closeId="bulk-hold-modal-close-btn"
    [closeDisabled]="!isSafeToClose()"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body pb-1" data-automation-id="bulk-hold-modal-body">
    <app-ineligible-for-bulk-hold-banner
      [ineligibleFormatGroups]="unavailableFormatGroups$ | async">
    </app-ineligible-for-bulk-hold-banner>
    <form [formGroup]="bulkHoldForm" class="pickup-location-form m-0">
      <div class="col-12 col-md-6 col-lg-5 p-0">
        <app-pickup-location-select
          formControlName="pickupLocation"
          [pickupLocations]="pickupLocations"
          [selectedLocationCode]="selectedLocationCode"
          [dataAutomationId]="'bulk-hold-pickup-location-select'">
        </app-pickup-location-select>
      </div>
      <app-bulk-hold-table
        [formatGroups]="availableFormatGroups"
        [selectedFormatGroups]="selectedFormatGroups"
        (formatChange)="onFormatChange($event)"
        [bulkHoldSubmitState]="bulkHoldSubmitState"
        [processingFgId]="bulkHoldSubmitState.processingFgId"
        (onRemove)="onRemoveItem($event)">
      </app-bulk-hold-table>
    </form>
  </div>
  <div class="modal-footer justify-content-end">
    <button (click)="onClose()"
            *ngIf="isSafeToClose(); else stopProcessing"
            #cancelButton
            class="ins-buttons button-secondary flint mr-3"
            data-automation-id="bulk-hold-modal-cancel-btn">
      {{ 'bulkHoldClose' | translate }}
    </button>
    <ng-template #stopProcessing>
      <button (click)="onStop()"
              class="ins-buttons button-secondary flint mr-3"
              data-automation-id="bulk-hold-modal-stop-btn">
        {{ 'bulkHoldStop' | translate }}
        <span *ngIf="bulkHoldSubmitState.stopping" class="d-flex ml-2">
          <span class="spinner-border text-primary" data-automation-id="hold-submit-state-stopping">
            <span class="sr-only">{{ 'bulkHoldStopping' | translate }}</span>
          </span>
        </span>
      </button>
    </ng-template>

    <button (click)="onSubmit()"
            class="ins-buttons button-primary flint"
            data-automation-id="bulk-hold-modal-confirm-btn"
            [attr.aria-disabled]="isSubmitDisabled()"
            [class.button-disabled]="isSubmitDisabled()"
            type="button">
      {{ 'bulkHoldConfirmAndSubmit' | translate }}
    </button>
  </div>
</div>
