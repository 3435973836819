import { Component, Input, OnInit } from '@angular/core';
import { faLightbulbOn, faUsers } from '@fortawesome/pro-light-svg-icons';
import { Cover } from '../../../../models/cover';
import { AgentOrConceptData, AgentTypes, Entity, EntityTypes } from '../../../models/entity';

@Component({
  selector: 'app-agent-or-concept-card',
  templateUrl: './agent-or-concept-card.component.html',
  styleUrls: ['./agent-or-concept-card.component.scss']
})
export class AgentOrConceptCardComponent implements OnInit {
  @Input() public entity: Entity;
  @Input() public showAuthorBio = true; // can be removed when issue with multiple author bios on one page is resolved
  public readonly entityTypes = EntityTypes;
  public agentOrConcept: AgentOrConceptData;
  public showBiography = false;
  public coverConfig: Cover;
  public conceptIcon = faLightbulbOn;
  public agentIcon = faUsers;

  constructor() {
  }

  public ngOnInit() {
    this.agentOrConcept = {
      id: this.entity.id,
      entityType: this.entity.entityType,
      title: this.entity.label,
    };
    this.showBiography = this.entity.entityType === EntityTypes.AGENT && this.entity.type === AgentTypes.PERSON;
    if (this.showBiography) {
      this.coverConfig = {
        type: this.entity.entityType,
        coverUrl: this.entity.coverUrl
      };
    }
  }
}
