import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ListItemEntity, ListWithItemsCount } from '../../../list/models/list';
import { SelectListTrigger } from '../../../list/models/select-list';

@Component({
  selector: 'app-select-list-button',
  templateUrl: './select-list-button.component.html',
  styleUrls: ['./select-list-button.component.scss'],
})
export class SelectListButtonComponent {
  @Input() public buttonClass: string;
  @Input() public label: string;
  @Input() public listItemEntities: ListItemEntity[];
  @Input() public lists: ListWithItemsCount[];
  @Input() public listIdsSelected: string[];
  @Input() public canCreateNew: boolean;
  @Input() public trigger?: SelectListTrigger;
  @Input() public multiselect = false;
  @Input() public disabled = false;
  @Input() public confirmDelete?: boolean;
  @Output() public onDone = new EventEmitter<void>();
  @Output() public onCancel = new EventEmitter<void>();

  public selectListOpened: boolean;

  public setDropdownOpened(opened: boolean) {
    this.selectListOpened = opened;
  }

  public toggleDropdown(dropdown: NgbDropdown): void {
    if (!this.disabled && !this.selectListOpened) {
      dropdown.open();
    } else {
      dropdown.close();
    }
  }
}
