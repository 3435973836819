<div class="card mb-6 py-4 px-4" data-automation-id="agent-or-concept-card">
  <div class="row">
    <div class="col-auto" *ngIf="showBiography && coverConfig.coverUrl">
      <div class="cover-container">
        <app-cover
          [config]="coverConfig"
          data-automation-id="agent-or-concept-card-image"
          size="medium">
        </app-cover>
      </div>
    </div>
    <div class="col">
      <div class="row section" tabindex="-1" data-automation-id="agent-or-concept-card-focusable">
        <div class="col data-container pl-1 pr-1 pr-sm-2 pl-sm-3">
          <div class="card-entity-type" [ngSwitch]="entity.entityType" data-automation-id="agent-or-concept-card-entity-type">
            <span *ngSwitchCase="entityTypes.AGENT" translate>
              <fa-icon [icon]="agentIcon" [classes]="['card-entity-type-icon']"></fa-icon>
              entityCardContributor
            </span>
            <span *ngSwitchCase="entityTypes.CONCEPT" translate>
              <fa-icon [icon]="conceptIcon" [classes]="['card-entity-type-icon']"></fa-icon>
              entityCardTopic
            </span>
          </div>
          <h2 class="card-title mb-3">
            <a [routerLink]="['/search', 'card']"
               data-automation-id="agent-or-concept-card-title"
               [queryParams]="{id: entity.id, entityType: entity.entityType}">
              {{ agentOrConcept.title }}</a>
          </h2>
          <div class="card-description mb-3" data-automation-id="agent-or-concept-card-biography" *ngIf="showBiography && showAuthorBio">
            <app-syndetics-unbound-author-bio [isbn]="entity.bookIsbn"></app-syndetics-unbound-author-bio>
          </div>
          <div class="card-see-more">
            <a [routerLink]="['/search', 'card']"
               data-automation-id="agent-or-concept-card-see-more"
               [queryParams]="{id: entity.id, entityType: entity.entityType}"
               translate>entityCardSeeMore</a>
          </div>
        </div>
        <div class="col-auto toolbar-container pl-0 pr-1 pr-sm-2 pl-sm-2">
          <app-toolbar-line [data]="agentOrConcept" [entity]="entity"></app-toolbar-line>
        </div>
      </div>
    </div>
  </div>
</div>
