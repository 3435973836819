import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'merlin-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Output("openRecommendationsDialogEvent") openChatDialogEvent = new EventEmitter();
  @Output("openQuizDialogEvent") openQuizDialogEvent = new EventEmitter();
  @Output("closeMerlinModalDialogEvent") closeMerlinModalDialogEvent = new EventEmitter();


  public onRecommendationButtonPressed() {
    this.openChatDialogEvent.emit();
  }

  public onQuizButtonPressed(): void {
    this.openQuizDialogEvent.emit();
  }

  public onCloseDialogPressed() {
    this.closeMerlinModalDialogEvent.emit();
  }

}
