import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-bookshelf-card-action-item',
  templateUrl: './bookshelf-card-action-item.component.html',
  styleUrls: ['./bookshelf-card-action-item.component.scss'],
})
export class BookshelfCardActionItemComponent {
  @Input() public messageKey: string;
  @Input() public icon: IconDefinition;
}
