<div class="d-flex align-items-end">
  <button class="btn tab-pagination" *ngIf="showArrowButton()" (click)="moveLeft()" [attr.aria-label]="'moveLeft' | translate">
    <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
  </button>
  <drag-scroll scrollbar-hidden drag-scroll-y-disabled [snap-offset]="20"
               class="nav nav-tabs tab-header" ngbNav #nav="ngbNav" [(activeId)]="activeId"
               (navChange)="onTabChange($event)">
    <ng-container [ngbNavItem]="idx" *ngFor="let materialTab of materialTabs; let idx=index; trackBy: trackById">
      <a ngbNavLink class="tab-label" drag-scroll-item>
        <div class="d-flex flex-column align-items-center tab-label-content" data-automation-id="rollups-tab"
             [class.checked-out]="!availableStatuses.includes(availabilityUpdates && availabilityUpdates[idx]?.status?.general)"
             [class.alwaysAvailable]="formatByCode?.alwaysAvailable">
             <ng-container *ngIf="materialTab?.name; else emptyLabel">
            <div class="label" data-automation-id="rollups-tab-title">
              {{ materialTab.translationKey ? (materialTab.translationKey | translate) : materialTab.name }}
            </div>
          </ng-container>
          <ng-template #emptyLabel>
            <div class="label" data-automation-id="rollups-tab-title" translate>other</div>
          </ng-template>
          <app-rollup-tabs-content-status
            [materialTab]="materialTab"
            data-automation-id="tab-status"
            [capitalize]="true"
            [availabilityStatus]="availabilityUpdates && availabilityUpdates[idx]?.status?.general">
          </app-rollup-tabs-content-status>
        </div>
      </a>
      <ng-template ngbNavContent>
        <app-rollup-tabs-content
          [materialTab]="materialTab"
          [hasEcontentIssues]="hasEcontentIssues[idx]"
          [availabilityUpdateInfo]="availabilityUpdates && availabilityUpdates[idx]"
          [queryParams]="queryParams"
          [fgId]="fgId"
          [fgTitle]="fgTitle"
          [ariaDescribedBy]="ariaDescribedBy"
          (getIt$)="getIt$.emit($event)"
          (placeHold$)="placeHold$.emit($event)"
          (checkLocationAndEditionInfo$)="checkLocationAndEditionInformation(idx)"
          [language]="language">
        </app-rollup-tabs-content>
      </ng-template>
    </ng-container>
  </drag-scroll>
  <button class="btn tab-pagination" *ngIf="showArrowButton()" (click)="moveRight()" [attr.aria-label]="'moveRight' | translate">
    <fa-icon class="arrow-icon" [icon]="arrowIconRight"></fa-icon>
  </button>
</div>
<div [ngbNavOutlet]="nav"></div>
