import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { ViewType } from '../../entity/models/view';
import { LOCAL_STORAGE } from '../../models/local-storage';
import { CustomDomainLoader } from '../../services/custom-domain-loader';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { WindowRefService } from '../../services/window-ref.service';
import { WebCustomizationInvertedLicenseMapping, WebCustomizationRankedLicenses } from '../models/license';
import { CustomerI, NetworkPage, Page, PAGE_STATUS } from '../models/page';
import { AudienceSettings, GridSettings, PageSettings, Settings, SiteTheme, } from '../models/settings';
import { ElementsV2Service } from './elements-v2.service';
import { License } from 'app/models/license';

@Injectable({
  providedIn: 'root'
})
export class DiscoverV2BffService {
  private readonly baseURL = 'api/discover-v2-bff';
  private allowGrid: boolean = false;
  private viewType: ViewType = ViewType.LIST;
  public localStorage: any;
  public $page = new BehaviorSubject<Page | null>(null);
  private settings: Settings;
  private pageSize: number = 20;
  public customer: CustomerI = null;
  public featureAdultTheming: boolean = false

  constructor(
    private http: HttpClient,
    private readonly configLoader: ConfigurationLoader,
    private readonly elementsV2Service: ElementsV2Service,
    private windowRef: WindowRefService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly customDomainLoader: CustomDomainLoader,
  ) {
    this.localStorage = this.windowRef.nativeWindow().localStorage;

    const domain = customDomainLoader.domain;
    const env = domain.split('.') ?? [];

    const flags = Object.keys(this.featureToggleService.getToggles()) as string[];
    this.customer = {
      customerDomain: domain,
      env,
      flags
    };
  }

  public getPage(): Observable<Page> {
    return this.$page;
  }

  public getGridOptions() {
    this.viewType = this.localStorage.getItem(LOCAL_STORAGE.VIEW_TYPE) ?? this.viewType;
    return { allowGrid: this.allowGrid, viewType: this.viewType };
  }

  private setupGrid(settings: PageSettings) {
    const { grid_display_option_id } = settings;
    switch (grid_display_option_id) {
      case GridSettings.ALLOW_GRID:
        this.allowGrid = true;
        break;
      case GridSettings.DEFAULT_GRID:
        this.localStorage.setItem(LOCAL_STORAGE.PAGE_SIZE, this.pageSize.toString());
        this.localStorage.setItem(LOCAL_STORAGE.VIEW_TYPE, ViewType.GRID);
        this.allowGrid = true;
        break;
      case GridSettings.DISABLE_GRID:
        this.localStorage.setItem(LOCAL_STORAGE.VIEW_TYPE, ViewType.LIST);
        this.allowGrid = false;
        break;
      default:
        break;
    }
  }

  private async setupTheme(settings: PageSettings, license: string) {
    const { audience_theme_id } = settings;

    const isPremium = [License.DISCOVER_PREMIUM, License.DISCOVER_CONSORTIA].includes(license as License);

    if (audience_theme_id === AudienceSettings.KIDS_CATALOG) {
      // Load Fonts and Custom Themes
      await this.elementsV2Service.loadStyle(settings.theme.theme_selected, settings?.theme?.color);
    } else {
      if (this.featureAdultTheming && settings?.theme?.color && isPremium) {
        // Load Roboto & Domine Font for Vega Classic with bg color
        await this.elementsV2Service.loadStyle(
          SiteTheme.VEGA_CLASSIC,
          settings?.theme?.color
        );
      } else {
        // Load Roboto & Domine Font for Vega Classic
        await this.elementsV2Service.loadStyle(SiteTheme.VEGA_CLASSIC);
      }
    }
  }

  private setupSettings(settings: PageSettings) {
    const { audience_theme_id } = settings;
    const isKidsCatalog = audience_theme_id === AudienceSettings.KIDS_CATALOG;

    this.settings = {
      ...settings,
      search: {
        allowGrid: this.allowGrid,
        viewType: this.localStorage.getItem(LOCAL_STORAGE.VIEW_TYPE) ?? this.viewType,
      },
      audience: {
        isKidsCatalog
      }
    };
  }

  public loadPage(): Observable<Page> {
    const toggles = this.featureToggleService.getToggles();
    this.featureAdultTheming = toggles['VE-4281_2024-03-11_HomePageTheme'];
    const licenseKey = WebCustomizationRankedLicenses.filter(license => toggles[license])[0];
    const license = WebCustomizationInvertedLicenseMapping.get(licenseKey);
    return this.http.get<Page>(`${this.baseURL}/page`, {
      params: {
        status: PAGE_STATUS.LIVE,
        hideExpiredContent: true,
        limitScheduledContent: true,
        contentScope: true,
        pageSize: 100,
        passVegaProgramStatus:true,
        hideVegaProgramEvents:true,
        prefetchVegaProgramEvents:true
      },
      headers: {
        ...(license && { 'iii-customer-license': license })
      }
    }).pipe(
      tap(async (page: Page) => {
        const { settings } = page;
        await this.setupTheme(settings, license);
        this.setupGrid(settings);
        this.setupSettings(settings);
        const formatedPage: Page = { ...page, settings: this.settings };
        this.$page.next(formatedPage);
        return formatedPage;
      }),
      catchError(() => {
        return of(undefined);
      }));
  }

  public getPageData(): Promise<NetworkPage> {
    return this.loadPage().toPromise();
  }
}
