<div class="user-tools" [class.position-absolute]="!fixedHeader" [class.position-fixed]="fixedHeader" role="navigation" aria-label="User profile" @present
     data-automation-id="user-panel"
     id="user-panel"
     [style.top]="top"
     [style.left]="left"
     [style.right]="right">
  <ng-container *ngIf="(isPatronAuthorized || isStaffAuthorized); else signIn">
    <app-inspire-my-account-quick-view
      [checkoutsTotal$]="checkoutsTotal$"
      [holdsTotal$]="holdsTotal$"
      [moneyOwed$]="moneyOwed$"
      [user$]="user$"
      [userNicknameOrName$]="userNicknameOrName$"
      [isStaffAuthorized]="isStaffAuthorized"
      [staffUser$]="staffUser$"
      [staffUserName$]="staffUserName$"
      [guestLibraryEmail]="guestLibraryEmail"
      [hideInfoSection]="hideUserInfoSection"
      [customLinks]="customLinks"
      (onSignOut)="logOut()"
      (onClose)="closeUserPanel()"
      ></app-inspire-my-account-quick-view>
  </ng-container>
  <ng-template #signIn>
    <app-inspire-sign-in
      [libraryName]="libraryName"
      [loginType]="loginType"
      [signUpCustomLink]="customLinks?.signUpUrl"
      (onSignIn)="logIn()"
      (onClose)="closeUserPanel()"></app-inspire-sign-in>
  </ng-template>
</div>
