<ng-container *ngIf="isFeatureBulkHoldsEnabled">
  <div *ngIf="isAuthorizedInKeycloak$ | async" class="d-flex mt-md-1 flex-row align-items-start fixed-bottom-container">
    <ng-container *ngIf="(selectedItems$ | async) as selectedItems">

      <!-- Select All / Deselect All -->
      <button class="ins-buttons button-secondary flint bulk-select-button"
              data-automation-id="bulk-toggle-select-all-button"
              [attr.aria-pressed]="isAllItemsSelected(selectedItems)"
              (click)="onBulkSelectionChange(selectedItems.length !== items.length)">
        <span>{{ 'bulkSelectAll' | translate }}</span>
        <span class="icon ml-2" [ngClass]="isAllItemsSelected(selectedItems) ? 'fas fa-check-square' :  'far fa-square'" aria-hidden="true"></span>
      </button>

      <!-- Selected Items Counter -->
      <div aria-live="polite" role="status" class="bulk-select-count mx-3 py-1 text-nowrap" data-automation-id="bulk-select-count">
        <strong>{{ 'bulkItemsCountSelected' | translate: {itemsCount: selectedItems.length} }}:</strong>
      </div>

      <!-- Bulk Place Hold -->
      <button class="ins-buttons no-border button-primary flint bulk-action-button"
              [class.button-disabled]="!selectedItems.length"
              [attr.aria-disabled]="!selectedItems.length"
              (click)="onBulkPlaceHold(selectedItems)"
              data-automation-id="bulk-holds-button">
        <span>{{ 'bulkPlaceHolds' | translate }}</span>
      </button>
    </ng-container>
  </div>
</ng-container>
