import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemDetail, ShowcaseDetail } from '../../models/custom-events';
import { CustomerI, Page } from '../../models/page';
import { DiscoverV2BffService } from '../../service/discover-v2-bff.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-page-preview-v2',
  templateUrl: './home-page-preview-v2.component.html',
})
export class HomePagePreviewV2Component implements OnInit, OnDestroy {
  public page: Page = null;
  private readonly subscriptions = new Subscription();
  public customer: CustomerI = null;
  public translations: any = {};

  constructor(
    private readonly router: Router,
    private discoverV2BffService: DiscoverV2BffService,
    private readonly cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.customer = discoverV2BffService.customer;
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.discoverV2BffService.$page.subscribe((page) => {
      if (page) {
        this.page = page;
      }
      this.cdr.detectChanges();
    }));

    this.translations = {
        showCaseViewAll: this.translateService.instant('showCaseViewAll')
    };
  }

  public ngOnDestroy(): void {
    this.page = null;
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:itemClicked', ['$event'])
  public onItemClicked(event: CustomEvent) {
    const {id, entityType}: ItemDetail = event.detail;
    this.router.navigate(['/search', 'card'], {queryParams: {id, entityType}});
  }

  @HostListener('window:showcaseClicked', ['$event'])
  public onShowcaseClicked(event: CustomEvent) {
    const {id}: ShowcaseDetail = event.detail;

    if (id === null || id === undefined) {
      return;
    }

    this.router.navigate(['/library-list', 'showcase', id]);
  }
}
