import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'common/shared.module';
import { BulkSelectPanelComponent } from './components/bulk-select-panel/bulk-select-panel.component';
import * as bulkSelect from './reducers/bulk-select.reducer';
import { StoreModule } from '@ngrx/store';
import { BulkSelectCheckboxComponent } from './components/bulk-select-checkbox/bulk-select-checkbox.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';

const COMPONENTS = [
  BulkSelectCheckboxComponent,
  BulkSelectPanelComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(bulkSelect.featureKey, bulkSelect.reducer),
    FeatureToggleModule,
  ],
  exports: [
    ...COMPONENTS,
  ],
})
export class BulkSelectModule { }
