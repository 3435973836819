import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';

import { AppModule } from 'app';
import { environment } from 'environments/environment';
import { LocaleHelper } from 'shared/locale';

import frData from '@angular/common/locales/fr';
import frDataExtra from '@angular/common/locales/extra/fr';

import esData from '@angular/common/locales/es-US';
import esDataExtra from '@angular/common/locales/extra/es-US';

import enData from '@angular/common/locales/en';
import enDataExtra from '@angular/common/locales/extra/en';

import caData from '@angular/common/locales/ca-ES-valencia';
import caDataExtra from '@angular/common/locales/extra/ca-ES-valencia';

import plData from '@angular/common/locales/pl';
import plDataExtra from '@angular/common/locales/extra/pl';

import isData from '@angular/common/locales/is';
import isDataExtra from '@angular/common/locales/extra/is';

import miData from '@angular/common/locales/mi';
import miDataExtra from '@angular/common/locales/extra/mi';

import esSpanishData from '@angular/common/locales/es'; // es-ES
import esSpanishDataExtra from '@angular/common/locales/extra/es'; // es-ES

import svData from '@angular/common/locales/sv'; // sv-SE
import svDataExtra from '@angular/common/locales/extra/sv'; // sv-SE

registerLocaleData(frData, 'fr', frDataExtra);
registerLocaleData(esData, 'es-US', esDataExtra);
registerLocaleData(enData, 'en', enDataExtra);
registerLocaleData(caData, 'ca-ES', caDataExtra);
registerLocaleData(plData, 'pl-PL', plDataExtra);
registerLocaleData(isData, 'is-IS', isDataExtra);
registerLocaleData(miData, 'mi-MI', miDataExtra);
registerLocaleData(esSpanishData, 'es-ES', esSpanishDataExtra);
registerLocaleData(svData, 'sv-SE', svDataExtra);

if (environment.production) {
  enableProdMode();
}

LocaleHelper.getLocaleAndContext()
  .then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(console.error);
  });
