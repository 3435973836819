<img *ngIf="item?.logoUrl || item?.params?.imageUrl"
     [src]="item?.logoUrl || item?.params?.imageUrl"
     class="custom-passthrough-image"
     data-automation-id="custom-passthrough-image"
     [ngClass]="{'custom-passthrough-image-button': item?.params?.hideSystemName}"
     alt="{{item.params.name || item.params.systemName}}"/>
<!--Hide with css when hideSystemName is true-->
<span *ngIf="!item?.params?.hideSystemName"
      class="custom-passthrough-system-name text-truncate"
      tippy="{{item.params.name || item.params.systemName}}"
      data-automation-id="custom-passthrough-system-name">
    {{item.params.name || item.params.systemName}}
  </span>
