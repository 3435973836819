<div [ngClass]="{'search-card-disabled': formatGroup.isUnresolved}" class="card py-4 px-4"
     data-automation-id="search-card">
  <div class="row no-gutters card-main-content">
    <div class="cover-wrapper">
      <div class="card-checkbox d-flex flex-row">
        <div class="mb-1" *ngIf="bulkFeatureKey">
          <app-bulk-select-checkbox
            [bulkFeatureKey]="bulkFeatureKey"
            [item]="formatGroup"
            [ariaTitleId]="ariaTitleId">
          </app-bulk-select-checkbox>
        </div>

        <div class="cover-container pr-3">
          <app-cover
            [config]="coverConfig"
            [title]="title"
            [author]="author"
            [materialType]="materialType"
            data-automation-id="search-card-image"
            size="large">
          </app-cover>
        </div>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="row section" #searchCard tabindex="-1" data-automation-id="search-card-focusable">
        <div class="col data-container">
          <ng-container *ngIf="!isListView; else listViewTemplate">
            <h2 class="card-title mb-2" [id]="ariaTitleId">
              <ng-container *ngIf="formatGroup?.isUnresolved; else linkTitle">
                {{ formatGroup.title }}
              </ng-container>
              <ng-template #linkTitle>
                <a
                  [routerLink]="['/search', 'card']"
                  data-automation-id="search-card-title"
                  [queryParams]="{id: formatGroup.id, entityType: entityTypes.FORMAT_GROUP}"
                  (keydown)="onSaveFocus($event)"
                  (click)="onSaveFocus($event)">
                  {{ formatGroup.title }}
                </a>
              </ng-template>
            </h2>
          </ng-container>
          <ng-template #listViewTemplate>
            <h3 class="card-title mb-2" [id]="ariaTitleId">
              <ng-container *ngIf="formatGroup?.isUnresolved; else linkTitle">
                {{ formatGroup.title }}
              </ng-container>
              <ng-template #linkTitle>
                <a
                  [routerLink]="['/search', 'card']"
                  data-automation-id="search-card-title"
                  [queryParams]="{id: formatGroup.id, entityType: entityTypes.FORMAT_GROUP}"
                  (keydown)="onSaveFocus($event)"
                  (click)="onSaveFocus($event)">
                  {{ formatGroup.title }}
                </a>
              </ng-template>
            </h3>
          </ng-template>
          <div *ngIf="formatGroup.primaryAgent?.label" class="author mb-2">
            <a [routerLink]="['/search', 'card']"
               [queryParams]="{id: formatGroup.primaryAgent.id, entityType: 'Agent'}"
               data-automation-id="author"
               class="author-name"
               (keydown)="onSaveFocus($event)"
               (click)="onSaveFocus($event)">
              <span>{{ formatGroup.primaryAgent.label }}</span>
            </a>
          </div>
          <div class="date-range mb-2" data-automation-id="search-card-info">
            <span class="card__publication-info">
              <ng-container *ngIf="formatGroup.publicationDate">
                {{ 'publicationInfo' | translate }}: {{ formatGroup.publicationDate }}
              </ng-container>
              <ng-container *ngIf="formatGroup.publicationDate && formatGroup.language"> | </ng-container>
              <ng-container *ngIf="formatGroup.language">{{ language }}</ng-container>
            </span>
          </div>
          <div *ngIf="formatGroup?.seriesTitle" class="series-info mb-2">
            <a href=""
               data-automation-id="series-title"
               (keydown.enter)="$event.preventDefault(); onSeriesTypeSearch($event, formatGroup.seriesTitle, formatGroup.seriesTitleSearch)"
               (click)="$event.preventDefault(); onSeriesTypeSearch($event, formatGroup.seriesTitle, formatGroup.seriesTitleSearch)"
               class="link">
              <fa-icon [icon]="booksIcon" class="mr-1"></fa-icon>
              <span>{{ formatGroup.seriesTitle }}</span>
            </a>
          </div>
          <div class="not-found-words mt-2" *ngIf="notFoundWords.length" data-automation-id="not-found-words">
            {{ 'missingWords' | translate }} <span class="mr-1" *ngFor="let word of notFoundWords">{{ word }}</span>
          </div>
        </div>
        <div *ngIf="!formatGroup.isUnresolved" class="col-auto">
          <div class="d-flex flex-column flex-lg-row align-items-center align-items-md-baseline">
            <app-share-icons-button
              *ngIf="individualResultOnSrp"
              [url]="'/search/card?'"
              [title]="formatGroup?.title"
              [author]="formatGroup?.primaryAgent?.label"
              [queryParams]="{id: formatGroup.id, entityType: entityTypes.FORMAT_GROUP}">
            </app-share-icons-button>
            <app-bookmark-button-container
              [ariaDescribedBy]="ariaTitleId"
              [fgOrEntity]="formatGroup"
              [recordId]="selectedTabRecordId"
              [tabName]="selectedTabName">
            </app-bookmark-button-container>
            <app-entity-options-button
              [entityType]="formatGroup.entityType"
              [resourceData]="resourceData"
              [editionsData]="editionsData"
              [ariaDescribedBy]="ariaTitleId">
            </app-entity-options-button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isWiderThanMd">
        <div class="col not-found-words">
          <ng-container *ngTemplateOutlet="suppressedItemLabelVIew"></ng-container>
        </div>
      </div>
      <div class="row" *ngIf="isWiderThanMd">
        <ng-container *ngTemplateOutlet="tabsView"></ng-container>
      </div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="!isWiderThanMd">
    <div class="col not-found-words mb-2">
      <ng-container *ngTemplateOutlet="suppressedItemLabelVIew"></ng-container>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="!isWiderThanMd">
    <ng-container *ngTemplateOutlet="tabsView"></ng-container>
  </div>
</div>

<ng-template #suppressedItemLabelVIew>
  <span class="search-card-label mb-2" *ngIf="formatGroup?.isUnresolved">
    {{ 'suppressedItemLabel' | translate: {recordId: formatGroup?.materialTabs[0]?.recordIds[0]} }}
  </span>
</ng-template>

<ng-template #tabsView>
  <div class="col">
    <app-overdrive-and-sierra-availability-container
      *ngIf="!formatGroup.isUnresolved"
      [containerData]="formatGroup.materialTabs"
      [resourceId]="formatGroup.id">
      <ng-template let-availabilityUpdates="availabilityUpdates">
        <app-rollup-tabs
          [materialTabs]="formatGroup.materialTabs"
          [availabilityUpdates]="availabilityUpdates"
          [queryParams]="queryParams"
          [fgId]="formatGroup.id"
          [fgTitle]="formatGroup.title"
          data-automation-id="rollup-tabs"
          (getIt$)="getIt$.emit($event)"
          (placeHold$)="placeHold$.emit($event)"
          [ariaDescribedBy]="ariaTitleId"
          [language]="language">
        </app-rollup-tabs>
      </ng-template>
    </app-overdrive-and-sierra-availability-container>
  </div>
</ng-template>
