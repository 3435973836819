import { NLB_CUSTOMER_INTEGRATION_HIDDEN_FEATURES } from './nlb/configs/nlb-common.config';

export enum CustomerIntegrationType {
  NLB = 'NLB',
}

export interface CustomerIntegrationLinks {
  accountUrl: string;
  checkoutsPhysicalUrl: string;
  holdsPhysicalUrl: string;
  checkoutsElectronicUrl: string;
  holdsElectronicUrl: string;
  finesAndFeesUrl: string;
  bookmarksUrl: string;
  signUpUrl: string;
  borrowAndDeliverUrlTemplate: string;
}

export interface CustomerIntegrationHiddenFeatures {
  accountTab: boolean;
  bookmarksTab: boolean;
  readingHistoryTab: boolean;
  userInfoSection: boolean;
}

export const CustomerIntegrationHiddenFeaturesMap: {[key in CustomerIntegrationType]?: CustomerIntegrationHiddenFeatures} = {
  [CustomerIntegrationType.NLB]: NLB_CUSTOMER_INTEGRATION_HIDDEN_FEATURES,
};

