import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATEPICKER_VALIDATORS } from '@angular/material/datepicker';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LocationWithName } from 'app/models/locations';
import { LocalizationService } from 'app/services/localization.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { loadTabHold, updateShouldCloseModal } from 'user/actions/user-profile.actions';
import { BorrowByMailConfiguration, UserAddress } from 'user/models/user';
import { getErrorMessage, getShouldCloseModal, isLoading, UserState } from 'user/reducers/user.reducer';
import { GetItForm } from 'user/models/get-it';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { IlsType } from 'shared/models/ilsType';

export const dateFormatFactory = (localizationService: LocalizationService) => {
  const finalDateFormat = (localizationService.dateFormat).toUpperCase();

  return {
    parse: {
      dateInput: finalDateFormat,
    },
    display: {
      dateInput: finalDateFormat,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: finalDateFormat,
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
};

@Component({
  selector: 'app-get-it-dialog',
  templateUrl: './get-it-dialog.component.html',
  styleUrls: ['./get-it-dialog.component.scss'],
  animations: [
    trigger('collapsible', [
      state('void', style({ height: '0' })),
      state('*', style({ height: '*' })),
      transition('* <=> *', animate('200ms')),
    ]),
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useFactory: dateFormatFactory, deps: [LocalizationService] },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetItDialogComponent implements OnInit {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public selectedLocationCode: string;
  @Input() public volume: string;
  @Input() public itemVolume: string;
  @Input() public borrowByMailConfiguration?: BorrowByMailConfiguration;
  @Input() public holdExpirationDefault: number;
  @Input() public holdActivationDateSetting: boolean;
  @Input() public userAddress: UserAddress;
  @Input() public errorMessage: string;
  public dateFormat = this.dateFormats.display.dateInput;
  public today = new Date();
  public calendarIcon = faCalendarWeek;
  public windowClose = faTimes;
  public getItForm: FormGroup<GetItForm>;
  public ilsType: string;
  protected readonly IlsType = IlsType;
  public formDataSubject = new Subject<any>();
  private readonly subscription = new Subscription();
  public isLoading$: Observable<boolean>;

  constructor(
    @Inject(MAT_DATE_FORMATS) private dateFormats: any,
    public readonly activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly store: Store<UserState>,
    private readonly configLoader: ConfigurationLoader,
  ) {}

  public ngOnInit() {
    this.getItForm = this.fb.group<GetItForm>({
      pickupLocation: new FormControl(this.selectedLocationCode, Validators.required),
      hasExpirationDate: new FormControl(false),
      borrowByMail: new FormControl(false)
    });

    this.ilsType = this.configLoader.ilsType;

    if (!this.pickupLocations?.length) {
      this.pickupLocation.disable();
    }

    if (this.ilsType === IlsType.polaris && this.holdActivationDateSetting) {
      this.getItForm.addControl('neededFrom', this.initializeDateFormControl());
    }

    if (this.ilsType === IlsType.sierra) {
      this.getItForm.addControl('neededBy', this.initializeDateFormControl(this.holdExpirationDefault));
    }

    this.isLoading$ = this.store.select(isLoading);
  }

  public onClose() {
    this.formDataSubject.error({});
    this.store.dispatch(updateShouldCloseModal({ errorMessage: '' }));
    this.activeModal.dismiss();
  }

  public onSubmit() {
    this.store.dispatch(loadTabHold());
    this.formDataSubject.next(this.getItForm.value);
    this.subscription.add(
      this.store.select(getShouldCloseModal).subscribe((shouldCloseModal) => {
        if (shouldCloseModal) {
          this.activeModal.close();
        }
      }),
    );

    this.subscription.add(
      this.store.select(getErrorMessage).subscribe((message) => {
        if (message) {
          this.errorMessage = message;
        }
      }),
    );
  }

  public onChange(checked: boolean) {
    this.getItForm.patchValue({
      hasExpirationDate: checked,
    });
  }

  public onBorrowByMailChange(checked: boolean) {
    this.getItForm.patchValue({
      borrowByMail: checked,
    });
    if (checked) {
      this.pickupLocation.disable();
    } else {
      this.pickupLocation.enable();
    }
  }

  public get borrowByMail(): AbstractControl {
    return this.getItForm.get('borrowByMail');
  }

  public get pickupLocation(): AbstractControl {
    return this.getItForm.get('pickupLocation');
  }

  private initializeDateFormControl(holdExpirationDefaultValue = 0): FormControl<Date> {
    return new FormControl(new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() + holdExpirationDefaultValue,
    ), MAT_DATEPICKER_VALIDATORS);
  }
}
