<div class="location-information">
  <table class="table location-table" data-automation-id="loc-info-table">
    <thead data-automation-id="items-table-header">
      <tr>
        <th translate data-automation-id="items-table-header-cell">itemLocation</th>
        <th translate data-automation-id="items-table-header-cell">itemDetails</th>
        <th translate data-automation-id="items-table-header-cell">itemCallNumber</th>
        <th translate data-automation-id="items-table-header-cell">itemStatus</th>
        <th translate data-automation-id="items-table-header-cell">itemDueDate</th>
        <th translate data-automation-id="items-table-header-cell">itemOpacMessage</th>
        <th translate data-automation-id="items-table-header-cell" *ngIf="showSpecialColumn">special</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableItems">
        <td>
          <ng-container *ngIf="item.location; else emptyValue">
            {{item.location}}
          </ng-container>
        </td>
        <td>
          <button
            class="ins-buttons button-primary details"
            data-automation-id="item-details-popup-button"
            [attr.aria-label]="'itemDetailsPopup' | translate"
            [tippy]="'itemDetailsPopup' | translate"
            (click)="showDetails(item)">
            <fa-icon class="info-icon" [icon]="infoCircle"></fa-icon>
          </button>
        </td>
        <td>
          <ng-container *ngIf="item.callNumber; else emptyValue">
            {{item.callNumber}}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="item.dueDate; else notCheckedOut">
            {{ 'checkedOut' | translate }}
          </ng-container>
          <ng-template #notCheckedOut>
            <ng-container *ngIf="item.status; else emptyValue">
              {{item.status}}
            </ng-container>
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="item.dueDate; else emptyValue">
            {{item.dueDate}}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isEmptyMsg(item.opacMessage)">
            {{item.opacMessage}}
          </ng-container>
        </td>
        <td *ngIf="showSpecialColumn">
          <a *ngIf="item.rareContentLink"
             href="{{item.rareContentLink}}"
             class="ins-buttons button-secondary flint"
             data-automation-id="items-table-rare-content-link"
             target="_blank"
             translate>
            specialRequest
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #emptyValue>
  —
</ng-template>
