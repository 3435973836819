<div class="rollup-tabs-content w-100">
  <div class="mb-3">
    <app-checked-and-held-items-banner-container
      [recordIds]="materialTab.recordIds"
      [hasEcontentIssues]="hasEcontentIssues"
      [instanceIds]="instanceIds"
      [vendors]="materialTab?.availability?.vendors"
      [isElectronic]="isElectronic">
    </app-checked-and-held-items-banner-container>
  </div>
  <div class="row justify-content-end">
    <div class="col-12 col-md">
      <app-electronic-item-availability
        *ngIf="isElectronic; else physicalItemTmpl"
        [materialTab]="materialTab"
        [availabilityUpdateInfo]="availabilityUpdateInfo"
        [vendorsLogo]="vendorsLogo">
      </app-electronic-item-availability>

      <ng-template #physicalItemTmpl>
        <app-physical-item-availability [materialTab]="materialTab"
                                        [availabilityStatus]="availabilityUpdateInfo?.status?.general">
        </app-physical-item-availability>
      </ng-template>

      <div class="row mb-3" *ngIf="this.materialTab.holdingsLatestReceived">
        <div class="col-auto rollup-tabs-content-card-col-mw-fixed">
          {{'latestReceived' | translate}}
        </div>
        <div class="w-100 d-lg-none"></div>
        <div class="col" data-automation-id="latest-received">
          <p class="mb-1">{{this.materialTab.holdingsLatestReceived}}</p>
        </div>
      </div>

      <div class="mb-3"
           *ngIf="materialTab.holdingsDisplayStrings?.length || materialTab.indexesDisplayStrings?.length > 0
           || materialTab.supplementsDisplayStrings?.length > 0">
        <app-library-has
          [issuesStrings]="materialTab.holdingsDisplayStrings"
          [supplementsStrings]="materialTab.supplementsDisplayStrings"
          [indexesStrings]="materialTab.indexesDisplayStrings"
        ></app-library-has>
      </div>

      <div class="row mb-3" *ngIf="!isElectronic && (locations?.length || materialTab.hasMoreLocations)">
        <div class="col">
          <ins-rollup-tabs-content-locations [locations]="locations"
                                             [displayAllLocationsLink]="materialTab.hasMoreLocations"
                                             (locationClicked$)="onCheckItemAvailability($event)"
                                             (allLocationsClicked$)="onCheckItemAvailability()">
          </ins-rollup-tabs-content-locations>
        </div>
      </div>
    </div>

    <div class="col-auto tabs-btn flex-column">
      <app-toolbar-buttons
        #getItButton
        class="w-100"
        *ngIf="!materialTab?.availability?.isGetItSuppressed"
        [label]="materialTab.translationKey ? (materialTab.translationKey | translate) : materialTab.name"
        [tabName]="materialTab.name"
        [recordId]="materialTab.recordIds"
        [isElectronicResource]="isElectronic"
        [eResourceUrls]="materialTab?.availability?.urls"
        [availability]="availabilityUpdateInfo?.status"
        [reserve]="availabilityUpdateInfo?.reserve"
        [hasEcontentIssues]="hasEcontentIssues"
        [eVendorAvailabilityPending]="availabilityUpdateInfo?.eVendorAvailabilityPending"
        [resourceId]="fgId"
        [title]="fgTitle"
        [ariaDescribedBy]="ariaDescribedBy"
        (getIt$)="getIt$.emit($event)"
        (placeHold$)="placeHold$.emit($event)"
        (checkLocationAndEditionInfo$)="onCheckEditions()">
      </app-toolbar-buttons>
      <ng-container *ngIf="isNlbCustomer && !isElectronic">
        <app-nlb-borrow-n-deliver class="mt-2" [recordIds]="materialTab.recordIds"></app-nlb-borrow-n-deliver>
      </ng-container>
    </div>
  </div>

  <div class="d-flex flex-nowrap justify-content-between mt-2">
    <a [routerLink]="['/search', 'card']"
       [queryParams]="queryParams"
       (click)="checkLocationAndEditionInfo$.emit()"
       data-automation-id="check-availability-from-other-sources"
       *ngIf="isElectronic && materialTab.checkAvailabilityFromOtherSources; else editionInformationTmpl"
       class="location-link"
    >{{'checkAvailabilityFromOtherSources' | translate}}</a>

    <ng-template #editionInformationTmpl>
      <a [routerLink]="['/search', 'card']"
         [queryParams]="queryParams"
         (click)="checkLocationAndEditionInfo$.emit()"
         data-automation-id="loc-and-edtn-info"
         class="location-link"
         [attr.aria-label]="'editionInformationForTitle' | translate : {title: fgTitle}"
      >{{'viewLocationAndEditionInfoBtn' | translate}}</a>
    </ng-template>

    <span class="ml-1 center-span" *ngIf="availabilityUpdateInfo?.holds || availabilityUpdateInfo?.copies">
      <app-holds-and-copies-message [holds]="availabilityUpdateInfo?.holds"
                                    [copies]="availabilityUpdateInfo?.copies"></app-holds-and-copies-message>
    </span>
  </div>
</div>
