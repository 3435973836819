import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission, UserState } from 'user/reducers/user.reducer';
import { CustomerIntegrationType } from '../../../customer-integration/customer-integration';
import { Entity, FormatGroup, isFormatGroup } from '../../../entity/models/entity';

@Component({
  selector: 'app-bookmark-button-container',
  templateUrl: './bookmark-button-container.component.html',
})
export class BookmarkButtonContainerComponent implements OnInit {
  @Input() public fgOrEntity: FormatGroup | Entity;
  @Input() public ariaDescribedBy = '';
  @Input() public recordId: string;
  @Input() public tabName: string;
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public isNlbCustomer: boolean;
  public isFormatGroup: boolean;

  constructor(
    private readonly configLoader: ConfigurationLoader,
    private readonly store: Store<UserState>,
  ) { }

  ngOnInit(): void {
    this.isNlbCustomer = this.configLoader.customerIntegrationType === CustomerIntegrationType.NLB;
    this.isFormatGroup = isFormatGroup(this.fgOrEntity);
  }
}
