<div class="modal merlin-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Hi ~ I'm The Famous Wizard Vega! It's nice to meet you!</h2>
        <img src="../../../../../assets/close.png" (click)="onCloseDialogPressed()"/>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="icon col-md-2 col-sm-2">
            <img src="../../../../../assets/merlin/hourglass-30.png" alt="Icon 1">
          </div>
          <div class="text col-md-6 col-sm-6">
            Ask me for a recommendation and I can find just the right book for you
          </div>
          <div class="col-md-4 col-sm-4">
            <button class="button btn-primary btn-block merlin-button" (click)="onRecommendationButtonPressed()">
              Recommendations
            </button>
          </div>
        </div>
        <div class="row">
          <div class="icon col-md-2 col-sm-2">
            <img src="../../../../../assets/merlin/hourglass-30.png" alt="Icon 2">
          </div>
          <div class="text col-md-6 col-sm-6">
            Let me teach you about your favorite book and we can test your knowledge!
          </div>
          <div class="col-md-4 col-sm-4">
            <button class="button btn-primary btn-block merlin-button" (click)="onQuizButtonPressed()">Quiz</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
